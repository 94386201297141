import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ElementaryHeroTabs from "../../blocks/HighSchoolHeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";
import ContactForm from "../contact/ContactForm";


function ProductsServices() {
    const [selected, setSelected] = useState(0);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image education-hero" style={{ backgroundImage: 'url(/assets/images/bg/Product-and-Services-hero-img.webp)' }}>
                <ElementaryHeroTabs selectedTab={'product'}/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center pt--100">
                                <h2>PRODUCT & SERVICES</h2>
                                <p style={{fontSize: 20}}>Unlocking the Digital Future for Preschool Educators</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1">
                
              <div className="service-details-inner">
                  <div className="inner">
                  

                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                        <div className="col-lg-12">
                        <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0, marginTop: 40}}>                                  
                            
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="row">
                                        <div className="section-title service-style--3 text-left mb_sm--0">
                                            <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Enhancing High School Lessons With ANTT: Interactive and Standards-Aligned Curriculum Resources</h2>
                                            <p className="description">As a High School teacher, you have the opportunity to enhance your lessons and make them more interactive for your students. ANTT offers a wide range of curriculum and lesson planning resources that are designed to align with the latest teaching methods and standards. These resources include pre-made lesson plans, activities, and assessments that are easy to implement and are specifically designed to engage and inspire your students. Whether you have a background in creative SEO content writing or hold a high school teaching license, ANTT can help you take your teaching to the next level.</p>
                                            {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                              <div style={{
                                height: 460,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#F0F9FF'
                              }}>
                                <img style={{width: '80%'}} src="/assets/images/education/1 (1).webp" alt="About Images"/>
                              </div>
                              
                            </div>
                          </div>
                          <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0, marginTop: 80}}>                                  
                            <div className="col-lg-6" >
                              <div style={{
                                height: 460,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#F0F9FF'
                              }}>
                                <img style={{width: '80%'}} src="/assets/images/education/2 (1).webp" alt="About Images"/>
                              </div>
                              
                            </div>
                            <div className="col-lg-6" style={{paddingLeft: 50}}>
                                <div className="about-inner inner">
                                    <div className="row">
                                        <div className="section-title service-style--3 text-left mb_sm--0">
                                            <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">"Enhance Your Teaching Skills and Give Your Students the Best Education With ANTT's Professional Development Opportunities for High School Teachers"</h2>
                                            <p className="description">Enhance Your Teaching Skills and Knowledge with ANTT's Professional Development Program for High School Teachers. From workshops and webinars to other training opportunities, our program helps educators stay current on the latest teaching strategies and technology trends to give your students the best quality of education.</p>
                                            {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      {/* End Single Area */}

                      {/* Start Single Area */}
                      <div style={{backgroundColor: '#F2FCFF', marginTop: -100, paddingTop: 130}}>
                        <div style={{transform: 'scale(0.8)', transformOrigin: 'top center', }} className="container">
                          <div className="col-lg-12">
                            

                            <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0}}>                                  
                              <div className="col-lg-6" >
                                <img  src="/assets/images/education/group_1000009481.webp" alt="About Images"/>
                                
                              </div>
                              <div className="col-lg-6" style={{paddingLeft: 10}}>
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="section-title service-style--3 text-left mb_sm--0">
                                              <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Enhancing High School Education With ANTT Robotics Hardware and Apps</h2>
                                              <p className="description">"Elevate your teaching skills with ANTT Robotics' STEAM education program for High School teachers. Our cutting-edge platform and interactive sessions are designed to enhance the skills of teachers and prepare students for the fourth industrial revolution. Incorporate hands-on learning and computer programming with our easy-to-use tools and resources, and inspire your students to think critically and creatively."</p>
                                              {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                            <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0, marginTop: 130}}>                                  
                              
                              <div className="col-lg-6">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="section-title service-style--3 text-left mb_sm--0">
                                              <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Explore the Magic of Robotics With ANTT Robotics' All-in-One STEM DIY Box for High School Teachers</h2>
                                              <p className="description">Unlock the potential of your students with ANTT Robotics' all-in-one STEM DIY Box for High School teachers. Our specially curated "School of IoT" box includes coding, robotics, math, science, and arts projects that teach fourth industrial career readiness skills. Explore smart miniature projects like smart cities, solar systems, and mars rovers, and inspire your students to think critically and creatively with hands-on learning and computer programming. Take the first step in the fourth industrial revolution with ANTT Robotics' STEM DIY Box for educators.</p>
                                              {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6" >
                                <img  src="/assets/images/education/4.webp" alt="About Images"/>
                                
                              </div>
                            </div>
                            <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0, marginTop: 130}}>                                  
                              <div className="col-lg-6" >
                                <img  src="/assets/images/education/group_1000009481.webp" alt="About Images"/>
                                
                              </div>
                              <div className="col-lg-6" style={{paddingLeft: 10}}>
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="section-title service-style--3 text-left mb_sm--0">
                                              <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Introduce Your High School Students to the Cutting-Edge World of Robotics and Technology With the ANTT Robotics Trainer Kit</h2>
                                              <p className="description"> Our all-in-one kit is designed for easy use and includes a wide range of components like DC motors, servo motors, sensors, LEDs, and a voltage regulator. Our plug-and-play blocks make it simple for students to complete hands-on experiments in IoT, robotics and electronics. The curriculum includes modules on coding in C/Python, project management, and soft skills development to give students a well-rounded learning experience. With the ANTT Robotics Trainer Kit, students will be equipped with the knowledge and skills they need to excel in the fourth industrial revolution.</p>
                                              {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                            <div className="row" style={{marginTop: 40}}>
                                <div className="col-lg-6 col-md-6" style={{padding: 10}}>
                                    <div className="card service-card" style={{border: 'none', backgroundColor: '#F0FDFA', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: 20, height: 430 }}>
                                        <img src="/assets/images/education/group_1000008158.webp" style={{marginBottom: 30}}/>
                                        <p style={{fontSize: 18, fontWeight: 600, color: '#252B42'}}>Understanding and proficiency in coding and programming languages such as C and Python, as well as their applications in robotics and IoT.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6" style={{padding: 10}}>
                                    <div className="card service-card" style={{border: 'none', backgroundColor: '#F0FDFA', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: 20, height: 430 }}>
                                        <img src="/assets/images/education/group_1000008160.webp" style={{marginBottom: 30}}/>
                                        <p style={{fontSize: 18, fontWeight: 600, color: '#252B42'}}>Knowledge of electronic components and sensors and their function in building and controlling robots and IoT systems.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col-lg-4 col-md-6" style={{padding: 10}}>
                                    <div className="card service-card" style={{border: 'none', backgroundColor: '#F0FDFA', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: 20, height: 430 }}>
                                        <img src="/assets/images/education/group.webp" style={{marginBottom: 30}}/>
                                        <p style={{fontSize: 18, fontWeight: 600, color: '#252B42'}}>Ability to design and build robots and IoT systems using the Robotics Trainer Kit and its components.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6" style={{padding: 10}}>
                                    <div className="card service-card" style={{border: 'none', backgroundColor: '#F0FDFA', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: 20, height: 430 }}>
                                        <img src="/assets/images/education/Soft skills.webp" style={{marginBottom: 30, width: '60%'}}/>
                                        <p style={{fontSize: 18, fontWeight: 600, color: '#252B42'}}>Proficiency in project management and soft skills, including problem-solving, critical thinking, and teamwork.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6" style={{padding: 10}}>
                                    <div className="card service-card" style={{border: 'none', backgroundColor: '#F0FDFA', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', borderRadius: 12, alignItems: 'center', textAlign: 'center', padding: 20, height: 430 }}>
                                        <img src="/assets/images/education/group_1000008012.webp" style={{marginBottom: 30}}/>
                                        <p style={{fontSize: 18, fontWeight: 600, color: '#252B42'}}>Understanding of the fourth industrial revolution and its impact on various industries, and the ability to apply that knowledge to real-world projects and challenges.</p>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      {/* End Single Area */}

                      {/* Start Single Area */}
                      {/* <div className="row align-items-center" style={{marginTop: 150, marginLeft: -4, marginRight: 0}}>                                  
                          
                          <div className="col-lg-7">
                              <div className="about-inner inner">
                                  <div className="row">
                                      <div className="section-title service-style--3 text-left mb_sm--0">
                                          <h2 style={{fontSize: 45, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Digital literacy training for educators is crucial in today's digital age.</h2>
                                          <p style={{marginBottom: 40}} className="description">As technology plays an increasingly important role in our daily lives, educators must have the skills and knowledge to effectively integrate technology and digital tools in the classroom. Our digital literacy training program is designed to help educators develop the skills they need to use technology and digital tools to access, analyze, and create information.</p>
                                          <p style={{marginBottom: 40}} className="description">Our training program includes a wide range of resources and tools that educators can use to enhance their instruction and engage students in learning.We provide all classroom solutions for STEAM laboratory, interior, teaching resources, handbook, quizzes for students and handson training session. Furthermore, we provide training on analytics and data tracking tools that allow educators to monitor student progress and make data-driven decisions about instruction.</p>
                                          <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-5">
                              <div className="thumbnail" style={{position: 'relative'}}>
                                  <img className="w-100" src="/assets/images/education/Digital literacy training_.webp" alt="About Images"/>
                              </div>
                          </div>
                      </div> */}
                      {/* End Single Area */}

                      {/* Start Contact Us */}
                      <div style={{marginTop: -400, paddingTop: 130, backgroundColor: '#fff'}}>
                        <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                          <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0}}>                                  
                            
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="row">
                                        <div className="section-title service-style--3 text-left mb_sm--0">
                                            <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Enhance Your CS Teaching Skills With ANTT Robotics' Classroom Automation and Education Management Training</h2>
                                            <p className="description">Enhance your teaching skills and prepare your students for the future with ANTT Robotics' CS learning program for educators. Our comprehensive training includes daily teaching software and fourth industrial software for your middle school classroom, such as Google, Microsoft, Scratch MIT, EduBlock, School of IoT, and Arduino. With ANTT Robotics, you can automate your classroom and manage your education with ease, giving your students the best possible chance at success in the fast-paced digital world. Upgrade your teaching game and give your students a head start in their future careers with ANTT Robotics' CS learning program.</p>
                                            {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                              <div style={{
                                height: 490,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#F0F9FF'
                              }}>
                                <img style={{width: '80%'}} src="/assets/images/education/Computer Programming.webp" alt="About Images"/>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    {/* End Contact Us */}
                      

              

                  </div>
              </div>
                        
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={0}  />

        </React.Fragment>
    )
}
export default ProductsServices;
