import React from 'react';
import {Link} from 'react-router-dom';

function MiddleSchoolHeroTabs({ selectedTab }) {
  return (
    <div className="container academic" id="hero-tabs-container">
      <div className={selectedTab === 'stem' ? 'hero-tab-first hero-tab-selected' : 'hero-tab-first'}>
        <Link to='/higher-education/steam'/>
        <p>STEAM</p>
      </div>
      <div className={selectedTab === 'product' ? 'hero-tab hero-tab-selected' : 'hero-tab'}>
        <Link to='/higher-education/product-service'/>
        <p>Product & Services</p>
      </div>
      <div className={selectedTab === 'case' ? 'hero-tab hero-tab-selected' : 'hero-tab'}>
        <Link to='/higher-education/case-study'/>
        <p>Case Study</p>
      </div>
      <div className={selectedTab === 'demo' ? 'hero-tab-last hero-tab-selected' : 'hero-tab-last'}>
        <Link to='/higher-education/demo'/>
        <p>Get Demo Product</p>
      </div>
      {/* <div className='hero-tab-last'>
        <Link to='#'/>
        <p>Standards</p>
      </div> */}
  </div>
  )
}

export default  MiddleSchoolHeroTabs;
