import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";
import Slider from "react-slick";
import PortfolioList from "../elements/portfolio/PortfolioList";
import { slickDot , portfolioSlick2 } from "../page-demo/script";

const list = [
    {
        image: 'image-1',
        category: 'Everbest Media',
        title: 'STEAM & Robotics Teaching Solutions',
        details: 'https://old.anttrobotics.com/pressrelease/press4 '
    },
    {
        image: 'image-3',
        category: 'IoTKID LLC',
        title: 'loT & Robotics Training',
        details: '/portfolio-details'
    },
    {
        image: 'image-2',
        category: 'Regnum IT Limited',
        title: 'loT Devices for Toll Management System',
        details: '/portfolio-details'
    },
    {
        image: 'image-4',
        category: 'Showa Co., Ltd',
        title: 'loT & Al based Software-Hardware Development ',
        details: '/portfolio-details'
    },
    {
        image: 'image-5',
        category: 'AlMS LAB',
        title: 'COVID 19 Medical Care Robot',
        details: '/portfolio-details'
    },
    {
        image: 'image-6',
        category: 'ICT Ministry of Bangladesh',
        title: 'Al &Speech Recognition',
        details: '/portfolio-details'
    },
    {
        image: 'image-7',
        category: 'Champion',
        title: 'Bangladesh Frontier Startup, Seoul, SK',
        details: 'https://old.anttrobotics.com/pressrelease/press2'
    },
    {
        image: 'image-8',
        category: 'Low Cost Ventilators',
        title: 'UIU takes the lead in developing low-cost ventilators ',
        details: '/portfolio-details'
    },
    {
        image: 'image-9',  
        category: '1st Runners Up ',
        title: 'Entrepreneurship World Cup 2019',
        details: '/portfolio-details'
    }
]

const PortfolioList2 = [
    {
        image: 'image-1',
        category: 'Everbest Media',
        title: 'STEAM & Robotics Teaching Solutions'
    },
    {
        image: 'image-3',
        category: 'IoTKID LLC',
        title: 'loT & Robotics Training'
    },
    {
        image: 'image-2',
        category: 'Regnum IT Limited',
        title: 'loT Devices for Toll Management System'
    },
    {
        image: 'image-4',
        category: 'Showa Co., Ltd',
        title: 'loT & Al based Software-Hardware Development '
    },
    {
        image: 'image-5',
        category: 'AlMS LAB',
        title: 'COVID 19 Medical Care Robot'
    },
    {
        image: 'image-6',
        category: 'ICT Ministry of Bangladesh',
        title: 'Al &Speech Recognition'
    },
    {
        image: 'image-7',
        category: 'Champion',
        title: 'Bangladesh Frontier Startup, Seoul, SK'
    },
    {
        image: 'image-8',
        category: 'Low Cost Ventilators',
        title: 'UIU takes the lead in developing low-cost ventilators '
    },
    {
        image: 'image-9',  
        category: '1st Runners Up ',
        title: 'Entrepreneurship World Cup 2019'
    }
]

const Portfolio = () => {
    return (
        <>
            <PageHelmet pageTitle='Counters' />

            {/* Start Header Area  */}
            <HeaderFixed />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <Breadcrumb title={'Portfolio'}   />
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main  className="page-wrapper">

                {/* Start Portfolio Area */}
                <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="portfolio-area pt--90 pb--140 bg_color--1">
                    <div className="rn-slick-dot">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {list.map((value , index) => (
                                                <div className="portfolio" key={index}>
                                                    <div className="thumbnail-inner">
                                                        <div className={`thumbnail ${value.image}`}></div>
                                                        <div className={`bg-blr-image ${value.image}`}></div>
                                                    </div>
                                                    <div className="content">
                                                        <div className="inner">
                                                            <p>{value.category}</p>
                                                            <h4><a href={value.details}>{value.title}</a></h4>
                                                            <div className="portfolio-button">
                                                                <a className="rn-btn" href={value.details}>Case Study</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}


                {/* Start Portfolio Area */}
                <div style={{marginTop: -160}} className="portfolio-area pt--120 bg_color--5">
                    <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30">
                                        <h2 className="title">All Works</h2>
                                        <p>With support from the local governments in Bangladesh and South Korea, we have collaborated in many different sectors for the promotion of advanced business automation, robotics products, fourth industrial products, and software solutions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-left mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 text-center">
                                        <a className="rn-button-style--2 btn-solid" href="/portfolio"><span>View More Project</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Portfolio Area */}
                {/* <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30">
                                    <h2 className="title">All Works</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="wrapper portfolio-sacousel-inner mb--55">
                        <div className="portfolio-slick-activation mt--70 mt_sm--40">
                            <Slider {...portfolioSlick2}>
                                {PortfolioList2.map((value , index) => (
                                    <div className="portfolio" key={index}>
                                        <div className="thumbnail-inner">
                                            <div className={`thumbnail ${value.image}`}></div>
                                            <div className={`bg-blr-image ${value.image}`}></div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <p>{value.category}</p>
                                                <h4><a href="/portfolio-details">{value.title}</a></h4>
                                                <div className="portfolio-button">
                                                    <a className="rn-btn" href="/portfolio-details">Case Study</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div> */}
                {/* End Portfolio Area */}

            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer topMargin={-200}  />
            {/* End Footer Area  */}

        </>
        
    )
}

export default Portfolio;