import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ElementaryHeroTabs from "../../blocks/VocationalHeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";

import VocationalCollapsible from "../../blocks/VocationalCollapsible";
import CurriculumSubItem from "../../blocks/CurriculumSubItem";




function SteamVocational() {
    const [selected, setSelected] = useState(0);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image vocational_bg" 
                  style={{ 
                        backgroundColor: '#4ABEAD', 
                        display: 'flex',
                        backgroundPosition: 'top', 
                        alignItems: 'center',
                        position: 'relative', 
                  }}
            >
                <ElementaryHeroTabs selectedTab={'curriculum'}/>
                <div className="container">
                  <div className="row" style={{alignItems: 'center'}}>
                      
                      <div className="col-lg-7 about_hero_content">
                          <h2>Transform Learning With Blended Support</h2>
                          <p>Revolutionize your school’s learning experience with our blended teaching tools and support. Empowering decision-makers, educators, and event heads to achieve greater success and fulfillment in their roles</p>
                      </div>
                      
                      <div className="col-lg-5 about_hero_image" style={{alignSelf: 'flex-end'}}>
                          <img src="/assets/images/vocational/curriculum/1.webp"/>
                      </div>
                      
                      
                  </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1 curriculum-vocational-body">
                
              <div className="service-details-inner">
                  <div className="inner">
                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                          {/* Start Single Area */}
                          <div className="row align-items-center">                                  
                              <div className="col-lg-6 enhance-teach-content">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="service-style--3 text-left mb_sm--0">
                                              <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Computer Science</h2>
                                              <p className="description">Computer Science Curriculum: Programming Fundamentals, OOP, Web Dev, and Agile Methodology. Learn Data Management, Software Engineering, and Advanced Topics in Algorithms and Data Structures with Java</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 enhance-teach-image">
                                  <div style={{position: 'relative'}}>
                                      <img className="w-100" src="/assets/images/vocational/curriculum/2.webp" alt="About Images"/>
                                  </div>
                              </div>
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 80}}>
                            <VocationalCollapsible title='Computer Science Part- I'>
                                <CurriculumSubItem title={'Introduction to programming concepts and problem-solving techniques'}/>
                                <CurriculumSubItem title={'Fundamentals of computer architecture and operating systems'}/>
                                <CurriculumSubItem title={'Data types, variables, and control structures'}/>
                                <CurriculumSubItem title={'Debugging and error handling'}/>
                                <CurriculumSubItem title={'Introduction to object-oriented programming with Java'}/>
                            </VocationalCollapsible>
                            <VocationalCollapsible title='Computer Science Part- II'>
                                <CurriculumSubItem title={'Advanced programming topics such as recursion, data structures, and algorithms'}/>
                                <CurriculumSubItem title={'Principles of software engineering and software design patterns'}/>
                                <CurriculumSubItem title={'Database systems and data management'}/>
                                <CurriculumSubItem title={'Web development using HTML, CSS, and JavaScript'}/>
                                <CurriculumSubItem title={'Introduction to Agile methodology and Test-Driven Development (TDD'}/>
                            </VocationalCollapsible>
                              
                          </div>
                          {/* End Single Area */}
                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 100}}>                                  
                              <div className="col-lg-6 enhance-teach-content">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="service-style--3 text-left mb_sm--0">
                                              <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Robotics and Automation</h2>
                                              <p className="description">Robotics and Automation Curriculum: Learn Mechanical and Electrical Systems, Control Theory, and Robot Programming with Python and ROS. Explore Robot Vision and Real-World Applications in Kinematics, Dynamics, and Control Techniques</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 enhance-teach-image">
                                  <div style={{position: 'relative'}}>
                                      <img className="w-100" src="/assets/images/vocational/curriculum/3.webp" alt="About Images"/>
                                  </div>
                              </div>
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 80}}>
                            <VocationalCollapsible title='Robotics and Automation- I'>
                                <CurriculumSubItem title={'Introduction to Robotics and Automation'}/>
                                <CurriculumSubItem title={'Mechanical and Electrical systems for robotics'}/>
                                <CurriculumSubItem title={'Sensors and Actuators'}/>
                                <CurriculumSubItem title={'Introduction to Control Systems'}/>
                                <CurriculumSubItem title={'Robot Programming using Python'}/>
                            </VocationalCollapsible>
                            <VocationalCollapsible title='Robotics and Automation- II'>
                                <CurriculumSubItem title={'Kinematics and Dynamics'}/>
                                <CurriculumSubItem title={'Control Theory and Techniques'}/>
                                <CurriculumSubItem title={'Advanced Robot Programming and Control using ROS (Robot Operating System)'}/>
                                <CurriculumSubItem title={'Robot Vision and Perception'}/>
                                <CurriculumSubItem title={'Real-world Applications of Robotics and Automation'}/>
                            </VocationalCollapsible>
                              
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 100}}>                                  
                              <div className="col-lg-6 enhance-teach-content">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="service-style--3 text-left mb_sm--0">
                                              <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">IoT and Embedded Systems</h2>
                                              <p className="description">Revolutionize Your Vocational School with ANTT Robotics: Discover Plug-and-Play Coding and Robotics Solutions for Teachers and Unlock Your Students' Potential Today!</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 enhance-teach-image">
                                  <div style={{position: 'relative'}}>
                                      <img className="w-100" src="/assets/images/vocational/curriculum/4.webp" alt="About Images"/>
                                  </div>
                              </div>
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 80}}>
                            <VocationalCollapsible title='IoT and Embedded Systems- I'>                                
                                <CurriculumSubItem title='Introduction to IoT and Embedded Systems' />
                                <CurriculumSubItem title='Fundamentals of Microcontrollers and Microprocessors' />
                                <CurriculumSubItem title='Interfacing Sensors and Actuators with Microcontrollers' />
                                <CurriculumSubItem title='Programming Microcontrollers using C' />
                                <CurriculumSubItem title='Data Communication for IoT' />
                            </VocationalCollapsible>
                            <VocationalCollapsible title='IoT and Embedded Systems - II'>
                                <CurriculumSubItem title='IoT Platforms and Protocols' />
                                <CurriculumSubItem title='Cloud Computing for IoT' />
                                <CurriculumSubItem title='Mobile and Web-based Interface Design for IoT' />
                                <CurriculumSubItem title='IoT Security and Privacy' />
                                <CurriculumSubItem title='Real-world Applications of IoT and Embedded Systems' />
                            </VocationalCollapsible>
                              
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 100}}>                                  
                              <div className="col-lg-6 enhance-teach-content">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="service-style--3 text-left mb_sm--0">
                                              <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Professional Programming</h2>
                                              <p className="description">Professional Programming Curriculum: Learn SDLC, OOP, TDD, and Refactoring Techniques. Discover Advanced Concepts in DevOps, Big Data, and Data Analytics for Real-World Applications. Explore Software Design Patterns, Quality Assurance, and CI/CD.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 enhance-teach-image">
                                  <div style={{position: 'relative'}}>
                                      <img className="w-100" src="/assets/images/vocational/curriculum/5.webp" alt="About Images"/>
                                  </div>
                              </div>
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 80}}>
                            <VocationalCollapsible title='Professional Programming - I'>                                
                                <CurriculumSubItem title='Introduction to Software Development Life Cycle (SDLC)' />
                                <CurriculumSubItem title='Object-Oriented Programming Concepts' />
                                <CurriculumSubItem title='Software Design Patterns' />
                                <CurriculumSubItem title='Test-Driven Development (TDD)' />
                                <CurriculumSubItem title='Refactoring Techniques' />
                            </VocationalCollapsible>
                            <VocationalCollapsible title='Professional Programming - II'>
                                <CurriculumSubItem title='Advanced Software Development Concepts and Techniques' />
                                <CurriculumSubItem title='Software Quality Assurance and Testing' />
                                <CurriculumSubItem title='Introduction to DevOps and Continuous Integration and Continuous Deployment (CI/CD)' />
                                <CurriculumSubItem title='Big Data and Data Analytics for Software Development' />
                                <CurriculumSubItem title='Real-world Applications of Professional Programming' />
                            </VocationalCollapsible>
                              
                          </div>
                          {/* End Single Area */}
      

                      
                        

                          
                       
                      </div>
                      
                      {/* End Single Area */}
                      

              

                  </div>
              </div>
                        
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-500}  />

        </React.Fragment>
    )
}
export default SteamVocational;
