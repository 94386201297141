import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { slickDot } from "../page-demo/script";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import Particles from "react-tsparticles";
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import Team from "../elements/Team";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";
import HeaderFixed from "../component/header/HeaderFixed";
import ModalVideo from 'react-modal-video';
import "./popUp.css";

const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'FUTURE ROBOTICS PLATFORM',
        description: 'ANTT Robotics researches and develops the latest in robotics, AI, STEAM, IoT, and more. Our goal is to make the world a better place through technology that\'s \n accessible to everyone.',
        buttonText: 'Contact Us',
        buttonLink: '/contact'
    }
]

const list = [
    {
        image: 'image-1',
        category: 'Everbest Media',
        title: 'STEAM & Robotics Teaching Solutions',
        details: 'https://old.anttrobotics.com/pressrelease/press4 '
    },
    {
        image: 'image-3',
        category: 'IoTKID LLC',
        title: 'loT & Robotics Training',
        details: '/portfolio-details'
    },
    {
        image: 'image-2',
        category: 'Regnum IT Limited',
        title: 'loT Devices for Toll Management System',
        details: '/portfolio-details'
    },
    {
        image: 'image-4',
        category: 'Showa Co., Ltd',
        title: 'loT & Al based Software-Hardware Development ',
        details: '/portfolio-details'
    },
    {
        image: 'image-5',
        category: 'AlMS LAB',
        title: 'COVID 19 Medical Care Robot',
        details: '/portfolio-details'
    },
    // {
    //     image: 'image-6',
    //     category: 'ICT Ministry of Bangladesh',
    //     title: 'Al &Speech Recognition',
    //     details: '/portfolio-details'
    // },
    {
        image: 'image-7',
        category: 'Champion',
        title: 'Bangladesh Frontier Startup, Seoul, SK',
        details: 'https://old.anttrobotics.com/pressrelease/press2'
    },
    {
        image: 'image-8',
        category: 'Low Cost Ventilators',
        title: 'UIU takes the lead in developing low-cost ventilators ',
        details: '/portfolio-details'
    },
    {
        image: 'image-9',
        category: '1st Runners Up ',
        title: 'Entrepreneurship World Cup 2019',
        details: '/portfolio-details'
    }
]

const testimonials = [
    {
        image: '/assets/images/client/testimonial-1.png',
        name: 'Patricia Ishemo',
        title: 'CEO, IOTKID LLC',
        description: 'A great platform to play and learn programming skills! ANTT Robotics is perfect platform to start an edutech business with their STEAM tools, books, training and support.'
    },
    {
        image: '/assets/images/client/testimonial-2.png',
        name: 'Mr Andrei Calinescu',
        title: '',
        description: 'Technology has always excited me. After using and evaluating countless edu-tech and coding solutions, it\'s clear that ANTT Robotics\' approach to education is what we need in a rapidly changing environment. Their dream team has the right blend of intelligence, experience, passion, and work ethic to be a success.'
    },
    // {
    //     image: '/assets/images/client/testimonial-3.png',
    //     name: 'Zunaid Ahmed Palak',
    //     title: 'MP, State Minister, Bangladesh\'s ICT Division',
    //     description: 'The project has been a great opportunity for the Bangladeshi teams to raise their startup competitiveness to the next level. I am confident that all teams will continue the journey of great challenges to grow as a next generation leader in Bangladeshi’s ICT field.'
    // }
];

class HomeParticles extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })
        this.state = {
            isMissionVideoOpen: false,
            isVisionVideoOpen: false,
            showModal: false
        }
        this.openMissionVideo = this.openMissionVideo.bind(this);
        this.closeMissionVideo = this.closeMissionVideo.bind(this);
        this.openVisionVideo = this.openVisionVideo.bind(this);
        this.closeVisionVideo = this.closeVisionVideo.bind(this);
        this.openModal = this.openModal.bind(this)
        // this.closePopup = this.closePopup.bind(this);
    }
    openMissionVideo() {
        this.setState({ isMissionVideoOpen: true });
    }

    closeMissionVideo() {
        this.setState({ isMissionVideoOpen: false });
    }

    openVisionVideo() {
        this.setState({ isVisionVideoOpen: true });
    }

    closeVisionVideo() {
        this.setState({ isVisionVideoOpen: false });
    }
    openModal() {
        this.setState({ isOpen: true })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    // closePopup() {
    //     this.setState({ showModal: false });
    // }
    stickyHeader() { }

    componentDidMount() {
        // HubSpot Embed Script
        const hubSpotScript = document.createElement("script");
        hubSpotScript.src = "//js.hs-scripts.com/46750957.js";
        hubSpotScript.type = "text/javascript";
        hubSpotScript.async = true;
        hubSpotScript.defer = true;
        document.body.appendChild(hubSpotScript);

        // Show HubSpot popup after 10 seconds
        // setTimeout(() => {
        //     this.setState({ showModal: true });
        //     const hubspotScript = document.createElement("script");
        //     hubspotScript.src = "//js.hsforms.net/forms/embed/v2.js";
        //     hubspotScript.charset = "utf-8";
        //     hubspotScript.type = "text/javascript";
        //     hubspotScript.onload = () => {
        //         window.hbspt.forms.create({
        //             portalId: "46750957",
        //             formId: "b0909582-7e40-41c7-abba-a8fe000d18a7",
        //             target: "#hubspotForm"
        //         });
        //     };
        //     document.body.appendChild(hubspotScript);
        // }, 10000); // 10 seconds delay
    }
    render() {
        const PostList = BlogContent.slice(0, 5);

        // window.addEventListener('scroll', function() {
        //     var value = window.scrollY;
        //     if (value > 100) {
        //         document.querySelector('.header--fixed').classList.add('sticky')
        //     }else{
        //         document.querySelector('.header--fixed').classList.remove('sticky')
        //     }
        // });

        // var elements = document.querySelectorAll('.has-droupdown > a');
        // for(var i in elements) {
        //     if(elements.hasOwnProperty(i)) {
        //         elements[i].onclick = function() {
        //             this.parentElement.querySelector('.submenu').classList.toggle("active");
        //             this.classList.toggle("open");
        //         }
        //     }
        // }

        return (
            <Fragment>
                <Helmet pageTitle="ANTT Robotics" />

                {/* Start Header Area  */}
                {/* <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img style={{transform: 'scale(0.8)'}} height={62.5} className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images"/>
                                    <img style={{transform: 'scale(0.8)'}} className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images"/>
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home','service', 'about', 'portfolio','team','testimonial','blog', 'contact']} currentClassName="is-current" offset={-200}>
                                    <li style={{transform: 'scale(0.8)'}}><a href="#home">Home</a></li>
                                    <li style={{transform: 'scale(0.8)'}}><a href="#service">Service</a>
                                        <ul className="submenu">
                                            <li><Link to="/service-pack">Service Package</Link></li>
                                            <li><Link to="/service-details/steam">Steam Education</Link></li>
                                            <li><Link to="/service-details/iot-aiot">IoT & AIoT Solutions</Link></li>
                                            <li><Link to="/service-details/robotic-gadgets">Robotic Gadget</Link></li>
                                            <li><Link to="/service-details/pcb">PCB & PCBA</Link></li>
                                            <li><Link to="/service-details/service-robot">Service Robot</Link></li>
                                            <li><Link to="/service-details/customised-solutions">Customised Solutions</Link></li>
                                        </ul>
                                    </li>
                                    <li style={{transform: 'scale(0.8)'}}><Link to="/about">About</Link></li>
                                    <li style={{transform: 'scale(0.8)'}}><a href="/portfolio">Portfolio</a></li>
                                    <li style={{transform: 'scale(0.8)'}}><a href="/product">Product</a></li>
                                    <li style={{transform: 'scale(0.8)'}}><a href="/contact">Contact</a></li>
                                    <li style={{transform: 'scale(0.8)'}}><a href="/investor-portal">Investor Portal</a></li>
                                    
                                </Scrollspy>
                            </nav>
                            <div className="header-btn">
                                <a style={{transform: 'scale(0.8)'}} className="rn-btn" href="/login">
                                    <span>Login</span>
                                </a>
                            </div>
                            
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                           
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header> */}
                <HeaderFixed />
                {/* End Header Area  */}

                {/* Start Slider Area   */}

                <div className="home_bg">
                    <div style={{ transformOrigin: 'center center' }} className="container">
                        <div className="row">

                            <div className="hero_text">
                                <h1>Future Robotics</h1>
                                <h2>NoCoding Platform</h2>
                                <p className="description">Empowering Enterprises, SMEs and Academic Institutions with Customizable No-Code Solutions for Industry 4.0 Transformation</p>
                            </div>


                            <img className="home_img" src="/assets/images/home/Hero-img.webp" />



                        </div>
                    </div>
                </div>
                
                {/* Start Embedded Video Area */}
                <div className="service-style--3 text-left mb--15 mb_sm--0" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 50 }}>
                    <h2 className="title">Watch Our Service Overview</h2>
                    <div style={{ position: 'relative', paddingBottom: '25%', height: 0, width: '100%', overflow: 'hidden', maxWidth: '900px', backgroundColor: '#000' }}>
                        <iframe
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            src="https://www.youtube.com/embed/YsIl_axt_mo?autoplay=1&mute=1"
                            title="PCB Service Overview"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </div>
                </div>
                {/* End Embedded Video Area */}

                {/* Start HubSpot Popup Modal */}
                {/*{this.state.showModal && (
                    <div className="popup-modal">
                        <div className="popup-overlay" onClick={this.closePopup}></div> {/* Close on overlay click */}
                    {/*    <div className="popup-content">
                            <button className="close-button" onClick={this.closePopup}>&times;</button>
                            <div id="hubspotForm"></div>
                        </div>
                    </div>/*}
                )}/*}
                {/* End HubSpot Popup Modal */}
                
                
                {/* End Slider Area   */}

                {/* Start Single Area */}
                {/* <div className="container">
                    <div className="text-left mb--15 mb_sm--0 chatbot-section">
                        <div className="col-lg-6 col-sm-12" style={{marginBottom: 40}}>
                            <h2  className="title">Revolutionize Your Business with AI Chatbot</h2>
                        </div>
                        
                        
                        <div className="chatbot_container">
                            <img className="chatbot_lg" src="/assets/images/home/AI Chatbot/chatbot_lg.webp"/>
                            <img className="chatbot_sm" src="/assets/images/home/AI Chatbot/chatbot_sm.webp"/>
                            <div className="chatbot_features">
                                <img style={{marginRight: 20}} src="/assets/images/home/AI Chatbot/No coding IOT.webp"/>
                                <p style={{fontSize: 20, fontWeight: 600, color: '#056EBE'}}>No-Coding IoT Platform - Seamless Integration with Smart Devices</p>
                            </div>
                            <div className="chatbot_features">
                                <img style={{marginRight: 20}} src="/assets/images/home/AI Chatbot/IoT Hardware.webp"/>
                                <p style={{fontSize: 20, fontWeight: 600, color: '#056EBE'}}>IoT Hardware Solutions  Empowering Enterprises to Acquire Machine Data</p>
                            </div>
                            <div className="chatbot_features">
                                <img style={{marginRight: 20}} src="/assets/images/home/AI Chatbot/Expert Resources.webp"/>
                                <p style={{fontSize: 20, fontWeight: 600, color: '#056EBE'}}>Expert Resources and Training  with AI and Human Assistance Learn Future  Skills </p>
                            </div>
                            <div className="chatbot_features">
                                <img style={{marginRight: 20}} src="/assets/images/home/AI Chatbot/Custom Solutions.webp"/>
                                <p style={{fontSize: 20, fontWeight: 600, color: '#056EBE'}}>Custom Solutions Designed No - Coding Required</p>
                            </div>
                           
                        </div>
                    </div>
                </div> */}

                {/* End Single Area */}

                {/* Start Improve Education Area */}
                <div className="about-area" id="features_section">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center" style={{ paddingTop: 100 }}>

                                <div style={{ paddingLeft: 0 }} className="col-lg-6 fautures_1_image">
                                    <div style={{ position: 'relative' }}>
                                        <img src="/assets/images/home/PRODUCT/1.webp" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="section-title service-style--3 text-left mb_sm--0">
                                                <h2 style={{ fontSize: 30, marginBottom: 20 }} className="title">ANTT Robotics - Empowering the Future Workforce</h2>
                                                <p style={{ marginBottom: 40 }} className="description">ANTT Robotics is committed to empowering individuals and organizations with Industry 4.0 solutions and digital literacy. Our Computer Science Education Made Easy ecosystem offers innovative tools, software, and resources for effective teaching and learning. The Build Your Future package provides cutting-edge fourth industrial skills, including IoT, sensors, big data, and smart project development, to prepare you for the digital age. Join us today and take the first step towards a brighter future.</p>
                                                <a style={{ border: '2px solid #2A9DF4' }} className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Improve Education Area */}

                {/* Start No Coding Area */}
                <div className="about-area" >
                    <div className="about-wrapper">
                        <div className="container">
                            <div style={{ transformOrigin: 'top' }} className="row row--35 align-items-center feature_2">


                                <div className="col-lg-6 features_2_text">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="section-title service-style--3 text-left mb_sm--0">
                                                <h2 style={{ fontSize: 30, marginBottom: 20 }} className="title">Automation Without Coding</h2>
                                                <p style={{ marginBottom: 40 }} className="description">Unlock the potential of your students with ANTT Robotics' STEAM education products. Our no-coding platform and gamified sessions make it easy for elementary school teachers to guide their students in solving real-world problems through hands-on learning and computer programming. With our tools and resources, you can take your teaching beyond the classroom and inspire your students to think critically and creatively.</p>
                                                <a style={{ border: '2px solid #2A9DF4' }} className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ paddingRight: 0 }} className="col-lg-6 features_2_image">
                                    <div style={{ position: 'relative' }}>
                                        <img src="/assets/images/home/PRODUCT/2.webp" alt="About Images" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End No Coding Area */}

                {/* Start Improve Education Area */}
                <div className="about-area" id="about">
                    <div className="about-wrapper">
                        <div className="container">
                            <div style={{ transformOrigin: 'top' }} className="row row--35 align-items-center feature_3">

                                <div style={{ paddingLeft: 0 }} className="col-lg-6 features_3_image">
                                    <div style={{ position: 'relative' }}>
                                        <img src="/assets/images/home/PRODUCT/3.webp" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="section-title service-style--3 text-left mb_sm--0">
                                                <h2 style={{ fontSize: 30, marginBottom: 20 }} className="title">Engaging and Effective Learning Resources</h2>
                                                <p style={{ marginBottom: 40 }} className="description">ANTT Robotics offers research-based, policy-driven pre-made interactive lesson plans, activities, and assessments aligned with standards. This improves student engagement and learning outcomes while saving educators time. Our ed-tech and digital literacy resources framework is designed to enhance teaching and learning experiences</p>
                                                <a style={{ border: '2px solid #2A9DF4' }} className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Improve Education Area */}

                {/* Start Service Area  */}
                <div className="service-section creative-service-wrapper bg_color--1 service-sm-margin" id="service">
                    <div className="container">
                        <div className="row" style={{ justifyContent: 'center', marginBottom: 30 }}>
                            <div className="col-lg-6 col-12">
                                <div className="section-title text-left mb--30">
                                    <h2 style={{ textAlign: 'center', fontSize: 40 }}>Our Services</h2>
                                    <p style={{ textAlign: 'center' }}>Innovate your business and guarantee results! Launch your global robotics business now!</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-12 col-12 text-le   ft" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start About Area */}
                <div style={{ marginBottom: -50, }} className="about-area ptb--30" id="mission-vision">
                    <div className="about-wrapper">
                        <div className="container" style={{ transformOrigin: 'top' }}>
                            <div className="row align-items-center">
                                <div className="col-lg-6 mission-image">
                                    <div style={{
                                        height: 480,
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: '#F0F9FF'
                                    }}>
                                        {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='DQRPZca7uhM' onClose={() => this.setState({ isOpen: false })} />
                                        <img className="video-popup" src="/assets/images/icons/play button icon.webp" onClick={this.openModal} />
                                        <img style={{ width: '80%' }} src="/assets/images/home/Mission and vision/mISSION.webp" alt="About Images" /> */}
                                        <ModalVideo channel='youtube' isOpen={this.state.isMissionVideoOpen} videoId='DQRPZca7uhM' onClose={this.closeMissionVideo} />
                                        <img className="video-popup" src="/assets/images/icons/play button icon.webp" onClick={this.openMissionVideo} />
                                        <img style={{ width: '80%' }} src="/assets/images/home/Mission and vision/mISSION.webp" alt="Play Mission Video" />
                                    </div>
                                </div>
                                <div className="col-lg-6 mission" style={{ paddingLeft: 50 }}>
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="section-title service-style--3 text-left mb_sm--0">
                                                <h2 style={{ fontSize: 30, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%' }} className="title">Mission </h2>
                                                <p className="description">ANTT Robotics is a platform that combines the latest technology and innovative teaching methods to provide Industry 4.0 solutions and digital literacy to organizations, academic institutions, and SMEs. Our non-coding and robotics hardware technologies enable us to offer tailored, cost-effective, and efficient solutions to a variety of industries and sectors. Our goal is to empower individuals and organizations to leverage technology to its full potential, and to achieve their strategic goals through continuous learning and innovation. Join us today and take the first step towards digital literacy and Industry 4.0 readiness</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center">

                                <div className="col-lg-6" style={{ paddingLeft: 50 }}>
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="section-title service-style--3 text-left mb_sm--0">
                                                <h2 style={{ fontSize: 30, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%' }} className="title">Vision</h2>
                                                <p className="description">Our vision is to be a leader in the digital education industry, providing cutting-edge technology and innovative teaching methods that prepare individuals and organizations for the fourth industrial revolution. We aim to make a positive impact on the world by empowering people to succeed in the rapidly changing digital landscape.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 vision-image">
                                    <div style={{
                                        height: 480,
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: '#F0F9FF'
                                    }}>
                                        <ModalVideo channel='youtube' isOpen={this.state.isVisionVideoOpen} videoId='DQRPZca7uhM' onClose={this.closeVisionVideo} />
                                        <img className="video-popup" src="/assets/images/icons/play button icon.webp" onClick={this.openVisionVideo} />
                                        <img style={{ width: '80%' }} src="/assets/images/home/Mission and vision/mISSION.webp" alt="Play Vision Video" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Portfolio Area */}
                <div className="home-portfolio pb--30 bg_color--1" id="portfolio">
                    <div className="rn-slick-dot">
                        <div className="container">
                            <div className="row" style={{ justifyContent: 'center' }}>
                                <div className="col-lg-8">
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                        <h2 className="title" style={{ textAlign: 'center', fontSize: 40 }}>Our Portfolio</h2>
                                        <p style={{ textAlign: 'center' }}>With support from the local governments in Bangladesh and South Korea, we have collaborated in many different sectors for the promotion of advanced business automation, robotics products, fourth industrial products, and software solutions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {list.map((value, index) => (
                                                <div className="portfolio" key={index} style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <div className="thumbnail-inner">
                                                        <div className="thumbnail-overlay">
                                                            <div className="portfolio-button">
                                                                <a className="rn-btn" href={value.details}>Case Study</a>
                                                            </div>
                                                        </div>
                                                        <div className={`thumbnail ${value.image}`}></div>
                                                        {/* <div className={`bg-blr-image ${value.image}`}></div> */}
                                                    </div>
                                                    <div className="content" style={{ marginTop: 26 }}>
                                                        <div className="inner">
                                                            <p style={{ textAlign: 'center', marginBottom: 6 }}>{value.category}</p>
                                                            <h4 style={{ textAlign: 'center' }}><a href={value.details}>{value.title}</a></h4>

                                                        </div>
                                                    </div>
                                                    {/* <Link className="link-overlay" to={value.details}></Link> */}
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                
                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--70 p pb--50 bg_color--5">
                    <div className="container">
                        <div className="row" style={{ justifyContent: 'center', marginBottom: 50 }}>
                            <div className="col-lg-8">
                                <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                    <h2 className="title" style={{ textAlign: 'center', fontSize: 40 }}>Our Fun Facts</h2>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Team Area  */}
                <div className="products-area bg_color--1" id="team">
                    <div className="container products">
                        <div style={{ justifyContent: 'center' }} className="row">
                            <div className="col-lg-6">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 style={{ textAlign: 'center', fontSize: 40 }} className="title">Our Products</h2>
                                    <p style={{ textAlign: 'center' }}>ANTT Robotics Smart Ecosystem Services Will Help You Get Started With Your Robotics Business.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row products-section">
                            <Team column="col-lg-3 col-md-6 col-sm-6 col-12" />
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}
                
           
                {/* Start Testimonial Area */}
                <div className="rn-testimonial-area ptb--40 sm-top-margin" id="testimonial">
                    <div className="container">
                        <div style={{ justifyContent: 'center' }} className="testimonial-title row">
                            <div className="col-lg-6">
                                <div className="section-title service-style--3 text-left mb--25 mb_sm--0">
                                    <h2 style={{ textAlign: 'center', fontSize: 40 }} className="title">Testimonials</h2>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center">
                            <div className="col-lg-4" style={{ marginTop: 100 }}>
                                <div style={{
                                    position: 'relative',
                                    border: '1px solid rgba(181, 176, 160, 0.77)',
                                    borderRadius: 20,
                                    height: 450,
                                    backgroundColor: '#F5FBFF',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }} className="testimonial-card">
                                    <img style={{
                                        width: 130,
                                        height: 130,
                                        borderRadius: '50%',
                                        border: '6px solid #C6EAFF',
                                        position: 'absolute',
                                        top: -65
                                    }} src="/assets/images/client/testimonial-1.png" alt="Testimonial Images" />
                                    <h4 style={{ fontWeight: 700, fontSize: 20, marginBottom: 3 }}>Patricia Ishemo</h4>
                                    <p style={{ fontSize: 14, fontWeight: 400, color: 'black', lineHeight: '24px' }}>CEO, IOTKID LLC</p>
                                    <p className="testimonial-body" style={{ fontSize: 12, fontWeight: 400, textAlign: 'center', position: 'relative', lineHeight: '24px', marginTop: 30 }}>A great platform to play and learn programming skills! ANTT Robotics is perfect platform to start an edutech business with their STEAM tools, books, training and support.</p>
                                </div>
                            </div>
                            <div className="col-lg-4" style={{ marginTop: 100 }}>
                                <div style={{
                                    position: 'relative',
                                    border: '1px solid rgba(181, 176, 160, 0.77)',
                                    borderRadius: 20,
                                    height: 450,
                                    backgroundColor: '#F5FBFF',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }} className="testimonial-card">
                                    <img style={{
                                        width: 130,
                                        height: 130,
                                        borderRadius: '50%',
                                        border: '6px solid #C6EAFF',
                                        position: 'absolute',
                                        top: -65
                                    }} src="/assets/images/client/testimonial-2.png" alt="Testimonial Images" />
                                    <h4 style={{ fontWeight: 700, fontSize: 20, marginBottom: 3 }}>Mr Andrei Calinescu</h4>
                                    <p className="testimonial-body" style={{ fontSize: 12, fontWeight: 400, textAlign: 'center', position: 'relative', lineHeight: '24px', marginTop: 30 }}>Technology has always excited me. After using and evaluating countless edu-tech and coding solutions, it's clear that ANTT Robotics' approach to education is what we need in a rapidly changing environment. Their dream team has the right blend of intelligence, experience, passion, and work ethic to be a success.</p>
                                </div>
                            </div>
                            {/* <div className="col-lg-4" style={{marginTop: 100}}>
                                <div style={{
                                    position: 'relative',
                                    border: '1px solid rgba(181, 176, 160, 0.77)',
                                    borderRadius: 20,
                                    height: 450,
                                    backgroundColor: '#F5FBFF',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }} className="testimonial-card">
                                    <img style={{
                                        width: 130,
                                        height: 130,
                                        borderRadius: '50%',
                                        border: '6px solid #C6EAFF',
                                        position: 'absolute',
                                        top: -65
                                    }} src="/assets/images/client/testimonial-3.png" alt="Testimonial Images"/>
                                    <h4 style={{fontWeight: 700, fontSize: 20, marginBottom: 3}}>Zunaid Ahmed Palak</h4>
                                    <p style={{fontSize: 14, fontWeight: 400, color: 'black', textAlign: 'center', lineHeight: '24px'}}>MP, State Minister, Bangladesh's ICT Division</p>
                                    <p className="testimonial-body" style={{fontSize: 12, fontWeight: 400, textAlign: 'center', position: 'relative', lineHeight: '24px', marginTop: 30}}>The project has been a great opportunity for the Bangladeshi teams to raise their startup competitiveness to the next level. I am confident that all teams will continue the journey of great challenges to grow as a next generation leader in Bangladeshi’s ICT field.</p>
                                </div>
                            </div> */}
                            {/* <div className="slick-space-gutter--15 slickdot--20">
                                <Slider {...slickDot}>
                                    {testimonials.map((value , index) => (
                                        
                                            <div style={{
                                                position: 'relative',
                                                border: '1px solid rgba(181, 176, 160, 0.77)',
                                                borderRadius: 20,
                                                // height: 450,
                                                backgroundColor: '#F5FBFF',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                padding: '80px 50px 20px'
                                            }}>
                                                <img style={{
                                                    width: 130,
                                                    height: 130,
                                                    borderRadius: '50%',
                                                    border: '6px solid #C6EAFF',
                                                    position: 'absolute',
                                                    top: -65
                                                }} src={`${value.image}`} alt="Testimonial Images"/>
                                                <h4 style={{fontWeight: 700, fontSize: 20, marginBottom: 3}}>{value.name}</h4>
                                                <p style={{fontSize: 14, fontWeight: 400, color: 'black', lineHeight: '24px'}}>{value.title}</p>
                                                <p className="testimonial-body" style={{fontSize: 12, fontWeight: 400, textAlign: 'center', position: 'relative', lineHeight: '24px', marginTop: 30}}>{value.description}</p>
                                            </div>
                                        
                                    ))}
                                </Slider>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* End Testimonial Area */}


                {/* Start Blog Area */}
                {/* <div className="rn-blog-area pt--120 pb--140 bg_color--1" id="blog">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-6">
                                <div className="section-title text-left">
                                    <h2>Latest News</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--55 mt_sm--30 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0">
                            <div className="col-lg-12">
                                <Slider {...slickDot}>
                                    {PostList.map((value , i ) => (
                                        <div className="blog blog-style--1" key={i}>
                                            <div className="thumbnail">
                                                <a href="/blog-details">
                                                    <img src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                                </a>
                                            </div>
                                            <div className="content">
                                                <p className="blogtype">{value.category}</p>
                                                <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                                <div className="blog-btn">
                                                    <a className="rn-btn text-white" href="/blog-details">Read More</a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>    
                    </div>    
                </div> */}
                {/* End Blog Area */}

                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--30 home-contact" id="contact">
                    <Contact />
                </div>
                {/* End Contact Us */}


                {/* Start Brand Area */}
                <div className="home-brand rn-brand-area bg_color--1 ptb--30">
                    <div style={{ transformOrigin: 'top' }} className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}
export default HomeParticles;