import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import Slider from "react-slick";
import { slickDot } from "../page-demo/script";
import {Link} from "react-router-dom";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";
import BrandOne from "../elements/Brand";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import CounterTwo from "./counters/CounterTwo";
import ModalVideo from 'react-modal-video';

const list = [
    {
        image: 'image-1',
        category: 'Everbest Media',
        title: 'STEAM & Robotics Teaching Solutions'
    },
    {
        image: 'image-3',
        category: 'IoTKID LLC',
        title: 'loT & Robotics Training'
    },
    {
        image: 'image-2',
        category: 'Regnum IT Limited',
        title: 'loT Devices for Toll Management System'
    },
    {
        image: 'image-4',
        category: 'Showa Co., Ltd',
        title: 'loT & Al based Software-Hardware development '
    },
    {
        image: 'image-5',
        category: 'AlMS LAB',
        title: 'COVID 19 Medical Care Robot'
    },
    {
        image: 'image-6',
        category: 'ICT Ministry of Bangladesh',
        title: 'Al &Speech Recognition'
    },
    {
        image: 'image-7',
        category: 'Champion',
        title: 'Bangladesh Frontier Startup, Seoul, SK'
    },
    {
        image: 'image-8',
        category: '1st Runners Up ',
        title: 'Entrepreneurship World Cup 2019'
    },
    {
        image: 'image-9',
        category: 'Low Cost Ventilators',
        title: 'UIU takes the lead in developing low-cost ventilators '
    }
]

let Data = [
  {
      countNum : 11,
      countTitle: 'Countries',
  },
  {
      countNum : 8,
      countTitle: 'Authorized Distributors',
  },
  {
      countNum : 8,
      countTitle: 'Research Collaboration',
  },
];


class InvestorPortal extends Component {
    // state = {
    //     didViewCountUp: false
    // };
    // onVisibilityChange = isVisible => {
    //     if (isVisible) {
    //         this.setState({didViewCountUp: true});
    //     }
    // }
    constructor(props) {
        super(props);
        
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Investor Portal' />

                <HeaderFixed />
                {/* Start Breadcrump Area */}
                <div className="bg_image vocational_bg"
                    style={{ 
                        backgroundColor: '#5255FF', 
                        display: 'flex',
                        backgroundPosition: 'top', 
                        alignItems: 'center',
                        position: 'relative', 
                    }}>
                    <div style={{transform: 'scale(0.8)'}} className="container">
                        <div className="row" style={{alignItems: 'center'}}>
                            
                            <div className="col-lg-6 about_hero_content">
                                <h2>Investor Portal</h2>
                            </div>
                            
                            <div className="col-lg-6 about_hero_image" style={{alignSelf: 'flex-end'}}>
                                <img src="/assets/images/portfolio/investor-hero.webp"/>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}
                 

                {/* Start Finding Us Area  */}
                <div style={{transform: 'scale(0.8)', marginTop: 100}} className="container">
                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 0, boxShadow: '6px 4px 31px rgba(0, 0, 0, 0.07)'}}>                                                                         
                        <div className="col-lg-6 enhance-teach-content" style={{paddingLeft: 50, backgroundColor: '#FFF5F5', display: 'flex', height: 400}}>
                            <div className="about-inner inner" style={{display: 'flex'}}>
                                <div className="row">
                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0, alignItems: 'baseline'}}>
                                        <h2 style={{fontSize: 30, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%', color: '#6D5858'}} className="title">Patented Technological Platform With Significant Worldwide Markets</h2>                                                        
                                        <p style={{marginBottom: 34}}>Please sign in for access to the latest investor resources</p>
                                        <a style={{backgroundColor: '#F78F1E', padding: '10px 20px'}} href="/contact" className="generic-color-btn">Contact Us</a>                                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 enhance-teach-image" style={{padding: 0}}>
                            <div style={{
                                height: 400,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#F6F6FF',
                                
                            }}>
                                <img style={{width: '80%'}} src="/assets/images/portfolio/investor-1.webp" alt="About Images"/>
                            </div>                                    
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--20 p pb--50">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <h3 className="fontWeight500">Our Fun Facts</h3>
                                </div>
                            </div>
                        </div> */}
                        <CounterTwo />
                    </div>
                </div>
                {/* End CounterUp Area */}

                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1">
                    <div className="container" style={{transform: 'scale(0.8)'}}>
                        <div className="row">
                            <div className="col-lg-12">
                              <img src="/assets/images/bg/world-map.webp" alt="Finding Images"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Team Area  */}
                <div className="rn-brand-area bg_color--1">
                    <div style={{transform: 'scale(0.8)'}} className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="brand-style-flag">
                                    <li><img src="/assets/images/flags/usa_flag.webp"/><p>USA</p></li>
                                    <li><img src="/assets/images/flags/tanzania_flag.webp"/><p>Tanzania</p></li>
                                    <li><img src="/assets/images/flags/egypt_flag_icon_1.webp"/><p>Egypt</p></li>
                                    <li><img src="/assets/images/flags/thailand_flag.webp"/><p>Thailand</p></li>
                                    <li><img src="/assets/images/flags/bamgladesh_flag.webp"/><p>Bangladesh</p></li>
                                    <li><img src="/assets/images/flags/Singapore-flag.webp"/><p>Singapore</p></li>
                                    <li><img src="/assets/images/flags/japan_flag_icon.webp"/><p>Japan</p></li>
                                    <li><img src="/assets/images/flags/china_flag_icon.webp"/><p>China</p></li>
                                    <li><img src="/assets/images/flags/philippines_flag_icon_1.webp"/><p>Philippines</p></li>
                                    <li><img src="/assets/images/flags/malaysia_flag.webp"/><p>Malaysia</p></li>
                                    <li><img src="/assets/images/flags/south_korea_flag.webp"/><p>South Korea</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start About Area  */}
                <div style={{marginBottom: -50}} className="about-area" id="about">
                    <div className="about-wrapper">
                        <div className="container" style={{transform: 'scale(0.8)'}}>  
                            <div className="row row--35 align-items-center">
                                
                                <div className="col-lg-12" style={{marginBottom: 50}}>
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                        <h2 style={{fontSize: 40}} className="title">About Us</h2>
                                        <p className="description">ANTT Robotics help clients to learn how to automate things using the Internet of Things (IoT), Industrial IoT (IIoT), Robotics, Data Science, Blockchain, and the most up-to-date technologies. In short, ANTT is an innovative ecosystem developer that includes complete IIOT toolkits and frameworks while simultaneously teaching about the usage of fourth industrial tools to transfer the knowledge in the last  mileage  of the world. Further, strive to cater to Edtech and Hardtech industries by developing fun embedded system products.</p>
                                    </div>
                                </div>
                                
                                <div className="col-lg-7">
                                    <div className="thumbnail" style={{position: 'relative'}}>
                                        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='DQRPZca7uhM' onClose={() => this.setState({isOpen: false})} />
                                        <img className="video-popup" src="/assets/images/icons/play button icon.webp" onClick={this.openModal}/>
                                        <img className="w-100" src="/assets/images/about/about-us-home.webp" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="about-us-list" style={{marginBottom: 50}}>
                                                <div className="section-title-colored2"><h3>Mission</h3></div>
                                                <p>Accelerating the fourth industrial revolution by developing the Business organization to integrate localized ecosystems using modern technology. </p>
                                            </div>
                                            <div className="about-us-list">
                                                <div className="section-title-colored2"><h3>Vision</h3></div>
                                                <p>Research of new technologies to developed products, solutions and training supports for educational and industrial organization. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

               

                

                {/* Start Brand Area  */}
                <div className="rn-brand-area">
                    <div className="container" style={{marginTop: 40, transform: 'scale(0.8)'}}>
                        <div className="row" style={{justifyContent: 'center'}}>
                            <div className="col-lg-10">
                               
                                <h2 style={{fontSize: 40, textAlign: 'center', fontWeight: 600}} className="title">Funded & Supported By</h2>
                              
                            </div>
                        </div>
                        <div className="row" style={{marginTop: 60}}>
                            <div className="col-lg-3">
                                <div style={{height: 113, borderRadius: 7, border: '1px solid #E0E0E0', display: 'flex', justifyContent: 'center', padding: 20}}>
                                    <img src="/assets/images/brand/brand-01.png" alt="Logo Images"/>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div style={{height: 113, borderRadius: 7, border: '1px solid #E0E0E0', display: 'flex', justifyContent: 'center', padding: 20}}>
                                    <img src="/assets/images/brand/brand-03.webp" alt="Logo Images"/>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div style={{height: 113, borderRadius: 7, border: '1px solid #E0E0E0', display: 'flex', justifyContent: 'center', padding: 20}}>
                                    <img src="/assets/images/brand/brand-02.png" alt="Logo Images"/>
                                </div>
                            </div>                          
                            <div className="col-lg-3">
                                <div style={{height: 113, borderRadius: 7, border: '1px solid #E0E0E0', display: 'flex', justifyContent: 'center', padding: 20}}>
                                    <img src="/assets/images/brand/brand-17.png" alt="Logo Images"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                {/* Start About Area */}
                <div className="about-area" id="about">
                    <div className="about-wrapper">
                        <div className="container" style={{marginTop: 40, transform: 'scale(0.8)'}}>
                          <div className="row" style={{justifyContent: 'center'}}>
                                <div className="col-lg-10">
                                    
                                    <h2 style={{fontSize: 40, textAlign: 'center', fontWeight: 700}} className="title">Commercialization & Market Opportunity</h2>
                                    <p style={{textAlign: 'center'}}>A platform that harnesses the capabilities of AI and machine learning allows for smart manufacturing and enhances the knowledge acquisition for product development.</p>
                                </div>
                            </div>
                            <div className="row row--35 align-items-center" style={{marginTop: 80}}>
                                <div className="col-lg-5">
                                    <div>
                                        <img className="w-100" src="/assets/images/about/tam-sam-som.webp" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '400px' }}>
                                        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center',  margin: '15px 0' }}>
                                            <div style={{height: 48, width: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 20, backgroundColor: '#F78F1E', borderRadius: 24,}}>
                                                <img style={{width: '56%'}} src="/assets/images/about/bullet-01.webp" alt="About Images"/>
                                            </div>
                                            
                                            <h3 style={{fontSize: 24, margin: 0}}>Hardware Technology (HardTech)</h3>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center',  margin: '15px 0' }}>
                                            <div style={{height: 48, width: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 20, backgroundColor: '#F78F1E', borderRadius: 24,}}>
                                                <img style={{width: '56%'}} src="/assets/images/about/bullet-02.webp" alt="About Images"/>
                                            </div>
                                            <h3 style={{fontSize: 24, margin: 0}}>IoT, IIoT, AIIoT Device & Solution</h3>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center',  margin: '15px 0' }}>
                                            <div style={{height: 48, width: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 20, backgroundColor: '#F78F1E', borderRadius: 24,}}>
                                                <img style={{width: '56%'}} src="/assets/images/about/bullet-03.webp" alt="About Images"/>
                                            </div>
                                            <h3 style={{fontSize: 24, margin: 0}}>Robotic Chip & Gadget</h3>
                                        </div>
                                        <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center',  margin: '15px 0' }}>
                                            <div style={{height: 48, width: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 20, backgroundColor: '#F78F1E', borderRadius: 24,}}>
                                                <img style={{width: '56%'}} src="/assets/images/about/bullet-01.webp" alt="About Images"/>
                                            </div>
                                            <h3 style={{fontSize: 24, margin: 0}}>4IR Sensors & Module</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start CounterUp Area */}
                <div className="rn-counterup-area pt--70 p pb--50 bg_color--5">
                    <div className="container">
                        <div className="row" style={{justifyContent: 'center', marginBottom: 50}}>
                            <div className="col-lg-8">
                                <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                    <h2 className="title" style={{textAlign: 'center', fontSize: 40}}>Our Fun Facts</h2>
                                </div>
                            </div>
                        </div>
                        <CounterOne />
                    </div>
                </div>
                {/* End CounterUp Area */}

                 {/* Start Brand Area */}
                 <div className="rn-brand-area bg_color--1 ptb--30">
                    <div style={{transform: 'scale(0.8)'}} className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Portfolio Area */}
                <div className="home-portfolio pb--30 bg_color--1" id="portfolio" >
                    <div className="rn-slick-dot">
                        <div  className="container" style={{marginTop: 80}}>
                            <div className="row" style={{justifyContent: 'center'}}>
                                <div className="col-lg-8">
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0">
                                        <h2 className="title" style={{textAlign: 'center', fontSize: 40}}>Our Portfolio</h2>
                                        <p style={{textAlign: 'center'}}>With support from the local governments in Bangladesh and South Korea, we have collaborated in many different sectors for the promotion of advanced business automation, robotics products, fourth industrial products, and software solutions.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {list.map((value , index) => (
                                                <div className="portfolio" key={index} style={{display: 'flex', flexDirection: 'column'}}>        
                                                    <div className="thumbnail-inner">
                                                        <div className="thumbnail-overlay">
                                                            <div className="portfolio-button">
                                                                <a className="rn-btn" href={value.details}>Case Study</a>
                                                            </div>
                                                        </div>
                                                        <div className={`thumbnail ${value.image}`}></div>
                                                        {/* <div className={`bg-blr-image ${value.image}`}></div> */}
                                                    </div>
                                                    <div className="content" style={{marginTop: 26}}>
                                                        <div className="inner">
                                                            <p style={{textAlign: 'center', marginBottom: 6}}>{value.category}</p>
                                                            <h4 style={{textAlign: 'center'}}><a href={value.details}>{value.title}</a></h4>
                                                            
                                                        </div>
                                                    </div>
                                                    {/* <Link className="link-overlay" to={value.details}></Link> */}
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Finding Us Area  */}
                <div style={{transform: 'scale(0.8)'}} className="container">
                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 0, boxShadow: '6px 4px 31px rgba(0, 0, 0, 0.07)'}}>                                                                         
                        <div className="col-lg-6 enhance-teach-content" style={{paddingLeft: 50, backgroundColor: '#FFF5F5', display: 'flex', height: 400}}>
                            <div className="about-inner inner" style={{display: 'flex'}}>
                                <div className="row">
                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0, alignItems: 'baseline'}}>
                                        <h2 style={{fontSize: 30, marginBottom: 34, fontWeight: 500, lineHeight: '134.5%', color: '#6D5858'}} className="title">Send request for get access to the latest investor resources of ANTT Robotics LTD, ANTT Robotics LLC</h2>                                                        
                                        {/* <p>Please sign in for access to the latest investor resources</p> */}
                                        <a style={{backgroundColor: '#F78F1E', padding: '10px 20px'}} href="/contact" className="generic-color-btn">Enquiry</a>                                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 enhance-teach-image" style={{padding: 0}}>
                            <div style={{
                                height: 400,
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#F6F6FF',
                                
                            }}>
                                <img style={{width: '60%'}} src="/assets/images/portfolio/investor-2.webp" alt="About Images"/>
                            </div>                                    
                        </div>
                    </div>
                </div>
                {/* End Finding Us Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer topMargin={80} />

            </React.Fragment>
        )
    }
}
export default InvestorPortal;