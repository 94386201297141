import React, { useState, useEffect } from "react";
import PageHelmet from "../../component/common/Helmet";

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";
import {Link} from 'react-router-dom';
import ElementaryHeroTabs from "../../blocks/ElementaryHeroTabs";


function Resources() {
    const [selected, setSelected] = useState('');
    const [items, setItems] = useState([
      {
        key: 'cs',
        image: '/assets/images/education/Computer Science (CS).webp',
        title: "Computer Science (CS) for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'Learn the basics of computational thinking, algorithms, computer architecture, internet and web technologies, and programming concepts with ANTT Robotics training program. Gain the skills necessary to teach students in the digital age. Invest in our program to elevate your teaching and prepare students for future success.'
      },
      {
        key: 'ic',
        image: '/assets/images/education/Introduction to Coding.webp',
        title: "Introduction to Coding for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'Learn the basics of coding and programming with ANTT Robotics program. Covering foundational concepts such as algorithms, computer organization, and web technologies. Hands-on experience with block-based coding, drag-and-drop programming, and engaging coding challenges and projects. Develop the skills necessary to understand and create programs.'
      },
      {
        key: 'ca',
        image: '/assets/images/education/Computer Architecture.webp',
        title: "Computer Architecture for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'Learn the basics of computer organization, including the CPU, memory, and storage. Explore data storage and retrieval and the function of operating systems in managing computer systems.'
      },
      {
        key: 'cp',
        image: '/assets/images/education/Computer Programming.webp',
        title: "Computer Programming & Algorithm for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'Overview of key programming concepts including variables, control flow, functions and algorithms. Understanding data types and how to use them in your code. Learn how to use loops and procedures to control the flow of your program.'
      },
      {
        key: 'bc',
        image: '/assets/images/education/Basic Circuits_.webp',
        title: "Basic Circuits & Electronics for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'Basics of electricity and circuit functions, understanding electronic components, building circuits, experimenting with configurations, understanding sensors and actuators in robotics.'
      },
      {
        key: 'dr',
        image: '/assets/images/education/Data Representation.webp',
        title: "Data Representation for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'In this course, we will delve into the intricacies of data and how it is stored, manipulated and represented. We will learn about different types of data and their properties, as well as understanding the various data structures and algorithms used to organize and manage data. Additionally, we will cover the different methods for data representation and manipulation, providing a comprehensive understanding of the subject matter.'
      },
      {
        key: 'auto',
        image: '/assets/images/education/Automation.webp',
        title: "Automation & Emerging Technology for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'Understanding the basics of automation and robotics Our program provides an introduction to the key concepts of IoT, AI, VR and AR. You will gain a fundamental understanding of how these technologies work and their potential applications. This knowledge will help you to stay ahead of the curve in the digital age and to explore new ways of using technology in the classroom.'
      },
      {
        key: 'ss',
        image: '/assets/images/education/Soft skills.webp',
        title: "Soft Skills for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'The modern workforce demands more than just technical skills. Developing soft skills such as effective communication, teamwork, problem-solving, critical thinking and a strong sense of ethics and responsibility are vital for success in any field. Understanding the importance of these skills can help prepare students for the workforce and enable them to thrive in today\'s technology-driven world.'
      },
      {
        key: 'iot',
        image: '/assets/images/education/6.webp',
        title: "School <span style='font-weight: 400'>Of IOT</span>",
        description: 'Take your Teachining skills to the next level with ANTT Robotics\' all-in-one STEAM learning solutions. Our development boards, sensors, electronics modules, and guided resources make it easy for elementary school teachers to introduce cutting-edge technology into their classrooms. Imagine your students building smart agriculture systems for their villages, creating autonomous vehicles, or designing health care devices - the possibilities are endless with ANTT Robotics. Join the digital revolution and empower your students with the skills they need to succeed in the 21st century.'
      },
    ]);

    const defaultList = [
      {
        key: 'cs',
        image: '/assets/images/education/Computer Science (CS).webp',
        title: "Computer Science (CS) for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'Learn the basics of computational thinking, algorithms, computer architecture, internet and web technologies, and programming concepts with ANTT Robotics training program. Gain the skills necessary to teach students in the digital age. Invest in our program to elevate your teaching and prepare students for future success.'
      },
      {
        key: 'ic',
        image: '/assets/images/education/Introduction to Coding.webp',
        title: "Introduction to Coding for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'Learn the basics of coding and programming with ANTT Robotics program. Covering foundational concepts such as algorithms, computer organization, and web technologies. Hands-on experience with block-based coding, drag-and-drop programming, and engaging coding challenges and projects. Develop the skills necessary to understand and create programs.'
      },
      {
        key: 'ca',
        image: '/assets/images/education/Computer Architecture.webp',
        title: "Computer Architecture for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'Learn the basics of computer organization, including the CPU, memory, and storage. Explore data storage and retrieval and the function of operating systems in managing computer systems.'
      },
      {
        key: 'cp',
        image: '/assets/images/education/Computer Programming.webp',
        title: "Computer Programming & Algorithm for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'Overview of key programming concepts including variables, control flow, functions and algorithms. Understanding data types and how to use them in your code. Learn how to use loops and procedures to control the flow of your program.'
      },
      {
        key: 'bc',
        image: '/assets/images/education/Basic Circuits_.webp',
        title: "Basic Circuits & Electronics for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'Basics of electricity and circuit functions, understanding electronic components, building circuits, experimenting with configurations, understanding sensors and actuators in robotics.'
      },
      {
        key: 'dr',
        image: '/assets/images/education/Data Representation.webp',
        title: "Data Representation for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'In this course, we will delve into the intricacies of data and how it is stored, manipulated and represented. We will learn about different types of data and their properties, as well as understanding the various data structures and algorithms used to organize and manage data. Additionally, we will cover the different methods for data representation and manipulation, providing a comprehensive understanding of the subject matter.'
      },
      {
        key: 'auto',
        image: '/assets/images/education/Automation.webp',
        title: "Automation & Emerging Technology for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'Understanding the basics of automation and robotics Our program provides an introduction to the key concepts of IoT, AI, VR and AR. You will gain a fundamental understanding of how these technologies work and their potential applications. This knowledge will help you to stay ahead of the curve in the digital age and to explore new ways of using technology in the classroom.'
      },
      {
        key: 'ss',
        image: '/assets/images/education/Soft skills.webp',
        title: "Soft Skills for Elementary <span style='font-weight: 400'>School Educator</span>",
        description: 'The modern workforce demands more than just technical skills. Developing soft skills such as effective communication, teamwork, problem-solving, critical thinking and a strong sense of ethics and responsibility are vital for success in any field. Understanding the importance of these skills can help prepare students for the workforce and enable them to thrive in today\'s technology-driven world.'
      },
      {
        key: 'iot',
        image: '/assets/images/education/6.webp',
        title: "School <span style='font-weight: 400'>Of IOT</span>",
        description: 'Take your Teachining skills to the next level with ANTT Robotics\' all-in-one STEAM learning solutions. Our development boards, sensors, electronics modules, and guided resources make it easy for elementary school teachers to introduce cutting-edge technology into their classrooms. Imagine your students building smart agriculture systems for their villages, creating autonomous vehicles, or designing health care devices - the possibilities are endless with ANTT Robotics. Join the digital revolution and empower your students with the skills they need to succeed in the 21st century.'
      },
    ]

    function bySubject(e) {
      setSelected(e.target.value);
      console.log('value: ' + e.target.value);
      console.log(items.filter(item => item.key === e.target.value));
      setItems(defaultList.filter(item => item.key === e.target.value));
    }
  

    useEffect(() => {
      var elements = document.querySelectorAll('.dropdown > button');
      
      for(var i in elements) {
          if(elements.hasOwnProperty(i)) {
              elements[i].onclick = function() {
                  this.parentElement.querySelector('.dropdown-menu').classList.toggle("dropdown-active");
              }
          }
      }
    }, []);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image education-hero" style={{ backgroundImage: 'url(/assets/images/bg/Classroom-Resource-hero-img.webp)'}}>
                <ElementaryHeroTabs selectedTab={'resources'}/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center pt--100">
                                <h2>Classroom Resource</h2>
                                <p style={{fontSize: 20}}>Ignite Imagination Through ANTT STEAM Resources</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div style={{marginTop: 100}} className="rn-service-details bg_color--1">
                <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">

                                    {/* Start Single Area */}
                                    <div className="row">
                                      <div style={{position: 'relative', width: '100%'}} className="form-group">
                                        <img width={30} style={{position: 'absolute', top: 15, left: 32}} src='/assets/images/icons/Search icon.webp'/>
                                        <input
                                            style={{border: '1px solid #ced4da', padding: '0 80px', borderRadius: 30, height: 60, fontSize: 18, boxShadow: '10px 30px 40px rgba(158, 162, 160, 0.1)'}}
                                            name="fullname"
                                            type="text"
                                            className="form-control"
                                            placeholder="Search classroom resources"
                                            size="20"
                                        />                  
                                      </div>
                                    </div>
                                    
                                    
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row" style={{marginTop: 80}}>
                                        <div className="col-sm-6 col-lg-3">
                                          <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <p style={{margin: 0, fontSize: 18, fontWeight: 600}}>By Subject</p>
                                            <div className="dropdown">
                                              <select onChange={bySubject} value={selected} style={{width: 280, height: 60, fontSize: 18, backgroundColor: 'white', color: '#212529', border: '0.5px solid rgba(0, 0, 0, 0.23)', borderRadius: 8}} class="form-control">
                                                <option value={'cs'}>Digital Literacy</option>
                                                <option value={'ic'}>STEAM</option>
                                                <option value={'ca'}>Robotics</option> 
                                                <option value={'cs'}>Computer Science</option>
                                                <option value={'ca'}>ICT</option> 
                                                <option value={'ss'}>Soft Skills</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>                                  
                                        
                                        <div className="col-sm-6 col-lg-3">
                                          <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <p style={{margin: 0, fontSize: 18, fontWeight: 600}}>By Solution</p>
                                            <div className="dropdown">
                                              <select style={{width: 280, height: 60, fontSize: 18, backgroundColor: 'white', color: '#212529', border: '0.5px solid rgba(0, 0, 0, 0.23)', borderRadius: 8}} class="form-control">
                                                <option>Coding Certification Course</option>
                                                <option>EduBlock</option> 
                                                <option>Microsoft Office Pacakge</option> 
                                                <option>Google Workplace</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                        
                                        <div className="col-sm-6 col-lg-3">
                                          <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <p style={{margin: 0, fontSize: 18, fontWeight: 600}}>By Grade</p>
                                            <div className="dropdown">
                                              <select style={{width: 280, height: 60, fontSize: 18, backgroundColor: 'white', color: '#212529', border: '0.5px solid rgba(0, 0, 0, 0.23)', borderRadius: 8}} class="form-control">
                                                <option>Preschool</option> 
                                                <option>Elementary School</option>  
                                                <option>Middle School</option> 
                                                <option>High School</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                        
                                        <div className="col-sm-6 col-lg-3">
                                          <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <p style={{margin: 0, fontSize: 18, fontWeight: 600}}>By Type</p>
                                            <div className="dropdown">
                                              <select style={{width: 280, height: 60, fontSize: 18, backgroundColor: 'white', color: '#212529', border: '0.5px solid rgba(0, 0, 0, 0.23)', borderRadius: 8}} class="form-control">
                                                <option>Online</option> 
                                                <option>Offline </option>
                                                <option>Hardware Based</option> 
                                                <option>Software Based</option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>
                                        
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    {items.map((e, index) => {
                                      if (index > 0 && index % 2 == 1) {
                                        const couple = [
                                          <div className="col-lg-6 col-md-6 col-12 text-left">
                                            <div class="card service-card" style={{border: 'none', boxShadow: '2px 3px 20px rgba(0, 0, 0, 0.14)', borderRadius: '0.65rem', height: 960 }}>
                                                <img style={{borderTopLeftRadius: '0.65rem', borderTopRightRadius: '0.65rem', backgroundColor: '#B9FFCC', height: 390}} className="card-img-top" src={items[index - 1].image} alt="Card image cap"/>
                                                <div className="card-body" style={{display: 'flex', flexDirection: 'column', padding: 40, justifyContent: 'space-between'}}>
                                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <h5 className="card-title" style={{fontSize: 30, fontWeight: 700, marginBottom: 30}} dangerouslySetInnerHTML={{__html: items[index - 1].title}}></h5>
                                                    <p style={{fontSize: 16, marginBottom: 30}} className="card-text">{items[index - 1].description}</p>
                                                  </div>
                                                    
                                                    <a href={`#`} className="rn-button-style--2 btn-primary-color" style={{display: 'inline-block', alignSelf: 'flex-end', border: 'none', backgroundColor: '#F0FDF4', color: '#119F62'}}>Read more...</a>
                                                </div>
                                            </div>
                                          </div>,
                                          <div className="col-lg-6 col-md-6 col-12 text-left">
                                            <div class="card service-card" style={{border: 'none', boxShadow: '2px 3px 20px rgba(0, 0, 0, 0.14)', borderRadius: '0.65rem', height: 960 }}>
                                                <img style={{borderTopLeftRadius: '0.65rem', borderTopRightRadius: '0.65rem', backgroundColor: '#B9FFCC', height: 390}} className="card-img-top" src={items[index].image} alt="Card image cap"/>
                                                <div className="card-body" style={{display: 'flex', flexDirection: 'column', padding: 40, justifyContent: 'space-between'}}>
                                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <h5 className="card-title" style={{fontSize: 30, fontWeight: 700, marginBottom: 30}} dangerouslySetInnerHTML={{__html: items[index].title}}></h5>
                                                    <p style={{fontSize: 16, marginBottom: 30}} className="card-text">{items[index].description}</p>
                                                  </div>
                                                    
                                                    <a href={`#`} className="rn-button-style--2 btn-primary-color" style={{display: 'inline-block', alignSelf: 'flex-end', border: 'none', backgroundColor: '#F0FDF4', color: '#119F62'}}>Read more...</a>
                                                </div>
                                            </div>
                                          </div>
                                        ];
                                        return (
                                          <div className="row align-items-center" style={{marginTop: index == 1 ? 150 : 30, marginLeft: -4, marginRight: 0}}>
                                            {couple} 
                                          </div>
                                        )
                                      } else if (index % 2 == 0 && index == items.length - 1) {
                                        return (
                                            <div className="row align-items-center" style={{marginTop: index === 0 ? 150 : 30, marginLeft: -4, marginRight: 0}}>
                                              <div className="col-lg-6 col-md-6 col-12 text-left">
                                              <div class="card service-card" style={{border: 'none', boxShadow: '2px 3px 20px rgba(0, 0, 0, 0.14)', borderRadius: '0.65rem', height: 960 }}>
                                                <img style={{borderTopLeftRadius: '0.65rem', borderTopRightRadius: '0.65rem', backgroundColor: '#B9FFCC', height: 390}} className="card-img-top" src={items[index].image} alt="Card image cap"/>
                                                <div className="card-body" style={{display: 'flex', flexDirection: 'column', padding: 40, justifyContent: 'space-between'}}>
                                                  <div style={{display: 'flex', flexDirection: 'column'}}>
                                                    <h5 className="card-title" style={{fontSize: 30, fontWeight: 700, marginBottom: 30}} dangerouslySetInnerHTML={{__html: items[index].title}}></h5>
                                                    <p style={{fontSize: 16, marginBottom: 30}} className="card-text">{items[index].description}</p>
                                                  </div>
                                                    
                                                    <a href={`#`} className="rn-button-style--2 btn-primary-color" style={{display: 'inline-block', alignSelf: 'flex-end', border: 'none', backgroundColor: '#F0FDF4', color: '#119F62'}}>Read more...</a>
                                                </div>
                                              </div>
                                            </div> 
                                          </div>
                                        )
                                      }
                                    })
                                    }
                                    {/* <div className="row align-items-center" style={{marginTop: 150, marginLeft: -4, marginRight: 0}}>                                  
                                      <div className="col-lg-6 col-md-6 col-12 text-left">
                                        <div class="card service-card" style={{border: 'none', boxShadow: '2px 3px 20px rgba(0, 0, 0, 0.14)', borderRadius: '0.65rem', }}>
                                            <img style={{borderTopLeftRadius: '0.65rem', borderTopRightRadius: '0.65rem', backgroundColor: '#B9FFCC', height: 390}} className="card-img-top" src={`/assets/images/education/Digital Literacy.webp`} alt="Card image cap"/>
                                            <div className="card-body" style={{display: 'flex', flexDirection: 'column', padding: 40}}>
                                                <h5 className="card-title" style={{fontSize: 40, fontWeight: 700, marginBottom: 30}}>Digital <span style={{fontWeight: 400}}>Literacy</span></h5>
                                                <p style={{fontSize: 16, marginBottom: 30}} className="card-text">Unlock the power of learning with Digital Literacy! Our program helps preschool teachers master the fundamentals of STEM and how to integrate them into their curriculum. Get ready to create an engaging and meaningful learning experience for young children, so they can reach their fullest potential!</p>
                                                <a href={`#`} className="rn-button-style--2 btn-primary-color" style={{display: 'inline-block', alignSelf: 'flex-end', border: 'none', backgroundColor: '#F0FDF4', color: '#119F62'}}>Read more...</a>
                                            </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-12 text-left">
                                          <div class="card service-card" style={{border: 'none', boxShadow: '2px 3px 20px rgba(0, 0, 0, 0.14)', borderRadius: '0.65rem', }}>
                                             
                                              <img style={{borderTopLeftRadius: '0.65rem', borderTopRightRadius: '0.65rem', backgroundColor: '#B9FFCC', height: 390}} className="card-img-top" src={`/assets/images/education/Preschool STEAM.webp`} alt="Card image cap"/>
                                              <div className="card-body" style={{display: 'flex', flexDirection: 'column', padding: 40}}>
                                                  <h5 className="card-title" style={{fontSize: 40, fontWeight: 700, marginBottom: 30}}>Preschool <span style={{fontWeight: 400}}>STEAM</span></h5>
                                                  <p style={{fontSize: 16, marginBottom: 30}} className="card-text">Unlock your preschoolers' true potential with PRESCHOOL STEAM! Our comprehensive suite of assessment tools can help you analyze data and make data-driven decisions, so you can get the most out of your students and create an effective, engaging curriculum. With PRESCHOOL STEAM, you're sure to see your students grow in leaps and bounds!</p>
                                                  <a href={`#`} className="rn-button-style--2 btn-primary-color" style={{display: 'inline-block', alignSelf: 'flex-end', border: 'none', backgroundColor: '#F0FDF4', color: '#119F62'}}>Read more...</a>
                                              </div>
                                          </div>
                                      </div>
                                    </div> */}
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    {/* <div className="row align-items-center" style={{marginTop: 50, marginLeft: -4, marginRight: 0}}>                                  
                                        
                                    </div> */}
                                    {/* End Single Area */}

                                    {/* Start Finding Us Area  */}
                                    {/* <div className="row align-items-center" style={{marginTop: 30, marginLeft: -4, marginRight: 0}}>                                  
                                        <div className="col-lg-6 col-md-6 col-12 text-left" style={{paddingBottom: 30}}>
                                          <div class="card service-card" style={{border: 'none', boxShadow: '2px 3px 20px rgba(0, 0, 0, 0.14)', borderRadius: '0.65rem', }}>
                                              
                                              <img style={{borderTopLeftRadius: '0.65rem', borderTopRightRadius: '0.65rem', backgroundColor: '#B9FFCC', height: 390}} className="card-img-top" src={`/assets/images/education/EDUBLOCKS.webp`} alt="Card image cap"/>
                                              <div className="card-body" style={{display: 'flex', flexDirection: 'column', padding: 40}}>
                                                  <h5 className="card-title" style={{fontSize: 40, fontWeight: 700, marginBottom: 30}}>Edu<span style={{fontWeight: 400}}>Blocks</span></h5>
                                                  <p style={{fontSize: 16, marginBottom: 30}} className="card-text">Unlock the power of learning with ANTT! Our educational software and apps provide a powerful way to engage students with interactive simulations, games, and other tools. With ANTT, you can make learning more engaging, meaningful, and fun for your students. Take the guesswork out of instruction and give your students the edge they need to succeed.</p>
                                                  <a href={`#`} className="rn-button-style--2 btn-primary-color" style={{display: 'inline-block', alignSelf: 'flex-end', border: 'none', backgroundColor: '#F0FDF4', color: '#119F62'}}>Read more...</a>
                                              </div>
                                          </div>
                                      </div>
                                    </div> */}
                                    {/* End Finding Us Area  */}

                            

                                </div>
                            </div>
                        </div>
                    
                </div>
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-730}  />

        </React.Fragment>
    )
}
export default Resources;