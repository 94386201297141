import React, { useEffect, useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ElementaryHeroTabs from "../../blocks/AcademicHeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";
import ContactForm from "../contact/ContactForm";
import VocationalCollapsible from '../../blocks/AcademicCollapsible'

const programs = [
  {
    title: 'Hitech STEAM Robotics Lab',
    description: 'Upgrade Your School\'s Lab with Our Latest Tools and Resources for Hands-On Learning and Collaboration',
    image: 'a'
  },
  {
    title: 'STEAM for Staff & Teachers',
    description: 'Unlock the Potential of Your School\'s Educators with Customizable Professional Development',
    image: 'b'
  },
  {
    title: 'Coding Certificate for Teachers',
    description: 'Become a Leader in STEAM Education and Enhance Your Classroom Instruction with Our Accredited Program',
    image: 'c'
  }
];

const opportunities = [
  {
    title: 'Find Us',
    description: 'Share Your Location and Discover Our STEAM Solutions ',
    image: 'd'
  },
  {
    title: 'Local STEAM Experts',
    description: 'Connect with Us and Enhance Your Education',
    image: 'e'
  },
  {
    title: 'Pricing and Consultation',
    description: 'Share Your Location and Discover Our STEAM Solutions ',
    image: 'f'
  }
];


function ProductsServices() {
    const [selected, setSelected] = useState(0);
    useEffect(() => {
      const script = document.createElement("script");
      script.src = "//embed.typeform.com/next/embed.js";
      script.async = true;
      document.body.appendChild(script);

      // Cleanup script when the component is unmounted
      return () => {
          document.body.removeChild(script);
      };
  }, []);
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image about_bg" 
                  style={{ 
                        backgroundColor: '#4ABEAD', 
                        display: 'flex',
                        backgroundPosition: 'top', 
                        alignItems: 'center',
                        position: 'relative', 
                  }}
            >
                <ElementaryHeroTabs selectedTab={'product'}/>
                <div className="container">
                  <div className="row" style={{alignItems: 'center'}}>
                      
                      <div className="col-lg-6 about_hero_content">
                          <h2>Empower Your Academic School with STEAM & Digital Literacy</h2>
                          <p>All-inclusive classroom solutions & educator training by ANTT Robotics</p>
                      </div>
                      
                      <div className="col-lg-6 about_hero_image" style={{alignSelf: 'flex-end'}}>
                          <img src="/assets/images/academic/hero i mg.webp"/>
                      </div>
                      
                      
                  </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1 academic-prod-serv">
                
              <div className="service-details-inner">
                  <div className="inner">
                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                          <div className="row academic-products-intro">
                            <div className="col-lg-6 content">
                              <p>We also provide all classroom solutions for STEAM laboratory, interior, teaching resources, handbook, quizzes for students, and hands-on training sessions. Additionally, we offer a comprehensive digital literacy training program for educators, empowering them with the skills and knowledge they need to effectively integrate technology and digital tools in the classroom. Our training program includes a wide range of resources and tools that educators can use to enhance their instruction and engage students in learning.</p>
                            </div>
                            <div className="col-lg-6 image">
                              <img src="/assets/images/academic/1 (9).webp"/>
                            </div>
                          </div>
                        
                          <div className="color-bar-wrapper" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                              <div className="col-lg-8 section-title-40">
                                <h2 style={{marginBottom: 80}}>Give Your Students a Competitive Edge With STEAM Education</h2>
                              </div>
                              <div className="color-bar-header-container">
                                <div style={{backgroundColor: '#A5E968'}} className="color-bar-header">
                                  <p>Pre-School</p>
                                </div>
                                <div style={{backgroundColor: '#1CB1D0'}} className="color-bar-header">
                                  <p>Elementary School</p>
                                </div>
                                <div style={{backgroundColor: '#2FD6AB'}} className="color-bar-header">
                                  <p>Middle School</p>
                                </div>
                                <div style={{backgroundColor: '#07ABEB'}} className="color-bar-header">
                                  <p>High School</p>
                                </div>
                                <div style={{backgroundColor: '#9BCB2E'}} className="color-bar-header">
                                  <p>Vocational School</p>
                                </div>
                                <div style={{backgroundColor: '#3367CB'}} className="color-bar-header">
                                  <p>Higher Education</p>
                                </div>
                              </div>
                              <div className="color-bar-body">
                                <div style={{backgroundColor: '#B2F674',}} className="color-bar"></div>
                                <div style={{backgroundColor: '#1BB2D1',}} className="color-bar"></div>
                                <div style={{backgroundColor: '#26DFB0',}} className="color-bar"></div>
                                <div style={{backgroundColor: 'rgba(25, 160, 214, 0.77)'}} className="color-bar"></div>
                                <div style={{backgroundColor: '#9BCB3A'}} className="color-bar"></div>
                                <div style={{backgroundColor: '#3168CF'}} className="color-bar"></div>
                                <div className="transparent-body">
                                  <img style={{marginLeft: '200px', width: 400}} src="/assets/images/academic/1 (10).webp"/>
                                  <img style={{marginLeft: '0', width: 400}} src="/assets/images/academic/group_1000009217.webp"/>
                                  <img style={{marginLeft: '800px', width: 400}} src="/assets/images/academic/2 (10).webp"/>
                                  
                                  <img style={{marginLeft: '400px', width: 400}} src="/assets/images/academic/3 (9).webp"/>
                                  <img style={{marginLeft: '0', width: 400}} src="/assets/images/academic/4 (7).webp"/>
                                  <img style={{marginLeft: '400px', width: 600}} src="/assets/images/academic/5 (5).webp"/>
                                  <img style={{marginLeft: '400px', width: 600}} src="/assets/images/academic/6 (3).webp"/>
                                  <img style={{marginLeft: '400px', width: 800}} src="/assets/images/academic/7 (1).webp"/>
                                  <img style={{marginLeft: '200px', width: 1000}} src="/assets/images/academic/8 (1).webp"/>
                                  <img style={{marginLeft: '600px', width: 600}} src="/assets/images/academic/11 (1).webp"/>
                                  <img style={{marginLeft: '400px', width: 800}} src="/assets/images/academic/12 (1).webp"/>
                                  <img style={{marginLeft: '400px', width: 800}} src="/assets/images/academic/13 (1).webp"/>
                                  <img style={{marginLeft: '600px', width: 600}} src="/assets/images/academic/14 (1).webp"/>
                                </div>
                              </div>

                              <div className="color-bar-horizontal">
                                <VocationalCollapsible backgroungColor={'#A5E968'} title='Pre-School'>
                                  <img src="/assets/images/academic/group_1000009217.webp"/>
                                  <img src="/assets/images/academic/4 (7).webp"/>
                                </VocationalCollapsible>
                                <VocationalCollapsible backgroungColor={'#1CB1D0'} title='Elementary School'>
                                  <img src="/assets/images/academic/1 (10).webp"/>
                                  <img src="/assets/images/academic/group_1000009217.webp"/>
                                  <img src="/assets/images/academic/4 (7).webp"/>
                                  <img src="/assets/images/academic/8 (1).webp"/>
                                </VocationalCollapsible>
                                <VocationalCollapsible backgroungColor={'#2FD6AB'} title='Middle School'>
                                  <img src="/assets/images/academic/1 (10).webp"/>
                                  <img src="/assets/images/academic/3 (9).webp"/>
                                  <img src="/assets/images/academic/5 (5).webp"/>
                                  <img src="/assets/images/academic/6 (3).webp"/>
                                  <img src="/assets/images/academic/7 (1).webp"/>
                                  <img src="/assets/images/academic/8 (1).webp"/>
                                  <img src="/assets/images/academic/12 (1).webp"/>
                                  <img src="/assets/images/academic/13 (1).webp"/>
                                </VocationalCollapsible>
                                <VocationalCollapsible backgroungColor={'#07ABEB'} title='High School'>
                                  <img src="/assets/images/academic/3 (9).webp"/>
                                  <img src="/assets/images/academic/5 (5).webp"/>
                                  <img src="/assets/images/academic/6 (3).webp"/>
                                  <img src="/assets/images/academic/7 (1).webp"/>
                                  <img src="/assets/images/academic/8 (1).webp"/>
                                  <img src="/assets/images/academic/11 (1).webp"/>
                                  <img src="/assets/images/academic/12 (1).webp"/>
                                  <img src="/assets/images/academic/13 (1).webp"/>
                                  <img src="/assets/images/academic/14 (1).webp"/>
                                </VocationalCollapsible>
                                <VocationalCollapsible backgroungColor={'#9BCB2E'} title='Vocational School'>
                                  <img src="/assets/images/academic/2 (10).webp"/>
                                  <img src="/assets/images/academic/5 (5).webp"/>
                                  <img src="/assets/images/academic/6 (3).webp"/>
                                  <img src="/assets/images/academic/7 (1).webp"/>
                                  <img src="/assets/images/academic/8 (1).webp"/>
                                  <img src="/assets/images/academic/11 (1).webp"/>
                                  <img src="/assets/images/academic/12 (1).webp"/>
                                  <img src="/assets/images/academic/13 (1).webp"/>
                                  <img src="/assets/images/academic/14 (1).webp"/>
                                </VocationalCollapsible>
                                <VocationalCollapsible backgroungColor={'#3367CB'} title='Higher Education'>
                                  <img src="/assets/images/academic/2 (10).webp"/>
                                  <img src="/assets/images/academic/7 (1).webp"/>
                                  <img src="/assets/images/academic/8 (1).webp"/>
                                  <img src="/assets/images/academic/11 (1).webp"/>
                                  <img src="/assets/images/academic/12 (1).webp"/>
                                  <img src="/assets/images/academic/13 (1).webp"/>
                                  <img src="/assets/images/academic/14 (1).webp"/>
                                </VocationalCollapsible>
                              </div>
                              
                          </div>
                          

                          <div className="find-program" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div className="col-lg-8 section-title-40">
                              <h2 style={{marginBottom: 30}}>Find a program for your School</h2>
                              <p style={{marginBottom: 50}}>Keep your invention business live & guarantee High gain! Launch your global robotic business for good!</p>
                            </div>
                          </div>

                          <div className="row">
                              {programs.map( (val , i) => (
                                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 text-left" key={i} style={{paddingBottom: 30}}>
                                      <div class="card service-card" style={{border: 'none', boxShadow: '2px 3px 20px rgba(0, 0, 0, 0.14)', borderRadius: '0.65rem', height: 530 }}>
                                          <div style={{
                                              borderTopLeftRadius: '0.65rem', 
                                              borderTopRightRadius: '0.65rem', 
                                              backgroundColor: '#4ABEAD', 
                                              height: 260,
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center'
                                          }}>
                                              <img style={{width: '70%'}} id="service-image" className="card-img-top" src={`/assets/images/academic/${val.image}.webp`} alt="Card image cap"/>
                                          </div>
                                          
                                          <div className="card-body" style={{display: 'flex', flexDirection: 'column', padding: 20, justifyContent: 'space-between'}}>
                                              <div style={{display: 'flex', flexDirection: 'column'}}>
                                                  <h5 className="card-title" style={{fontWeight: 700, marginBottom: 30}}>{val.title}</h5>
                                                  <p style={{fontSize: 16, marginBottom: 30}} className="card-text">{val.description}</p>
                                              </div>
                                              
                                              <a href={`/service-details/${val.slug}`} className="homepage-services-btn">Read more...</a>
                                          </div>
                                      </div>
                                  </div>
                                  
                              ))}
                          </div>

                          <div className="find-program" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div className="col-lg-6 section-title-40">
                              <h2 style={{marginBottom: 50}}>Discover Pricing and Partnership Opportunity</h2>
                            </div>
                          </div>

                          <div className="row">
                              {opportunities.map( (val , i) => (
                                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 text-left" key={i} style={{paddingBottom: 30}}>
                                      <div class="card service-card" style={{border: 'none', boxShadow: '2px 3px 20px rgba(0, 0, 0, 0.14)', borderRadius: '0.65rem', height: 530 }}>
                                          <div style={{
                                              borderTopLeftRadius: '0.65rem', 
                                              borderTopRightRadius: '0.65rem', 
                                              backgroundColor: 'white', 
                                              height: 300,
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center'
                                          }}>
                                              <img style={{width: '70%'}} id="service-image" className="card-img-top" src={`/assets/images/academic/${val.image}.webp`} alt="Card image cap"/>
                                          </div>
                                          
                                          <div className="card-body" style={{display: 'flex', flexDirection: 'column', padding: '0 20px 20px 20px', justifyContent: 'flex-start', alignItems: 'center'}}>
                                             
                                                  <h5 className="card-title" style={{fontWeight: 700, marginBottom: 10}}>{val.title}</h5>
                                                  <p style={{fontSize: 16, marginBottom: 30, textAlign: 'center'}} className="card-text">{val.description}</p>
                                         
                                              
                                              <a href="/contact" className="generic-color-btn">Contact Us</a>
                                          </div>
                                      </div>
                                  </div>
                                  
                              ))}
                          </div>

                          <div className="rn-contact-us ptb--30" id="contact" style={{marginTop: 120}}>
                            <div className="contact-form--1">
                                
                                <div className="row row--35" style={{alignItems: 'stretch'}}>
                                    <div className="col-12 order-2 order-lg-1">
                                        <div className="section-title text-left mb--50">
                                            <p style={{color: '#2c7fc2', marginBottom: 2, fontSize: 30}}>Interested?</p>
                                            <h2 style={{marginBottom: 15, fontSize: 40, fontWeight: 400}} className="title">Book your <span style={{fontWeight: 600}}>Free</span> Session</h2>
                                            <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                        </div>
                                        <div className="form-wrapper">
                                            {/* <ContactForm /> */}
                                            <div data-tf-live="01J8T05PHQMQ54P9ANXE209CTP" style={{ width: '100%', height: '500px' }}></div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 order-1 order-lg-2">
                                        <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                            <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                        </div>
                                    </div> */}
                                </div>
                                
                            </div>
                          </div>
                       
                      </div>
                      
                      {/* End Single Area */}
                      

              

                  </div>
              </div>
                        
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer  />

        </React.Fragment>
    )
}
export default ProductsServices;
