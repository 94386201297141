import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";
import TabsLogin from "./tab/TabsLogin";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class PrivacyPolicy extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Privacy Policy' />

                <HeaderFixed />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area bg_image" style={{backgroundImage: 'url(/assets/images/bg/login-banner.png)', paddingBottom: 152, paddingTop: 96}}>
                    <div style={{transform: 'scale(0.8)'}} className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Privacy Policy</h2>
                                    {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--80 bg_color--5" 
                     style={{
                                backgroundImage: 'url(/assets/images/bg/login-background.jpeg)', 
                                paddingBottom: 152, 
                                paddingTop: 120, 
                                backgroundRepeat: 'no-repeat', 
                                backgroundSize: 'cover', 
                                backgroundPosition: 'center center'
                            }} >
                    <div className="container" style={{transform: 'scale(1)'}}>
                       
                        <div className="row">
                        
                                <div className="row row--35" style={{alignItems: 'center'}}>
                                    <div className="col-sm-12">
                                      <div className="card" style={{padding: 40}}>
                                        <h2 className="text-center">Privacy Policy</h2>
                                        <p>ANTT Robotics LTD. built the EduBlock - Learning Programming using Robotics app as a Free app. This SERVICE is provided by ANTT Robotics LTD. at no cost and is intended for use as is.</p>
                                        <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
                                        <p className="text-justify">If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at EduBlock - Learning Programming using Robotics unless otherwise defined in this Privacy Policy.</p>
                                        <h5>Information Collection and Use</h5><hr/>
                                        <p>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy. The app does use third-party services that may collect information used to identify you. Link to the privacy policy of third party service providers used by the app</p>
                                        <ul className="text-left" style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                            <li><p>Google Play Services</p></li>
                                            <li><p>Google Play Services</p></li>
                                            <li><p>Firebase Crashlytics</p></li>
                                        </ul>

                                        <h3 className="text-secondary">Log Data</h3><hr/>
                                        <p>We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (â€œIPâ€) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</p>
                                        <h3 className="text-secondary">Cookies</h3><hr/>
                                        <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. This Service does not use these â€œcookiesâ€ explicitly. However, the app may use third party code and libraries that use â€œcookiesâ€ to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>
                                        <h3 className="text-secondary">Service Providers</h3><hr/>
                                        <p>We may employ third-party companies and individuals due to the following reasons:</p>
                                        <ul style={{listStyleType: 'disc', marginLeft: '20px'}}>
                                            <li><p>To facilitate our Service;</p></li>
                                            <li><p>To provide the Service on our behalf;</p></li>
                                            <li><p>To perform Service-related services;</p></li>
                                            <li><p>To assist us in analyzing how our Service is used.</p></li>
                                        </ul>
                                        <p>We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpo.</p>
                                        
                                        <h3 className="text-secondary">Security</h3><hr/>
                                        <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                                        <h3 className="text-secondary">Links to Other Sites</h3><hr/>
                                        <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                                        <h3 className="text-secondary">Children Privacy</h3><hr/>
                                        <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions. We will immediately take action If parents are contact with us regarding the age contradictory.</p>
                                        <h3 className="text-secondary">Family and children Policy</h3><hr/>
                                        <p>The service is fully compatible with google children and families requirement. The service is designed for education purpose to learning robotics and programming skills and problem solving.These Services do not address anyone under the age of 13. If anyone feels that any contradictory issue can contact with the proper information. Regarding information we will immediately take actions. We are maintaining and updating our privacy policy according to the google play requirements.</p>
                                        
                                        <h3 className="text-secondary">Changes to This Privacy Policy</h3><hr/>
                                        <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
                                        <h5>Contact Us</h5><hr/>
                                        <p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>
                                        <ul style={{listStyleType: 'disc', marginLeft: '20px'}}><li><p>By email: support@anttrobotics.com</p></li></ul>
                                      </div>
                                    </div>
                                </div>
                           
                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--60 bg_color--1">
                    <div className="contact-form--1">
                        
                    </div>
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                {/* <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '520px', width: '100%' }}>
                        <GoogleMapReact
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            lat={23.7800221}
                            lng={90.4244863}
                            text="ANTT Robotics R&D office"
                        />
                        </GoogleMapReact>
                    </div>
                </div> */}
                {/* End Contact Map  */}
                

                {/* Start Brand Area */}
                {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer topMargin={-170} />
                
            </React.Fragment>
        )
    }
}
export default PrivacyPolicy