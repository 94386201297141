import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img style={{height: '60%'}} src="/assets/images/brand/brand-01.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '60%'}} src="/assets/images/brand/brand-02.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-03.png" alt="Logo Images"/>
                    </li>
                    <li style={{backgroundColor: '#263786'}}>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-04.webp" alt="Logo Images"/>
                    </li>
                    {/* <li>
                        <img src="/assets/images/brand/brand-05.png" alt="Logo Images"/>
                    </li> */}
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-06.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-07.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-08.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-09.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-10.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-11.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-12.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-13.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-14.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-15.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-16.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-17.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-18.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-19.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-20.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img style={{height: '80%'}} src="/assets/images/brand/brand-21.webp" alt="Logo Images"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;