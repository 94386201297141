import React from 'react';
import { FaFileDownload } from "react-icons/fa";

function CurriculumSubItem({ title }) {
  return (
    <div style={{
      display: 'flex', 
      height: 99, 
      flexDirection: 'row', 
      padding: '0 30px', 
      alignItems: 'center', 
      justifyContent: 'space-between',
      backgroundColor: '#F1F1F1',
      borderRadius: 10,
      marginBottom: 10,
      width: '90%'
    }}>
      <h6 style={{margin: 0, marginLeft: 60, fontSize: 25, fontWeight: 500, color: '#373636'}}>{title}</h6>
      <button className="btn"><FaFileDownload size={30}/></button>
    </div>
  )
}

export default CurriculumSubItem;
