import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ElementaryHeroTabs from "../../blocks/HigherEducationHeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";

const products = [
  {name: 'School of IoT 1.1', image: '1'},
  {name: 'Industrial IoT', image: '2'},
  {name: 'School of Blocks', image: '3'},
  {name: 'ANTT Wearable Programming Device ', image: '4'},
  {name: 'Maternity Care Learning Kit', image: '5'},
  {name: 'Smart Home Learning Kit', image: '6'},
  {name: 'Industrial Robotics Arm Learning kit', image: '7'},
  {name: 'Smart Agriculture Learning kit ', image: '8'},
];



function SteamVocational() {
    const [selected, setSelected] = useState(0);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image vocational_bg" 
                  style={{ 
                        backgroundColor: '#4ABEAD', 
                        display: 'flex',
                        backgroundPosition: 'top', 
                        alignItems: 'center',
                        position: 'relative', 
                  }}
            >
                <ElementaryHeroTabs selectedTab={'product'}/>
                <div className="container">
                  <div className="row" style={{alignItems: 'center'}}>
                      
                      <div className="col-lg-6 about_hero_content">
                          <h2>Drive Your Higher Education Institute's Success with STEAM & Digital Literacy</h2>
                          <p>Supercharge your career with STEM education: A must-have for higher education institute students</p>
                      </div>
                      
                      <div className="col-lg-6 about_hero_image" style={{alignSelf: 'flex-end'}}>
                          <img src="/assets/images/highered/product-service/hero.webp"/>
                      </div>
                      
                      
                  </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1 product-vocational-body">
                
              <div className="service-details-inner">
                  <div className="inner">
                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                          <div className="row">
                            <p style={{fontSize: 20, lineHeight: '35px'}}>At ANTT Robotics, we offer a complete range of classroom solutions designed to support STEAM education, including teaching resources, hands-on training sessions, quizzes for students, and a comprehensive handbook for educators. We also provide a digital literacy training program to help educators integrate technology and digital tools into their instruction effectively. Our training program includes a wealth of resources and tools to help educators develop the skills and knowledge they need to engage students in learning and maximize their success in a technology-driven world.</p>
                          </div>
                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 130}}>                                  
                            {products.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12" key={i} style={{paddingBottom: 30}}>
                                    <div class="card service-card" style={{border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '0.65rem' }}>
                                        <div style={{
                                            borderTopLeftRadius: '0.65rem', 
                                            borderTopRightRadius: '0.65rem', 
                                            backgroundColor: '#4ABEAD', 
                                            height: 379,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <img style={{width: '70%'}} id="service-image" className="card-img-top" src={`/assets/images/highered/product-service/${val.image}.webp`} alt="Card image cap"/>
                                        </div>
                                        
                                        <div className="card-body" style={{display: 'flex', flexDirection: 'column', padding: 20, height: 110, justifyContent: 'center'}}>
                                           
                                                <h5 className="card-title" style={{fontWeight: 700, fontSize: 30, textAlign: 'center'}}>{val.name}</h5>
                                            
                                            
              
                                        </div>
                                    </div>
                                </div>
                                
                            ))}
                          </div>
                          {/* End Single Area */}

                         
                        

                          {/* <div className="rn-contact-us ptb--30" id="contact" style={{marginTop: 120}}>
                            <div className="contact-form--1">
                                
                                <div className="row row--35" style={{alignItems: 'stretch'}}>
                                    <div className="col-12 order-2 order-lg-1">
                                        <div className="section-title text-left mb--50">
                                            <p style={{color: '#2c7fc2', marginBottom: 2, fontSize: 30}}>Interested?</p>
                                            <h2 style={{marginBottom: 15, fontSize: 40, fontWeight: 400}} className="title">Book your <span style={{fontWeight: 600}}>Free</span> Session</h2>
                                            <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                        </div>
                                        <div className="form-wrapper">
                                            <ContactForm />
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </div>
                          </div> */}
                       
                      </div>
                      
                      {/* End Single Area */}
                      

              

                  </div>
              </div>
                        
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-360}  />

        </React.Fragment>
    )
}
export default SteamVocational;
