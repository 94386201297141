import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";
import {Link} from 'react-router-dom';
import HeroTabs from "../../blocks/MiddleSchoolHeroTabs";
import ModalVideo from 'react-modal-video';


function SteamIct() {
  const [isOpen, setIsOpen] = useState(false);

  function openModal () {
    setIsOpen(true);
  }
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image education-hero" style={{ backgroundImage: 'url(/assets/images/bg/steam-ict-hero.webp)'}}>
                <HeroTabs selectedTab={'stem'}/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center pt--100">
                                <h2>Steam & ICT</h2>
                                <p style={{fontSize: 20}}>Learn STEAM & Teach Digital Literacy Skills</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1">
                <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container" id="preschool-stict">
                  <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <h2 style={{alignSelf: 'flex-start', marginBottom: 80, fontSize: 50}} className="title">Preparing Middle School Teachers for the Future With Steam & ICT Education</h2>
                                        <img style={{marginBottom: 70}} src="/assets/images/education/What_is_STEAM _ ICT.webp"/>
                                        <p className="description">The integration of Science, Technology, Engineering, Arts, and Mathematics (STEAM) in the classroom is a unique approach to education that emphasizes the connections between these subjects and how they can be applied to real-world problems. This type of education is crucial in preparing students for a future that is becoming increasingly technology-driven. Additionally, digital literacy is an essential skill that students need in today's digital age. As a middle school teacher, incorporating both STEAM and digital literacy into your teaching can create interactive and engaging lesson plans that keep students interested and motivated. Furthermore, using technology in the classroom allows educators to differentiate instruction and meet the diverse needs of students. The ANTT Robotics STEAM education program empowers students with skills that are in high demand in today's economy, providing a strong foundation for their future careers.</p>
                                      
                                    </div>
                                    
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0, marginTop: 150}}>                                  
                                      <div className="col-lg-6" >
                                        <div style={{
                                          height: 460,
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          backgroundColor: '#F0F9FF'
                                        }}>
                                          <img style={{width: '60%'}} src="/assets/images/education/2.webp" alt="About Images"/>
                                        </div>
                                        
                                      </div>
                                      <div className="col-lg-6" style={{paddingLeft: 50}}>
                                          <div className="about-inner inner">
                                              <div className="row">
                                                  <div className="section-title service-style--3 text-left mb_sm--0">
                                                      <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Preparing Students for the Future: The Importance of Steam and Digital Literacy in the Classroom</h2>
                                                      <p className="description">By including STEAM subjects and digital literacy in your teaching, you can help your students develop important skills like critical thinking and problem-solving. This approach also opens up opportunities for students to explore various career paths in fields like healthcare, manufacturing, computer programming, and engineering. Overall, incorporating STEAM and digital literacy in education is vital in today's world as it equips students with the tools they need to succeed in the future and navigate the digital landscape</p>
                                                      {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 150}}>
                                        <h2 style={{alignSelf: 'flex-start', marginBottom: 80, fontSize: 40}} className="title">Preparing for the Digital Age: How Our STEAM and Digital Literacy Program Benefits Middle School Teachers</h2>
                                        <img style={{marginBottom: 70}} src="/assets/images/education/Empowering Educators.webp"/>
                                        <p className="description">As technology becomes an integral part of our daily lives, it is crucial for middle school teachers to have the skills and knowledge to effectively integrate it into the classroom. Our STEAM and digital literacy education program is designed to help educators stay ahead of the technology curve and equip their students with the skills needed to succeed in the digital age. Our program includes various resources, tools, and solutions such as a STEAM laboratory, classroom design, teaching materials, handbooks, student quizzes, and hands-on training sessions. Additionally, we provide training on analytics and data tracking tools that enable educators to track student progress and make data-driven decisions to enhance instruction. With our program, middle school teachers can be confident in their ability to integrate technology and digital tools in their teaching and provide their students with the skills they need to thrive in the digital age.</p>
                                      
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 120}}>
                                        <h2 style={{alignSelf: 'flex-start', marginBottom: 80, fontSize: 40}} className="title">Elevate Your Teaching with ANTT Robotics' Educator Training Program</h2>
                                        <img style={{marginBottom: 70}} src="/assets/images/education/Empower Your Teaching.webp"/>
                                        <p className="description">up-to-date with the latest technology trends and ensuring that students are prepared for the digital world. This includes training on new and emerging technologies such as AI, VR, and AR which can be used to supplement instruction and provide new ways for students to learn. We provide training in various forms such as online courses, webinars, and virtual coaching sessions which can be tailored to the specific needs of educators. Our digital literacy training program is designed to empower educators with the skills and knowledge they need to effectively integrate technology and digital tools in the classroom. It provides them with the tools and resources they need to enhance instruction and engage students in learning, and stay up-to-date with the latest technology trends. Investing in our program is an investment in the future of education and the success of our students.</p>
                                        {/* <p className="description">On the other hand, digital literacy is the ability to use technology and digital tools to access, analyze, and create information. It's an essential skill that students need in today's digital age. As an educator, incorporating both STEAM and digital literacy into your teaching can create engaging and interactive lesson plans that keep students interested and motivated. By using technology in the classroom, educators can also differentiate instruction and meet the needs of diverse learners.</p> */}
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Finding Us Area  */}
                                    <div className="row align-items-center" style={{marginTop: 150}}>                                  
                                        <div className="col-lg-6">
                                          <div style={{
                                            height: 480,
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: '#F0F9FF'
                                          }}>
                                            <ModalVideo channel='youtube' isOpen={isOpen} videoId='NPHSGk5rQ8Y' onClose={() => setIsOpen(false)} />
                                            <img className="video-popup" src="/assets/images/icons/play button icon.webp" onClick={openModal}/>
                                            <img style={{width: '80%'}} src="/assets/images/education/Improve Educational Outcomes with ANTT_ (1).webp" alt="About Images"/>
                                          </div>
                                        </div>
                                        <div className="col-lg-6" style={{paddingLeft: 50}}>
                                            <div className="about-inner inner">
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0">
                                                        <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Improve Educational Outcomes with ANTT </h2>
                                                        <p className="description">ANTT offers a comprehensive computer science, IoT, Robotics, or STEAM curriculum and products for schools. Our platform offers the world's most advanced coding learning experience for both adults and children with a focus on STEAM education. Our curriculum teaches problem-solving, teamwork, creativity, and leadership skills. Our platform is available on mobile app and web portal, allowing students to learn anytime, anywhere with our ready-to-use coding software. Our platform also offers a range of resources and tools for teachers, including interactive and engaging </p>
                                                        <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Finding Us Area  */}

                            

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-780}  />

        </React.Fragment>
    )
}
export default SteamIct;