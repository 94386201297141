import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";


class HeaderFixed extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded')
        })
    }


    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open');
    }


    render(){
      window.addEventListener('scroll', function() {
        var value = window.scrollY;
        if (value > 100) {
            document.querySelector('.header--fixed').classList.add('sticky')
        }else{
            document.querySelector('.header--fixed').classList.remove('sticky')
        }
    });

    var elements = document.querySelectorAll('.has-droupdown > a');
    for(var i in elements) {
        if(elements.hasOwnProperty(i)) {
            elements[i].onclick = function() {
                this.parentElement.querySelector('.submenu').classList.toggle("active");
                this.classList.toggle("open");
            }
        }
    }
        const { logo, color='default-color' } = this.props;
        let logoUrl = <img height={62.5} style={{transform: 'scale(0.8)'}} src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
        // if(logo === 'light'){
        //     logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
        // }else if(logo === 'dark'){
        //     logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
        // }else if(logo === 'symbol-dark'){
        //     logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
        // }else if(logo === 'symbol-light'){
        //     logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
        // }else{
        //     logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
        // }

        
        return(
            <header className={`header-area formobile-menu header--fixed default-color`}>
                <div className="header-wrapper" id="header-wrapper" >
                    <div className="header-left">
                        <div className="logo">
                          <a href="/">
                              <img style={{transform: 'scale(0.8)',}} className="logo-1 logo-height" src="/assets/images/logo/logo-main.png" alt="Logo Images"/>
                              <img style={{transform: 'scale(0.8)',}} className="logo-2 logo-height" src="/assets/images/logo/logo-main.png" alt="Logo Images"/>
                          </a>
                        </div>
                    </div>
                    <div className="header-right">
                        <nav className="mainmenunav d-lg-block">
                            <ul className="mainmenu" >
                                <li style={{transform: 'scale(0.8)',}} className="has-droupdown"><Link to="#" >For Educators</Link>
                                    <ul className="submenu">
                                        {/* <li><Link to="/service-pack">Service Package</Link></li>
                                        <li><Link to="/service-details/steam">Steam Education</Link></li> */}
                                        <li className="has-droupdown"><Link to="#">Preschool</Link>
                                            <ul className="submenu">
                                                <li><Link to="/preschool/steam-ict">STEAM & ICT</Link></li>
                                                <li><Link to="/preschool/curriculum">Curriculum</Link></li>
                                                <li><Link to="/preschool/product-service">Product & Services</Link></li>
                                                <li><Link to="/preschool/resources">Resources</Link></li>
                                            </ul>
                                        </li>
                                        <li className="has-droupdown"><Link to="#">Elementary School</Link>
                                            <ul className="submenu">
                                                <li><Link to="/elementary/steam-ict">STEAM & ICT</Link></li>
                                                <li><Link to="/elementary/curriculum">Curriculum</Link></li>
                                                <li><Link to="/elementary/product-service">Product & Services</Link></li>
                                                <li><Link to="/elementary/resources">Resources</Link></li>
                                            </ul>
                                        </li>
                                        <li className="has-droupdown"><Link to="#">Middle School</Link>
                                            <ul className="submenu">
                                                <li><Link to="/middleschool/steam-ict">STEAM & ICT</Link></li>
                                                <li><Link to="/middleschool/product-service">Product & Services</Link></li>
                                                <li><Link to="/middleschool/curriculum">Curriculum</Link></li>
                                                <li><Link to="/middleschool/resources">Resources</Link></li>
                                            </ul>
                                        </li>
                                        <li className="has-droupdown"><Link to="#">High School</Link>
                                            <ul className="submenu">
                                                <li><Link to="/highschool/steam-ict">STEAM & ICT</Link></li>
                                                <li><Link to="/highschool/product-service">Product & Services</Link></li>
                                                <li><Link to="/highschool/curriculum">Curriculum</Link></li>
                                                <li><Link to="/highschool/resources">Resources</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="/coding-certificate">Coding Certificate</Link></li>
                                        {/* <li><Link to="#">Coding Certificate</Link></li> */}
                                        <li><Link to="#">Teacher's Portal</Link></li>
                                    </ul>
                                </li>
                                <li style={{transform: 'scale(0.8)',}} className="has-droupdown"><Link to="#" >For Institutes</Link>
                                    <ul className="submenu">
                                      
                                        <li className="has-droupdown"><Link to="#">Academic School</Link>
                                            <ul className="submenu">
                                                <li><Link to="/academic/steam">STEAM for School</Link></li>
                                                <li><Link to="/academic/product-service">Product & Services</Link></li>
                                                <li><Link to="/academic/curriculum">Curriculum</Link></li>
                                                
                                                <li><Link to="/academic/demo">Get Demo</Link></li>
                                            </ul>
                                        </li>
                                        <li className="has-droupdown"><Link to="#">Vocational School</Link>
                                            <ul className="submenu">
                                                <li><Link to="/vocational/steam">STEAM for Vocational</Link></li>
                                                <li><Link to="/vocational/product-service">Products & Services</Link></li> 
                                                <li><Link to="/vocational/curriculum">Curriculum</Link></li>
                                                <li><Link to="/vocational/demo">Get Demo</Link></li>
                                            </ul>
                                        </li>
                                        <li className="has-droupdown"><Link to="#">Higher Education</Link>
                                            <ul className="submenu">
                                                <li><Link to="/higher-education/steam">Steam</Link></li>
                                                <li><Link to="/higher-education/product-service">Products & Services</Link></li> 
                                                <li><Link to="/higher-education/case-study">Case Study</Link></li>
                                                <li><Link to="/higher-education/demo">Get Demo</Link></li>
                                            </ul>
                                        </li>
                                        {/* <li className="has-droupdown"><Link to="#">Vocational School</Link>
                                            <ul className="submenu">
                                                <li><Link to="#">Curriculum</Link></li>
                                                <li><Link to="#">Resources</Link></li> 
                                                <li><Link to="#">Standards</Link></li>
                                                <li><Link to="#">Case Study</Link></li>
                                            </ul>
                                        </li>
                                        <li className="has-droupdown"><Link to="#">K12</Link>
                                            <ul className="submenu">
                                                <li><Link to="#">Curriculum</Link></li>
                                                <li><Link to="#">Resources</Link></li> 
                                                <li><Link to="#">Standards</Link></li>
                                                <li><Link to="#">Case Study</Link></li>
                                            </ul>
                                        </li>
                                        <li className="has-droupdown"><Link to="#">University</Link>
                                            <ul className="submenu">
                                                <li><Link to="#">Curriculum</Link></li>
                                                <li><Link to="#">Resources</Link></li> 
                                                <li><Link to="#">Standards</Link></li>
                                                <li><Link to="#">Case Study</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link to="#">Teacher Training</Link></li>
                                        <li><Link to="#">Software Services</Link></li> */}
                                    </ul>
                                </li>
                                <li style={{transform: 'scale(0.8)',}} className="has-droupdown"><Link to="#" >Industrial Services</Link>
                                    <ul className="submenu">
                                        <li><a target="_blank" href="https://iotsnacksbox.io/">Iot Snacks Box</a></li>
                                        <li><Link to="/service-details/iot-aiot">IIoT & AIIoT Solutions</Link></li>
                                        <li><Link to="/service-details/pcb">PCB & PCBA</Link></li>
                                        <li><Link to="/service-details/robotic-gadgets">Robotic Gadget</Link></li>
                                        <li><Link to="/service-details/service-robot">Service Robot</Link></li>
                                        
                                        
                                        <li><Link to="/service-details/customised-solutions">Customized Solutions</Link></li>
                                        {/* <li><Link to="/service-pack">Service Package</Link></li>
                                        <li><Link to="/service-details/steam">Steam Education</Link></li> */}
                                        
                                        
                                        
                                        
                                        
                                    </ul>
                                </li>
                                <li style={{transform: 'scale(0.8)',}} className="has-droupdown"><Link to="#" >Our Portfolio</Link>
                                    <ul className="submenu">
                                        {/* <li><Link to="/service-pack">Service Package</Link></li>
                                        <li><Link to="/service-details/steam">Steam Education</Link></li> */}
                                        <li><Link to="/about" >About Us</Link></li>
                                        <li><Link to="#">Partnership</Link></li>
                                        <li><Link to="#">Business Profile</Link></li>
                                        <li><Link to="/investor-portal" >Investor Portal</Link></li>
                                        <li><Link to="#">News</Link></li>
                                        <li><Link to="#">Teachers Community</Link></li>
                                    </ul>
                                </li>
                                <li style={{transform: 'scale(0.8)',}} className="has-droupdown"><Link to="/contact" >Support</Link>
                                    {/* <ul className="submenu">
                                      
                                        <li><Link to="/contact" >Contact Us</Link></li>
                                        <li><Link to="#">FAQ</Link></li>
                                        <li><Link to="#">Support Center</Link></li>
                                        <li><Link to="#">Testimonial</Link></li>
                                    </ul> */}
                                </li>
                                
                                {/* <li style={{transform: 'scale(0.8)'}}><Link to="/product">Product</Link></li> */}

                                {/* <li style={{transform: 'scale(0.8)'}} className="has-droupdown"><Link to="#pages" >Pages</Link>
                                    <ul className="submenu">
                                        <li><Link to="/blog">Blog List</Link></li>
                                        <li><Link to="/blog-details">Blog Details</Link></li>
                                        <li><Link to="/service">Service</Link></li>
                                        <li><Link to="/service-details">Service Details</Link></li>
                                        <li><Link to="/portfolio">Portfolio</Link></li>
                                        <li><Link to="/portfolio-details">Portfolio Details</Link></li>
                                        <li><Link to="/404">404</Link></li>
                                    </ul>
                                </li> */}
                                {/* <li style={{transform: 'scale(0.8)'}} className="has-droupdown"><Link to="#" >Blocks</Link>
                                    <ul className="submenu">
                                        <li><Link to="/portfolio">Portfolio</Link></li>
                        
                                        <li><Link to="/service">Service</Link></li>
                                        <li><Link to="/video-popup">Video Popup</Link></li>
                                        <li><Link to="/progressbar">Progressbar</Link></li>
                                        <li><Link to="/gallery">Gallery</Link></li>
                                        <li><Link to="/counters">Counters</Link></li>
                                        <li><Link to="/blog">Products</Link></li>
                                        <li><Link to="/clint-logo">Clint Logo</Link></li>
                                        <li><Link to="/contact-form">Contact Form</Link></li>
                                        <li><Link to="/google-map">Google Map</Link></li>
                                        <li><Link to="/columns">Columns</Link></li>
                                        <li><Link to="/pricing-table">Pricing Table</Link></li>
                                    </ul>
                                </li> */}
                                
                                
                            </ul>
                        </nav>
                        {/* <div className="header-btn">
                            <a style={{transform: 'scale(0.8)',}} className="rn-btn" href="/login">
                                <span>Login</span>
                            </a>
                        </div> */}
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderFixed;