import React, { Component , Fragment } from "react";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';




class CounterOne extends Component{
    state = {
        didViewCountUp: false
    };
    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({didViewCountUp: true});
        }
    }
    render(){
        let Data = [
            {
                countNum : 10,
                countTitle: 'Patents and research journals in AI, Robotics, IoT, and fourth industrial solutions',
            },
            {
                countNum : 10,
                countTitle: 'Countries footprint and available our Edutech & IIoT products for providing services. ',
            },
            {
                countNum : 40,
                countTitle: 'Years of combined team experience and commitment to a customer-centric approach',
            },
        ];

        return(
            <Fragment>
                <div className="row" style={{justifyContent: 'center', alignItems: 'center'}}>
                    {Data.map( (value, index) => (
                        <div className="card col-lg-4 col-md-4 col-sm-12 col-12 counter-container" style={{border: 'none'}}>
                            <div className="counterup_style--1 " key={index}>
                                <h5 className="counter" style={{color: '#2C7FC2'}}>
                                    <VisibilitySensor onChange={this.onVisibilityChange} offset={{top:10}} delayedCall>
                                        <CountUp end={this.state.didViewCountUp ? value.countNum : 0} />
                                    </VisibilitySensor>
                                </h5>
                                <p className="description">{value.countTitle}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </Fragment>
        )
    }
}
export default CounterOne;