import React, { useState, useEffect, useRef } from "react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

function Collapsible({ open, children, title, backgroungColor }) {
  const [isOpen, setIsOpen] = useState(open);
  const [height, setHeight] = useState(open ? undefined : 0);

  const ref = useRef(null);

  useEffect(() => {
    if (!height || !isOpen || !ref.current) return undefined;
    
    const resizeObserver = new ResizeObserver((el) => {
      setHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [height, isOpen]);
 
  useEffect(() => {
    if (isOpen) setHeight(ref.current?.getBoundingClientRect().height);
    else setHeight(0);
  }, [isOpen]);

  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <>
      <div className="card" 
           style={{
            border: 'none', 
            // boxShadow: '10px 30px 40px rgba(158, 162, 160, 0.1)', 
            // borderRadius: 10, 
            width: '100%',
            // marginBottom: 30,
            backgroundColor: backgroungColor,
            color: 'white'
        }}>
        <div style={{display: 'flex', height: 83, flexDirection: 'row', padding: '0 30px', alignItems: 'center', justifyContent: 'space-between', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', zIndex: 100}}>
         
            <h6 style={{margin: 0, marginLeft: 30, fontSize: 25, fontWeight: 500, color: 'white'}}>{title}</h6>
      
          
          <button type="button" className="btn" onClick={handleFilterOpening}>
            {!isOpen ? (
              <FaChevronDown size={30} color="white" />
            ) : (
              <FaChevronUp size={30} color="white" />
            )}
          </button>
          
        </div>
      </div>
      <div style={{ height, overflow: 'hidden', transition: 'height 0.2s ease-in-out', width: '100%', zIndex: 0 }} >
            <div ref={ref} style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 30px', backgroundColor: backgroungColor}}>
              {children}
            </div>
        </div>
    </>
  );
}

export default Collapsible;