import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import HeroTabs from "../../blocks/HeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";


function ProductsServices() {
    const [selected, setSelected] = useState(0);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image education-hero" style={{ backgroundImage: 'url(/assets/images/bg/Product-and-Services-hero-img.webp)' }}>
                <HeroTabs selectedTab={'product'}/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center pt--100">
                                <h2>PRODUCT & SERVICES</h2>
                                <p style={{fontSize: 20}}>Unlocking the Digital Future for Preschool Educators</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details bg_color--1">
                
              <div className="service-details-inner">
                  <div className="inner">
                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                        <div className="col-lg-12">
                          <div className="row align-items-center" style={{marginTop: 50}}>                                  
                              <div className="col-lg-6">
                                  <div style={{position: 'relative', marginRight: 50}}>
                                      <img className="w-100" src="/assets/images/education/Curriculum and lesson_.webp" alt="About Images"/>
                                  </div>
                              </div>
                              <div className="col-lg-6">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="section-title service-style--3 text-left mb_sm--0">
                                              <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Curriculum and lesson planning resources</h2>
                                              <p style={{marginBottom: 40}} className="description">ANTT provides pre-made, standards-aligned lesson plans, activities and assessments that are designed to be interactive and engaging for students. These resources can save educators time and help to improve student engagement and learning outcomes.</p>
                                              {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                      
                      {/* End Single Area */}

                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                        <div className="col-lg-12">
                          <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0, marginTop: -100}}>                                  
                              <div className="col-lg-6">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="section-title service-style--3 text-left mb_sm--0">
                                              <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Professional development opportunities</h2>
                                              <p style={{marginBottom: 40}} className="description">ANTT offers workshops, webinars and other training opportunities to help educators stay up-to-date on the latest teaching strategies and technology trends. These opportunities enable educators to enhance their skills and knowledge, providing better quality of education to students.</p>
                                              {/* <p style={{marginBottom: 40}} className="description">Our training program includes a wide range of resources and tools that educators can use to enhance their instruction and engage students in learning.We provide all classroom solutions for STEAM laboratory, interior, teaching resources, handbook, quizzes for students and handson training session. Furthermore, we provide training on analytics and data tracking tools that allow educators to monitor student progress and make data-driven decisions about instruction.</p> */}
                                              {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6">
                                  <div style={{position: 'relative', marginLeft: 30}}>
                                      <img className="w-100" src="/assets/images/education/Curriculum and lesson -img.webp" alt="About Images"/>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                      {/* End Single Area */}

                      {/* Start Single Area */}
                      {/* <div className="row align-items-center" style={{marginTop: 150, marginLeft: -4, marginRight: 0}}>                                  
                          
                          <div className="col-lg-7">
                              <div className="about-inner inner">
                                  <div className="row">
                                      <div className="section-title service-style--3 text-left mb_sm--0">
                                          <h2 style={{fontSize: 45, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Digital literacy training for educators is crucial in today's digital age.</h2>
                                          <p style={{marginBottom: 40}} className="description">As technology plays an increasingly important role in our daily lives, educators must have the skills and knowledge to effectively integrate technology and digital tools in the classroom. Our digital literacy training program is designed to help educators develop the skills they need to use technology and digital tools to access, analyze, and create information.</p>
                                          <p style={{marginBottom: 40}} className="description">Our training program includes a wide range of resources and tools that educators can use to enhance their instruction and engage students in learning.We provide all classroom solutions for STEAM laboratory, interior, teaching resources, handbook, quizzes for students and handson training session. Furthermore, we provide training on analytics and data tracking tools that allow educators to monitor student progress and make data-driven decisions about instruction.</p>
                                          <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-5">
                              <div className="thumbnail" style={{position: 'relative'}}>
                                  <img className="w-100" src="/assets/images/education/Digital literacy training_.webp" alt="About Images"/>
                              </div>
                          </div>
                      </div> */}
                      {/* End Single Area */}

                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                        <div className="col-lg-12">
                          <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: -50}}>
                              {/* <div className="col-lg-6"><h2 style={{textAlign: 'center', marginBottom: 80}} className="title">Educational software and apps</h2></div> */}
                              <h2 style={{textAlign: 'center', fontSize: 45}} className="title">Educational</h2>
                              <h2 style={{textAlign: 'center', fontSize: 45, marginBottom: 80}} className="title">software and apps</h2>
                              <img style={{marginBottom: 70}} src="/assets/images/education/Educational  software and apps-img.webp"/>
                              <p className="description">Unlock the power of learning with ANTT! Our educational software and apps provide a powerful way to engage students with interactive simulations, games, and other tools. With ANTT, you can make learning more engaging, meaningful, and fun for your students. Take the guesswork out of instruction and give your students the edge they need to succeed.</p>
                              {/* <p className="description">On the other hand, digital literacy is the ability to use technology and digital tools to access, analyze, and create information. It's an essential skill that students need in today's digital age. As an educator, incorporating both STEAM and digital literacy into your teaching can create engaging and interactive lesson plans that keep students interested and motivated. By using technology in the classroom, educators can also differentiate instruction and meet the needs of diverse learners.</p> */}
                          </div>
                        </div>
                      </div>
                      {/* End Single Area */}

                      {/* Start Finding Us Area  */}
                      <div style={{backgroundColor: '#FFF7ED', paddingTop: 120, marginTop: -80}}>
                        <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                          <div className="col-lg-12">
                            <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0}}>                                  
                                
                                <div className="col-lg-9">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="section-title service-style--3 text-left mb_sm--0">
                                                <h2 style={{fontSize: 45, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Download Our App</h2>
                                                <p style={{marginBottom: 70}} className="description">Put the power of coding, robotics, IoT & data science in your students' hands with EduBlock! the perfect mobile application for teachers to incorporate coding, robotics, IoT, and data science into their lessons. This innovative mobile application will revolutionize the way you teach STEM courses and bring your lessons to life. With our easy-to-use platform, teachers can now easily teach coding, robotics, IoT, and data science to their students in a fun and interactive way. Take your classes to a whole new level with EduBlock: the world's leading robotics & IoT educational solution!</p>
                                                {/* <p style={{marginBottom: 40}} className="description">Our training program includes a wide range of resources and tools that educators can use to enhance their instruction and engage students in learning.We provide all classroom solutions for STEAM laboratory, interior, teaching resources, handbook, quizzes for students and handson training session. Furthermore, we provide training on analytics and data tracking tools that allow educators to monitor student progress and make data-driven decisions about instruction.</p> */}
                                                <div style={{display: 'flex'}}>
                                                  <a style={{backgroundColor: 'black', borderColor: 'black', marginRight: 30}} className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#"><img height={50} src="/assets/images/icons/Apple store-button.webp"/></a>
                                                  <a style={{backgroundColor: 'black', borderColor: 'black'}} className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#"><img height={50} src="/assets/images/icons/Google play-button.webp"/></a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div style={{position: 'relative'}}>
                                        <img className="w-100" src="/assets/images/education/Edublock app-img.webp" alt="About Images"/>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Finding Us Area  */}
                      {/* Start Single Area */}
                      <div style={{backgroundColor: '#FFF7ED', paddingBottom: 80}}>
                        <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                          <div className="col-lg-12">
                            <div className="row align-items-center">                                  
                                <div className="col-lg-4">
                                    <div style={{position: 'relative', marginRight: 50}}>
                                        <img className="w-100" src="/assets/images/education/Edublock.webp" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="section-title service-style--3 text-left mb_sm--0">
                                                {/* <h2 style={{fontSize: 50, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Curriculum and lesson planning resources</h2> */}
                                                <p style={{marginBottom: 40}} className="description">Edublocks offers a solution for teachers to integrate robotics and programming skills into their curriculum.Our robotics STEAM toy allows teachers to explore and create 100s of projects like Soccer Robot, Line follower, Distance Measurement Robot and more.Edublocks offers teachers the ability to learn Graphical programming, C programming, and Python through our interactive course curriculum and mobileapp.Our solution is plug and play, with no wire connections needed and offers hitech solutions using modern technology.Edublocks provides a hands-on approach to learning STEM subjects, preparing teachers for the future coding teaching sessions.</p>
                                                {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Single Area */}
                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center', marginTop: 100}} className="container">
                        <div className="col-lg-12">
                          <div className="row align-items-center">                                  
                              <div className="col-lg-6">
                                  <div style={{position: 'relative', marginRight: 50}}>
                                      <img className="w-100" src="/assets/images/education/Data and analytics tools_.webp" alt="About Images"/>
                                  </div>
                              </div>
                              <div className="col-lg-6">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="section-title service-style--3 text-left mb_sm--0">
                                              <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Data and analytics tools </h2>
                                              <p style={{marginBottom: 40}} className="description">ANTT provides tools that help educators to track student progress, assess student performance, and make data-driven decisions about instruction. These tools enable educators to monitor student performance and adapt teaching strategies to meet the needs of individual students.</p>
                                              {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                      {/* End Single Area */}
                      {/* Start Finding Us Area  */}
                      <div style={{backgroundColor: '#FFF7ED', paddingTop: 120, paddingBottom: 150}}>
                        <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                          <div className="col-lg-12">
                            <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0}}>                                  
                                
                                <div className="col-lg-9">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="section-title service-style--3 text-left mb_sm--0">
                                                <h2 style={{fontSize: 45, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Virtual Reality and Augmented Reality</h2>
                                                <p style={{marginBottom: 70}} className="description">Virtual Reality and Augmented Reality: ANTT provides immersive learning experiences using VR and AR technology that allows students to explore and interact with concepts in a more engaging way. These tools can be used to supplement instruction and provide new ways for students to learn.</p>
                                                {/* <p style={{marginBottom: 40}} className="description">Our training program includes a wide range of resources and tools that educators can use to enhance their instruction and engage students in learning.We provide all classroom solutions for STEAM laboratory, interior, teaching resources, handbook, quizzes for students and handson training session. Furthermore, we provide training on analytics and data tracking tools that allow educators to monitor student progress and make data-driven decisions about instruction.</p> */}
                                                <div style={{display: 'flex'}}>
                                                  <a style={{backgroundColor: 'black', borderColor: 'black', marginRight: 30}} className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#"><img height={50} src="/assets/images/icons/Apple store-button.webp"/></a>
                                                  <a style={{backgroundColor: 'black', borderColor: 'black'}} className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#"><img height={50} src="/assets/images/icons/Google play-button.webp"/></a>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div style={{position: 'relative'}}>
                                        <img className="w-100" src="/assets/images/education/AR Reaction-APP.webp" alt="About Images"/>
                                    </div>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* End Finding Us Area  */}

              

                  </div>
              </div>
                        
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-230}  />

        </React.Fragment>
    )
}
export default ProductsServices;