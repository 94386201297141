import React from 'react'
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp , FiCheck } from "react-icons/fi";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";
import ContactForm from "../elements/contact/ContactForm";

const PricingTable = () => {
    return (
        <>
            <PageHelmet pageTitle='Pricing Table' />

            {/* Start Header Area  */}
            <HeaderFixed />
            {/* End Header Area  */}
            
            {/* Start Breadcrump Area */}
            <div className="breadcrumb-area rn-bg-color bg_image" style={{backgroundImage: 'url(/assets/images/bg/service-pack-banner.jpeg)', paddingBottom: 152, paddingTop: 96}} >
                    <div style={{transform: 'scale(0.8)'}} className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="breadcrumb-inner pt--100">
                                    <h2 className="title">Service Package</h2>
                                    {/* <ul className="page-list">
                                        <li className="breadcrumb-item"><Link to={`${process.env.PUBLIC_URL}`}>Home</Link></li>
                                        {parent? <li className="breadcrumb-item">{parent}</li>:''}
                                        <li className="breadcrumb-item active">{title}</li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper">
                {/* Start Pricing Tbale Area  */}
                <div className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container service-sm-pack">
                        <div className="row">
                        <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <div className="row sercice-details-content pb--100 align-items-center">
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/service-pack-01.jpeg" alt="Service Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <p>Suitable For Small To Midsize Businesses</p>
                                                <h4 className="title">Partnership opportunities for edtech enterprises or academic institutes</h4>
                                                <ul className="liststyle">
                                                    <li>Exclusivity and Partnerships</li> 
                                                    <li>Regional Partnerships</li>
                                                    <li>Localized manufacturing partnerships for OEM and ODM </li>
                                                    <li>STEM Institutes and educational organizations</li>
                                                    <li>Workforce and future career readiness organizations and agencies</li>
                                                    <li>Research partnerships</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}
                                </div>
                            </div>
                                                 
                            

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-3 col-md-6 col-12">                           
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Starter Pack</h4>
                                            <div className="pricing">
                                                <span className="price">20</span>
                                                <span className="subtitle">Devices</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Minimum Order Quantity</li>
                                                <li><FiCheck />Re-branding Available </li>
                                                <li><FiCheck />Basic Book, Manual, Class Lecture</li>
                                                <li><FiCheck />12~16 Class Teaching Resources</li>
                                                <li><FiCheck />Quizzes for Each Session </li>
                                                <li><FiCheck />100MB Sign-up Bonus/Device to</li>
                                                <li><FiCheck />Do IoT Experiment</li>
                                                <li><FiCheck />02 Session for Teach the Trainer</li>
                                                <li><FiCheck />Teach the Trainer Support Limited</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="/contact">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-3 col-md-6 col-12">                           
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">SME Pack</h4>
                                            <div className="pricing">
                                                <span className="price">50</span>
                                                <span className="subtitle">Devices</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Minimum Order Quantity</li>
                                                <li><FiCheck />Re-branding Available</li> 
                                                <li><FiCheck />Basic Book, Manual, Class Lecture</li>
                                                <li><FiCheck />12~24 Class Teaching Resources</li>
                                                <li><FiCheck />Quizzes for Each Session</li> 
                                                <li><FiCheck />200MB Sign-up Bonus/Device to</li>
                                                <li><FiCheck />Do IoT Experiment</li>
                                                <li><FiCheck />04 Session for Teach the Trainer</li>
                                                <li><FiCheck />02 extra session - Teach the Trainer Support</li>
                                                <li><FiCheck />Lifetime Service Support</li>
                                              
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="/contact">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-3 col-md-6 col-12">                           
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">EdTech Pack</h4>
                                            <div className="pricing">
                                                <span className="price">70 </span>
                                                <span className="subtitle">Devices</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Minimum Order Quantity</li>
                                                <li><FiCheck />Re-branding Available</li> 
                                                <li><FiCheck />Basic Book, Manual, Class Lecture</li>
                                                <li><FiCheck />Step by Step Computer Science</li>
                                                <li><FiCheck />Learning Teaching Resources</li>
                                                <li><FiCheck />Quizzes for Each Session</li> 
                                                <li><FiCheck />3 month subscription free / Device to</li>
                                                <li><FiCheck />Do IoT Experiment</li>
                                                <li><FiCheck />06 Session for Teach the Trainer</li>
                                                <li><FiCheck />Prior Request based Teach the Trainer Support</li>
                                                <li><FiCheck />Lifetime Service Support</li>

                                             
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="/contact">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-3 col-md-6 col-12">                           
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Enterprise Pack</h4>
                                            <div className="pricing">
                                                <span className="price">100</span>
                                                <span className="subtitle">Devices</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck />Minimum Order Quantity</li>
                                                <li><FiCheck />Re-branding Available</li> 
                                                <li><FiCheck />Customized Book, Manual, Class Lecture</li>
                                               <li><FiCheck /> Customize able Teaching Resources</li>
                                                <li><FiCheck />Quizzes for Each Session</li> 
                                               <li><FiCheck /> 6 month subscription free / Device to</li>
                                                <li><FiCheck />Do IoT Experiment</li>
                                                <li><FiCheck />Teaching Resources wise Session</li>
                                                <li><FiCheck />for Teach the Trainer</li>
                                               <li><FiCheck /> On-demand basis Teach the Trainer Support </li> 
                                               <li><FiCheck /> Live Customer Support</li>
                                                <li><FiCheck />Lifetime Service Support</li>


                                             
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="/contact">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <div className="row sercice-details-content pb--100 align-items-center" style={{paddingTop: 80}}>
                                        <div className="col-lg-6 col-12">
                                            <div className="thumb">
                                                <img className="w-100" src="/assets/images/service/service-pack-02.jpeg" alt="Service Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-12">
                                            <div className="details mt_md--30 mt_sm--30">
                                                <p>Solutions for Enterprises, Factories, Industries, and MNCs</p>
                                                <h4 className="title">Industrial IoT and AIIoT Solutions</h4>
                                                <ul className="liststyle">
                                                    <li>No-coding hardware solutions</li>
                                                    <li>Industrial IoT and IoT automation services</li>
                                                    <li>for your home, factory, office, and more.</li>
                                                    <li>OEM and ODM manufacturing partnerships for IoT devices</li>
                                                    <li>User guides and supplementary documentation</li>
                                                    <li>Data analysis, visualization, and control Systems</li>
                                                    <li>Digital wallets and data management</li>
                                                    <li>AI powered sensor data analytics</li>
                                                    <li>Automated reporting systems and tools</li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="section-title text-left mb--30">
                                    <h2>IoT Dashboard Software Package</h2>                                 
                                </div>
                            </div>                         
                            

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-3 col-md-6 col-12">                           
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Starter Pack</h4>
                                            <div className="pricing">
                                                <span className="price">01 </span>
                                                <span className="subtitle">IoT</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Device Integration to Dashboard</li>
                                                <li><FiCheck /> 120 MB Data Up to</li>
                                                <li><FiCheck />10,000 Dot</li>
                                                <li><FiCheck />10 Team Member</li>
                                                <li><FiCheck />0.00 Additional Setup Charge</li>
                                                <li><FiCheck />SMS - As Pay As You Go</li>
                                                <li><FiCheck />Email - AS Pay As You Go</li>
                                                <li><FiCheck />Lifetime Service Support</li>                                              
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="/contact">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-3 col-md-6 col-12">                           
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">SME Pack</h4>
                                            <div className="pricing">
                                                <span className="price">10 </span>
                                                <span className="subtitle">IoT</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Device Integration to Dashboard</li>
                                                <li><FiCheck />500 MB Data Up to</li>
                                                <li><FiCheck />1 Million Dot</li>
                                                <li><FiCheck />30 Team Member</li>
                                                <li><FiCheck />0.00 Additional Setup Charge</li>
                                                <li><FiCheck />SMS - As Pay As You Go</li>
                                                <li><FiCheck />Email - AS Pay As You Go</li>
                                                <li><FiCheck />Lifetime Service Support</li>                                              
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="/contact">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-3 col-md-6 col-12">                           
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Mid-Industry Pack</h4>
                                            <div className="pricing">
                                                <span className="price">50 </span>
                                                <span className="subtitle">IoT</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Device Integration to Dashboard</li>
                                                <li><FiCheck />01 GB Data Up to</li>
                                                <li><FiCheck />10 Million Dot</li>
                                                <li><FiCheck />Unlimited Team Member</li>
                                                <li><FiCheck />USD 10/Device</li>
                                                <li><FiCheck />Additional Setup Charge</li>
                                                <li><FiCheck />SMS - 500</li>
                                                <li><FiCheck />Email - 500</li>
                                                <li><FiCheck />Lifetime Service Support</li>
                                                <li><FiCheck />On-demand Basis Customer Support</li>
                                             
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="/contact">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-3 col-md-6 col-12">                           
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Enterprise Pack</h4>
                                            <div className="pricing">
                                                <span className="price">100+ </span>
                                                <span className="subtitle">IoT</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Device Integration to Dashboard</li>
                                                <li><FiCheck />As per Required</li>
                                                <li><FiCheck />Unlimited Dot</li>
                                                <li><FiCheck />Unlimited Team Member</li>
                                                <li><FiCheck />USD 10/Device</li>
                                                <li><FiCheck />Additional Setup Charge</li>
                                                <li><FiCheck />SMS - As Per Required</li>
                                                <li><FiCheck />Email - As Per Required</li>
                                                <li><FiCheck />Custom Automation Solution </li>
                                                <li><FiCheck />Lifetime Service Support</li>
                                                <li><FiCheck />On-demand Basis Customer Support</li>

                                             
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="/contact">Contact Us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}
                            {/* Start Contact Us */}
                            <div className="rn-contact-us ptb--80 bg_color--5" id="contact">
                                <div className="contact-form--1">
                                    <div className="container">
                                        <div className="row row--35" style={{alignItems: 'center'}}>
                                            <div className="col-lg-6 order-2 order-lg-1">
                                                <div className="section-title text-left mb--50">
                                                    <h2 className="title">Contact Us</h2>
                                                    <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                                </div>
                                                <div className="form-wrapper">
                                                    <ContactForm />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 order-1 order-lg-2">
                                                <div className="thumbnail mb_md--30 mb_sm--30">
                                                    <img src="/assets/images/about/contact-us.png" alt="trydo"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Contact Us */}
                            
                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}
            </main>
            {/* End Page Wrapper  */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer />
            {/* End Footer Area  */}
        </>
        
    )
}
export default PricingTable;