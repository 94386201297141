// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import PageScrollTop from './component/PageScrollTop';

// Home layout
import Demo from './page-demo/Demo';
import MainDemo from './home/MainDemo';
import Startup from './home/Startup';
import Paralax from './home/Paralax';
import HomePortfolio from './home/HomePortfolio';
import DigitalAgency from './home/DigitalAgency';
import CreativeAgency from './home/CreativeAgency';
import PersonalPortfolio from './home/PersonalPortfolio';
import Business from './home/Business';
import StudioAgency from './home/StudioAgency';
import PortfolioLanding from './home/PortfolioLanding';
import CreativeLanding from './home/CreativeLanding';
import HomeParticles from './home/HomeParticles';
import CreativePortfolio from './home/CreativePortfolio';
import DesignerPortfolio from './home/DesignerPortfolio';
import InteriorLanding from './home/Interior';
import CorporateBusiness from './home/CorporateBusiness';
import InteractiveAgency from './home/InteractiveAgency';

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';
import DarkPortfolioLanding from './dark/PortfolioLanding';

// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import ServiceDetailsSteam from "./elements/ServiceDetailsSteam";
import ServiceRoboticGadgets from "./elements/ServiceRoboticGadgets";
import ServiceIotAiot from "./elements/ServiceIotAiot";
import ServicePcb from "./elements/ServicePcb";
import ServiceCustomisedSolutions from "./elements/ServiceCustomisedSolutions";
import ServiceRobot from "./elements/ServiceRobot";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./elements/BlogDetails";
import InvestorPortal from "./elements/InvestorPortal";


// Blocks Layout
import Counters from "./blocks/Counters";
import Testimonial from "./blocks/Testimonial";
import Portfolio from "./blocks/Portfolio";
import VideoPopup from "./blocks/VideoPopup";
import Gallery from "./blocks/Gallery";
import Brand from "./blocks/Brand";
import ProgressBar from "./blocks/ProgressBar";
import ContactForm from "./blocks/ContactForm";
import GoogleMap from "./blocks/GoogleMap";
import Columns from "./blocks/Columns";
import PricingTable from "./blocks/PricingTable";
import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

import Login from './elements/Login';
import PrivacyPolicy from './elements/PrivacyPolicy';
import EdublockPrivacyPolicy from './elements/edublock/privacy-policy';
import TermsConditions from './elements/TermsConditions';
import RefundPolicy from './elements/RefundPolicy';

import SteamIct from './elements/preschool/SteamIct';
import Curriculum from './elements/preschool/Curriculum';
import ProductsServices from './elements/preschool/ProductsServices';
import Resources from './elements/preschool/Resources';
import CodingCertificate from './elements/CodingCertificate';

import ElementaryProductsServices from './elements/elementaryschool/ProductsServices';
import ElementarySteamIct from './elements/elementaryschool/SteamIct';
import ElementaryResources from './elements/elementaryschool/Resources';
import ElementaryCurriculum from './elements/elementaryschool/Curriculum';

import MiddleSchoolSteamIct from './elements/middleschool/SteamIct';
import MiddleSchoolResources from './elements/middleschool/Resources';
import MiddleSchoolCurriculum from './elements/middleschool/Curriculum';
import MiddleSchoolProductsServices from './elements/middleschool/ProductsServices';

import HighSchoolSteamIct from './elements/highschool/SteamIct';
import HighSchoolResources from './elements/highschool/Resources';
import HighSchoolProductsServices from './elements/highschool/ProductsServices';
import HighSchoolCurriculum from './elements/highschool/Curriculum';

import AcademicProductsServices from './elements/academic/ProductsServices';
import AcademicSteam from './elements/academic/Steam';
import AcademicDemo from './elements/academic/Demo';
import AcademicCurriculum from './elements/academic/Curriculum';

import SteamVocational from './elements/vocational/SteamVocational';
import DemoVocational from './elements/vocational/Demo';
import CurriculumVocational from './elements/vocational/Curriculum';
import ProductsServicesVocational from './elements/vocational/ProductsServices';

import DemoHigherEducation from './elements/highereducation/Demo';
import SteamHigherEducation from './elements/highereducation/Stem';
import ProductsServicesHigherEducation from './elements/highereducation/ProductsServices';
import CaseStudyHigherEducation from './elements/highereducation/CaseStudy';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <PageScrollTop>
                    <Switch>
                        <Route exact path={`${process.env.PUBLIC_URL}/`} component={HomeParticles}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/main-demo`} component={MainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/dark-main-demo`} component={DarkMainDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/startup`} component={Startup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/paralax`} component={Paralax}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/digital-agency`} component={DigitalAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-agency`} component={CreativeAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/personal-portfolio`} component={PersonalPortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/studio-agency`} component={StudioAgency}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/business`} component={Business}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-home`} component={HomePortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-landing`} component={PortfolioLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-landing`} component={CreativeLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/home-particles`} component={HomeParticles}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/dark-portfolio-landing`} component={DarkPortfolioLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/designer-portfolio`} component={DesignerPortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/creative-portfolio`} component={CreativePortfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/interior-landing`} component={InteriorLanding}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/corporate-business`} component={CorporateBusiness}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/interactive-agency`} component={InteractiveAgency}/>

                        {/* Element Layot */}
                        <Route exact path={`${process.env.PUBLIC_URL}/service`} component={Service}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={ServiceDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details/steam`} component={ServiceDetailsSteam}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details/robotic-gadgets`} component={ServiceRoboticGadgets}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details/iot-aiot`} component={ServiceIotAiot}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details/pcb`} component={ServicePcb}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details/service-robot`} component={ServiceRobot}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-details/customised-solutions`} component={ServiceCustomisedSolutions}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/about`} component={About}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/product`} component={Blog}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/blog-details`} component={BlogDetails}/>

                        {/* Blocks Elements  */}
                        <Route exact path={`${process.env.PUBLIC_URL}/counters`} component={Counters}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/testimonial`} component={Testimonial}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/portfolio`} component={Portfolio}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/video-popup`} component={VideoPopup}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/gallery`} component={Gallery}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/clint-logo`} component={Brand}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/progressbar`} component={ProgressBar}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/contact-form`} component={ContactForm}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/google-map`} component={GoogleMap}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/columns`} component={Columns}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/pricing-table`} component={PricingTable}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/service-pack`} component={PricingTable}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/investor-portal`} component={InvestorPortal}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/edublock/privacy-policy`} component={EdublockPrivacyPolicy}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/terms-and-conditions`} component={TermsConditions}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/refund-policy`} component={RefundPolicy}/>


                        
                        {/* <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                        <Route component={error404}/> */}
                        <Route exact path={`${process.env.PUBLIC_URL}/preschool/steam-ict`} component={SteamIct}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/preschool/curriculum`} component={Curriculum}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/preschool/product-service`} component={ProductsServices}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/preschool/resources`} component={Resources}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/elementary/product-service`} component={ElementaryProductsServices}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/elementary/steam-ict`} component={ElementarySteamIct}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/elementary/resources`} component={ElementaryResources}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/elementary/curriculum`} component={ElementaryCurriculum}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/middleschool/steam-ict`} component={MiddleSchoolSteamIct}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/middleschool/resources`} component={MiddleSchoolResources}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/middleschool/curriculum`} component={MiddleSchoolCurriculum}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/middleschool/product-service`} component={MiddleSchoolProductsServices}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/highschool/steam-ict`} component={HighSchoolSteamIct}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/highschool/resources`} component={HighSchoolResources}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/highschool/product-service`} component={HighSchoolProductsServices}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/highschool/curriculum`} component={HighSchoolCurriculum}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/coding-certificate`} component={CodingCertificate}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/academic/product-service`} component={AcademicProductsServices}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/academic/steam`} component={AcademicSteam}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/academic/demo`} component={AcademicDemo}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/academic/curriculum`} component={AcademicCurriculum}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/vocational/steam`} component={SteamVocational}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/vocational/demo`} component={DemoVocational}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/vocational/curriculum`} component={CurriculumVocational}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/vocational/product-service`} component={ProductsServicesVocational}/>

                        <Route exact path={`${process.env.PUBLIC_URL}/higher-education/demo`} component={DemoHigherEducation}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/higher-education/steam`} component={SteamHigherEducation}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/higher-education/product-service`} component={ProductsServicesHigherEducation}/>
                        <Route exact path={`${process.env.PUBLIC_URL}/higher-education/case-study`} component={CaseStudyHigherEducation}/>
                    </Switch>
                </PageScrollTop>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();