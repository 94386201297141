import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";
import {Link} from 'react-router-dom';
import HeroTabs from "../../blocks/HeroTabs";


function SteamIct() {
    const [selected, setSelected] = useState(0);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image education-hero" style={{ backgroundImage: 'url(/assets/images/bg/steam-ict-hero.webp)'}}>
                <HeroTabs selectedTab={'stem'}/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center pt--100">
                                <h2>Steam & ICT</h2>
                                <p style={{fontSize: 20}}>Learn STEAM & Teach Digital Literacy Skills</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1">
                <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container" id="preschool-stict">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <h2 style={{alignSelf: 'flex-start', marginBottom: 80}} className="title">What is STEAM & ICT?</h2>
                                        <img style={{marginBottom: 70}} src="/assets/images/education/What is Steam _ ICT.webp"/>
                                        <p className="description">STEAM education is the integration of Science, Technology, Engineering, Arts, and Mathematics in the classroom. It is an approach to learning that emphasizes the connections between these subjects and how they can be applied to real-world problems. This type of education is important as it prepares students for the future, which is becoming increasingly technology-driven. STEAM education is a unique approach to learning that emphasizes the connections between these subjects and how they can be applied to real-world problems. It helps students to understand the world around them and prepare them for the future, which is becoming increasingly technology-driven.</p>
                                        <p className="description">On the other hand, digital literacy is the ability to use technology and digital tools to access, analyze, and create information. It's an essential skill that students need in today's digital age. As an educator, incorporating both STEAM and digital literacy into your teaching can create engaging and interactive lesson plans that keep students interested and motivated. By using technology in the classroom, educators can also differentiate instruction and meet the needs of diverse learners.</p>
                                    </div>
                                    
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginTop: 150}}>                                  
                                        <div className="col-lg-5">
                                            <div style={{position: 'relative'}}>
                                                <img className="w-100" src="/assets/images/education/Educators be familia.webp" alt="About Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="about-inner inner">
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0">
                                                        <h2 style={{fontSize: 45, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Why Should Educators be familiar With STEAM?</h2>
                                                        <p style={{marginBottom: 40}} className="description">Incorporating STEAM skills into the classroom can also help students to develop critical thinking and problem-solving skills, which are essential for success in any field. Additionally, the integration of STEAM and digital literacy can expose students to various career possibilities in the STEAM fields, such as healthcare, manufacturing, computer programming, and engineering. In summary, the integration of STEAM and digital literacy in education is essential for students and educators in today's world. It helps students to develop the skills and knowledge they need to succeed in the future and prepares them for the digital world.</p>
                                                        {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginTop: 150, marginLeft: -4, marginRight: 0}}>                                  
                                        
                                        <div className="col-lg-7">
                                            <div className="about-inner inner">
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0">
                                                        <h2 style={{fontSize: 45, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Digital literacy training for educators is crucial in today's digital age.</h2>
                                                        <p style={{marginBottom: 40}} className="description">As technology plays an increasingly important role in our daily lives, educators must have the skills and knowledge to effectively integrate technology and digital tools in the classroom. Our digital literacy training program is designed to help educators develop the skills they need to use technology and digital tools to access, analyze, and create information.</p>
                                                        <p style={{marginBottom: 40}} className="description">Our training program includes a wide range of resources and tools that educators can use to enhance their instruction and engage students in learning.We provide all classroom solutions for STEAM laboratory, interior, teaching resources, handbook, quizzes for students and handson training session. Furthermore, we provide training on analytics and data tracking tools that allow educators to monitor student progress and make data-driven decisions about instruction.</p>
                                                        {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div style={{position: 'relative'}}>
                                                <img className="w-100" src="/assets/images/education/Digital literacy training_.webp" alt="About Images"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 120}}>
                                        <h2 style={{alignSelf: 'flex-start', marginBottom: 80}} className="title">The ANTT Robotics educators training program focuses on staying</h2>
                                        <img style={{marginBottom: 70}} src="/assets/images/education/educators training program.webp"/>
                                        <p className="description">up-to-date with the latest technology trends and ensuring that students are prepared for the digital world. This includes training on new and emerging technologies such as AI, VR, and AR which can be used to supplement instruction and provide new ways for students to learn. We provide training in various forms such as online courses, webinars, and virtual coaching sessions which can be tailored to the specific needs of educators. Our digital literacy training program is designed to empower educators with the skills and knowledge they need to effectively integrate technology and digital tools in the classroom. It provides them with the tools and resources they need to enhance instruction and engage students in learning, and stay up-to-date with the latest technology trends. Investing in our program is an investment in the future of education and the success of our students.</p>
                                        {/* <p className="description">On the other hand, digital literacy is the ability to use technology and digital tools to access, analyze, and create information. It's an essential skill that students need in today's digital age. As an educator, incorporating both STEAM and digital literacy into your teaching can create engaging and interactive lesson plans that keep students interested and motivated. By using technology in the classroom, educators can also differentiate instruction and meet the needs of diverse learners.</p> */}
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Finding Us Area  */}
                                    <div className="row align-items-center" style={{marginTop: 150}}>                                  
                                        <div className="col-lg-5">
                                            <div style={{position: 'relative'}}>
                                                <img className="w-100" src="/assets/images/education/Improve Educational_.webp" alt="About Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="about-inner inner">
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0">
                                                        <h2 style={{fontSize: 45, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Improve Educational Outcomes with ANTT </h2>
                                                        <p style={{marginBottom: 40}} className="description">ANTT offers a comprehensive computer science, IoT, Robotics, or STEAM curriculum and products for schools. Our platform offers the world's most advanced coding learning experience for both adults and children with a focus on STEAM education. Our curriculum teaches problem-solving, teamwork, creativity, and leadership skills. Our platform is available on mobile app and web portal, allowing students to learn anytime, anywhere with our ready-to-use coding software. Our platform also offers a range of resources and tools for teachers, including interactive and engaging </p>
                                                        <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Finding Us Area  */}

                            

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-680}  />

        </React.Fragment>
    )
}
export default SteamIct;