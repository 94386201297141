import React ,{ Component }from "react";
// import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";

const ServiceList = [
    {
        icon: '1',
        title: "STEAM <span style='font-weight: 400'>Education</span>",
        description: 'We provide edtech solutions, STEM tools, software applications and curriculum Support for businesses.',
        image: '1',
        slug: 'steam'
    },
    {
        icon: '2',
        title: "IoT <span style='font-weight: 400'>Solutions</span>",
        description: 'Offering Industrial loT products and SaaS platforms that focus on no-coding solutions to automate your industry.',
        image: '2',
        slug: 'iot-aiot'
    },
    {
        icon: '3',
        title: "Robotic <span style='font-weight: 400'>Gadgets</span>",
        description: 'Find a wide range of cutting-edge products that complement your high-tech data driven customized lifestyle!',
        image: '3',
        slug: 'robotic-gadgets'
    },
    { 
        icon: '4',
        title: "PCB & <span style='font-weight: 400'>PCBA</span>",
        description: 'Experience the latest hardware technology and develop your PCB, PCBA, ODM, OEM and hardware testing facility.',
        image: '4',
        slug: 'pcb'
    },
    {
        icon: '5',
        title: "Service <span style='font-weight: 400'>Robot</span>",
        description: 'Consumer robotics products for organizations to optimize workplace efficiency using the Artificial Intelligence Co-Bot.',
        image: 'Service Robot Img',
        slug: 'service-robot'
    },
    { 
        icon: '6',
        title: "Customized <span style='font-weight: 400'>Solutions</span>",
        image: 'Customized Solutions Img',
        description: 'Customizable machine to machine communication and software with DevOps solutions for industrial automation.',
        slug: 'customised-solutions'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i} style={{paddingBottom: 30}}>
                            <div class="card service-card" style={{border: 'none', boxShadow: '2px 3px 20px rgba(0, 0, 0, 0.14)', borderRadius: '0.65rem', height: 530 }}>
                                <div style={{
                                    borderTopLeftRadius: '0.65rem', 
                                    borderTopRightRadius: '0.65rem', 
                                    backgroundColor: '#2A9DF4', 
                                    height: 220,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <img id="service-image" className="card-img-top" src={`/assets/images/home/Our Services/${val.icon}.webp`} alt="Card image cap"/>
                                </div>
                                
                                <div className="card-body" style={{display: 'flex', flexDirection: 'column', padding: 20, justifyContent: 'space-between'}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <h5 className="card-title" style={{fontWeight: 700, marginBottom: 30}} dangerouslySetInnerHTML={{__html: val.title}}></h5>
                                        <p style={{fontSize: 16, marginBottom: 30}} className="card-text">{val.description}</p>
                                    </div>
                                    
                                    <a href={`/service-details/${val.slug}`} className="homepage-services-btn">Read more...</a>
                                </div>
                            </div>
                        </div>
                        
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
