import React from 'react';
import {Link} from 'react-router-dom';

function ElementaryHeroTabs({ selectedTab }) {
  return (
    <div className="container school" id="hero-tabs-container">
      <div className={selectedTab === 'stem' ? 'hero-tab-first hero-tab-selected' : 'hero-tab-first'}>
        <Link to='/elementary/steam-ict'/>
        <p>STEM & ICT</p>
      </div>
      <div className={selectedTab === 'product' ? 'hero-tab hero-tab-selected' : 'hero-tab'}>
        <Link to='/elementary/product-service'/>
        <p>Product & Services</p>
      </div>
      <div className={selectedTab === 'curriculum' ? 'hero-tab hero-tab-selected' : 'hero-tab'}>
        <Link to='/elementary/curriculum'/>
        <p>Curriculum</p>
      </div>
      <div className={selectedTab === 'resources' ? 'hero-tab-last hero-tab-selected' : 'hero-tab-last'}>
        <Link to='/elementary/resources'/>
        <p>Resources</p>
      </div>
      {/* <div className='hero-tab-last'>
        <Link to='#'/>
        <p>Standards</p>
      </div> */}
  </div>
  )
}

export default  ElementaryHeroTabs;
