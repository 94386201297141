import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import GoogleMapReact from 'google-map-react';
import ContactForm from "./contact/ContactForm";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };
    componentDidMount() {
        // Add the Typeform script dynamically after the component mounts
        const script = document.createElement("script");
        script.src = "//embed.typeform.com/next/embed.js";
        script.async = true;
        document.body.appendChild(script);
    }

    render() {
        return (
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <HeaderFixed />

                {/* Start Breadcrump Area */}
                <div className="bg_image vocational_bg"
                    style={{
                        backgroundColor: '#5255FF',
                        display: 'flex',
                        backgroundPosition: 'top',
                        alignItems: 'center',
                        position: 'relative',
                    }}>
                    <div style={{ transform: 'scale(0.8)' }} className="container">
                        <div className="row" style={{ alignItems: 'center' }}>

                            <div className="col-lg-6 about_hero_content">
                                <h2>Contact Us</h2>
                            </div>

                            <div className="col-lg-6 about_hero_image" style={{ alignSelf: 'flex-end' }}>
                                <img src="/assets/images/contact/hero.webp" />
                            </div>


                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--80">
                    <div style={{ transform: 'scale(0.8)', transformOrigin: 'top center' }} className="container contact-body">

                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6  col-12" style={{ margin: '15px 0' }}>
                                <div className="contact-card">
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 50 }}>
                                        <img src="/assets/images/contact/email box.webp" />
                                        <div>
                                            <p style={{ marginBottom: 10, fontSize: 16 }}>Mail us now</p>
                                            <h4 style={{ fontSize: 18, marginBottom: 0 }}>Email Address</h4>
                                        </div>
                                    </div>

                                    <p style={{ fontSize: 18 }}><a style={{ color: '#58595B' }} href="mailto:support@anttrobotics.com">support@anttrobotics.com</a></p>
                                    <p style={{ fontSize: 18 }}><a style={{ color: '#58595B' }} href="mailto:uday@anttrobotics.com">uday@anttrobotics.com</a></p>
                                    <p style={{ fontSize: 18 }}><a style={{ color: '#58595B' }} href="mailto:jayme.ibd@anttrobotics.com">jayme.ibd@anttrobotics.com</a></p>
                                    <p style={{ fontSize: 18 }}><a style={{ color: '#58595B' }} href="mailto:anttrobotics@gmail.com">anttrobotics@gmail.com</a></p>


                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            {/* <div className="col-lg-4 col-md-6  col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email Address</h4>
                                        <p><a href="mailto:support@anttrobotics.com">support@anttrobotics.com</a></p>
                                        <p><a href="mailto:uday@anttrobotics.com">uday@anttrobotics.com</a></p>
                                        <p><a href="mailto:jayme.ibd@anttrobotics.com">jayme.ibd@anttrobotics.com</a></p>
                                        <p><a href="mailto:anttrobotics@gmail.com">anttrobotics@gmail.com</a></p>
                                    </div>
                                </div>
                            </div> */}
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6  col-12" style={{ margin: '15px 0' }}>
                                <div className="contact-card">
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 50 }}>
                                        <img src="/assets/images/contact/phone box.webp" />
                                        <div>
                                            <p style={{ marginBottom: 10, fontSize: 16 }}>Call Us Anytime</p>
                                            <h4 style={{ fontSize: 18, marginBottom: 0 }}>Phone Address</h4>
                                        </div>
                                    </div>

                                    <p style={{ fontSize: 18 }}><a style={{ color: '#58595B' }} href="tel:+880 192 577 0036">+880 192 577 0036 (BD)</a></p>
                                    <p style={{ fontSize: 18 }}><a style={{ color: '#58595B' }} href="tel:+44 7904 386644">+44 7904 386644 (USA)</a></p>
                                    <p style={{ fontSize: 18 }}><a style={{ color: '#58595B' }} href="tel:+82 10-7154-5263">+82 10-7154-5263 (SK)</a></p>


                                </div>

                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6  col-12" style={{ margin: '15px 0' }}>
                                <div className="contact-card">
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 50 }}>
                                        <img src="/assets/images/contact/phone box.webp" />
                                        <div>
                                            <p style={{ marginBottom: 10, fontSize: 16 }}>Our Location</p>
                                            <h4 style={{ fontSize: 18, marginBottom: 0 }}>Address</h4>
                                        </div>
                                    </div>

                                    <h4 style={{ fontSize: 18, marginBottom: 10 }}>Location in Bangladesh</h4>
                                    {/* <h6 style={{ fontSize: 12, marginBottom: 10 }}>ANTT Robotics LTD</h6> */}
                                    <p style={{ fontSize: 14, lineHeight: '16.94px' }}>ANTT Robotics R&D Office:L-4, Gha 75/A, Moddho Badda, Gulsha-1, Dhaka -1212, BD</p>

                                    <h4 style={{ fontSize: 18, marginBottom: 10 }}>Location in USA</h4>
                                    {/* <h6 style={{ fontSize: 12, marginBottom: 10 }}>ANTT Robotics LLC</h6> */}
                                    <p style={{ fontSize: 14, lineHeight: '16.94px' }}>30 N GOULD ST STE R SHERIDAN, WY 82801, USA</p>

                                    <h4 style={{ fontSize: 18, marginBottom: 10 }}>Location in South Korea</h4>
                                    {/* <h6 style={{ fontSize: 12, marginBottom: 10 }}>ANTT Robotics LLC</h6> */}
                                    <p style={{ fontSize: 14, lineHeight: '16.94px' }}>SEOUL CITY, MAPOGU, SEUNGMUNGIL, SOUTH KOREA, 04112</p>
                                </div>

                            </div>
                            {/* End Single Address  */}



                            <div className="rn-contact-us ptb--30" id="contact" style={{ marginTop: 120 }}>
                                <div className="contact-form--1">

                                    <div className="row row--35" style={{ alignItems: 'stretch' }}>
                                        <div className="col-12 order-2 order-lg-1">
                                            <div className="section-title text-left mb--50">
                                                <p style={{ color: '#2c7fc2', marginBottom: 2, fontSize: 30 }}>Interested?</p>
                                                <h2 style={{ marginBottom: 15, fontSize: 40, fontWeight: 400 }} className="title">Book your <span style={{ fontWeight: 600 }}>Free</span> Session</h2>
                                                <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                            </div>
                                            <div className="form-wrapper">
                                               {/* Typeform Embed */}
                                               <div data-tf-live="01J8T05PHQMQ54P9ANXE209CTP"></div>
                                           
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 order-1 order-lg-2">
                                            <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                                <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                            </div>
                                        </div> */}
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}



                {/* Start Contact Map  */}
                {/* <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '520px', width: '100%' }}>
                        <GoogleMapReact
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            lat={23.7800221}
                            lng={90.4244863}
                            text="ANTT Robotics R&D office"
                        />
                        </GoogleMapReact>
                    </div>
                </div> */}
                {/* End Contact Map  */}


                {/* Start Brand Area */}
                {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer topMargin={-50} />

            </React.Fragment>
        )
    }
}
export default Contact