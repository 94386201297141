import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ElementaryHeroTabs from "../../blocks/MiddleSchoolHeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";


function ProductsServices() {
    const [selected, setSelected] = useState(0);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image education-hero" style={{ backgroundImage: 'url(/assets/images/bg/Product-and-Services-hero-img.webp)' }}>
                <ElementaryHeroTabs selectedTab={'product'}/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center pt--100">
                                <h2>PRODUCT & SERVICES</h2>
                                <p style={{fontSize: 20}}>Unlocking the Digital Future for Preschool Educators</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1">
                
              <div className="service-details-inner">
                  <div className="inner">
                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center', marginBottom: -350}} className="container">
                        <div className="col-lg-12">
                          <div className="row align-items-center" style={{marginTop: 50}}>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="row">
                                        <div className="section-title service-style--3 text-left mb_sm--0">
                                            <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Enhancing Middle School Lessons With ANTT: Interactive and Standards Aligned Curriculum Resources</h2>
                                            <p style={{marginBottom: 40}} className="description">As a middle school teacher, you have the opportunity to enhance your lessons and make them more interactive for your students. ANTT offers a wide range of curriculum and lesson planning resources that are designed to align with the latest teaching methods and standards. These resources include pre-made lesson plans, activities, and assessments that are easy to implement and are specifically designed to engage and inspire your students. Whether you have a background in creative SEO content writing or hold a high school teaching license, ANTT can help you take your teaching to the next level.</p>
                                            {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                        </div>
                                    </div>
                                </div>
                              </div>                                  
                              <div className="col-lg-6">
                                  <div style={{position: 'relative'}}>
                                      <img className="w-100" src="/assets/images/education/Curriculum and lesson_.webp" alt="About Images"/>
                                  </div>
                              </div>
                              
                          </div>
                        </div>
                     
                      
                      {/* End Single Area */}

                      {/* Start Single Area */}
                  
                        <div className="col-lg-12">
                          <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0, marginTop: 40}}>
                            <div className="col-lg-6">
                                  <div style={{position: 'relative'}}>
                                      <img className="w-100" src="/assets/images/education/enhance-teaching.webp" alt="About Images"/>
                                  </div>
                              </div>                                  
                              <div className="col-lg-6">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="section-title service-style--3 text-left mb_sm--0">
                                              <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">"Enhance Your Teaching Skills and Give Your Students the Best Education with ANTT's Professional Development Opportunities for Middle School Teachers"</h2>
                                              <p style={{marginBottom: 40}} className="description">Enhance Your Teaching Skills and Knowledge with ANTT's Professional Development Program for Middle School Teachers. From workshops and webinars to other training opportunities, our program helps educators stay current on the latest teaching strategies and technology trends to give your students the best quality of education.</p>
                                              {/* <p style={{marginBottom: 40}} className="description">Our training program includes a wide range of resources and tools that educators can use to enhance their instruction and engage students in learning.We provide all classroom solutions for STEAM laboratory, interior, teaching resources, handbook, quizzes for students and handson training session. Furthermore, we provide training on analytics and data tracking tools that allow educators to monitor student progress and make data-driven decisions about instruction.</p> */}
                                              {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              
                          </div>
                        </div>
                        <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 100,}}>
                            <div className="col-lg-8">
                              <h2 style={{textAlign: 'center', marginBottom: 80, fontSize: 36}} className="title">Enhancing Middle School Education with ANTT Robotics Hardware and Apps</h2>
                            </div>
                            
                            <img style={{marginBottom: 70}} src="/assets/images/education/4 (1).webp"/>
                            <p className="description">Unlock the power of learning with ANTT! Our educational software and apps provide a powerful way to engage students with interactive simulations, games, and other tools. With ANTT, you can make learning more engaging, meaningful, and fun for your students. Take the guesswork out of instruction and give your students the edge they need to succeed.</p>
                        </div>
                      </div>
                      {/* End Single Area */}

                      {/* Start Single Area */}
                      <div style={{backgroundColor: '#F2FCFF', marginTop: -100, paddingTop: 5}}>
                        <div style={{transform: 'scale(0.8)', transformOrigin: 'top center', }} className="container">
                          <div className="col-lg-12">
                            

                            
                            <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0, marginTop: 150}}>                                  
                              
                              <div className="col-lg-6">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="section-title service-style--3 text-left mb_sm--0">
                                              <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Unlock the Potential of Coding and Robotics with ANTT Robotics - Plug-and-Play Solutions for Elementary School Teachers.</h2>
                                              <p className="description">Explore the exciting world of robotics and technology with ANTT Robotics' all-in-one STEM DIY Box for elementary school teachers. Our specially curated box is filled with simple sensors and components that make it easy for teachers to control robots wirelessly and complete fun projects. With our step-by-step guides and detailed instructions, educators can easily learn to program and control the robots, while engaging their students in hands-on learning experiences. Unlock the potential of your students and take the first step in the fourth industrial revolution with ANTT Robotics' STEM DIY Box.</p>
                                              {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6" >
                                <img  src="/assets/images/education/4.webp" alt="About Images"/>
                                
                              </div>
                            </div>
                            <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0, marginTop: 150}}>                                  
                              <div className="col-lg-6" >
                                <img  src="/assets/images/education/5.webp" alt="About Images"/>
                                
                              </div>
                              <div className="col-lg-6">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="section-title service-style--3 text-left mb_sm--0">
                                              <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Unlock the Power of Programming and Robotics with ANTT</h2>
                                              <p className="description">Advanced Coding Courses for C, Python and MicroPython. Elevate your skills and knowledge to embark on the journey of the fourth industrial revolution. Our expert-led tutorials and courses are tailored for the educators to make them career ready for the world of IoT and programming. Join us and develop dependable expertise in coding and IoT with ANTT Robotics.</p>
                                              {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      {/* End Single Area */}

                      {/* Start Single Area */}
                      {/* <div className="row align-items-center" style={{marginTop: 150, marginLeft: -4, marginRight: 0}}>                                  
                          
                          <div className="col-lg-7">
                              <div className="about-inner inner">
                                  <div className="row">
                                      <div className="section-title service-style--3 text-left mb_sm--0">
                                          <h2 style={{fontSize: 45, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Digital literacy training for educators is crucial in today's digital age.</h2>
                                          <p style={{marginBottom: 40}} className="description">As technology plays an increasingly important role in our daily lives, educators must have the skills and knowledge to effectively integrate technology and digital tools in the classroom. Our digital literacy training program is designed to help educators develop the skills they need to use technology and digital tools to access, analyze, and create information.</p>
                                          <p style={{marginBottom: 40}} className="description">Our training program includes a wide range of resources and tools that educators can use to enhance their instruction and engage students in learning.We provide all classroom solutions for STEAM laboratory, interior, teaching resources, handbook, quizzes for students and handson training session. Furthermore, we provide training on analytics and data tracking tools that allow educators to monitor student progress and make data-driven decisions about instruction.</p>
                                          <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-lg-5">
                              <div className="thumbnail" style={{position: 'relative'}}>
                                  <img className="w-100" src="/assets/images/education/Digital literacy training_.webp" alt="About Images"/>
                              </div>
                          </div>
                      </div> */}
                      {/* End Single Area */}

                      {/* Start Contact Us */}
                      <div style={{marginTop: -100, paddingTop: 130, backgroundColor: '#fff'}}>
                        <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                          <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0}}>                                  
                              
                              <div className="col-lg-6">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="section-title service-style--3 text-left mb_sm--0">
                                              <h2 style={{fontSize: 36, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Enhance Your CS Teaching Skills with ANTT Robotics' Classroom Automation and Education Management Training</h2>
                                              <p className="description">Enhance your teaching skills and prepare your students for the future with ANTT Robotics' CS learning program for educators. Our comprehensive training includes daily teaching software and fourth industrial software for your middle school classroom, such as Google, Microsoft, Scratch MIT, EduBlock, School of IoT, and Arduino. With ANTT Robotics, you can automate your classroom and manage your education with ease, giving your students the best possible chance at success in the fast-paced digital world. Upgrade your teaching game and give your students a head start in their future careers with ANTT Robotics' CS learning program</p>
                                              {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6" >
                                <img  src="/assets/images/education/classroom-automation.webp" alt="About Images"/>
                                
                              </div>
                            </div>
                        </div>
                      </div>
                      
                    {/* End Contact Us */}
                      

              

                  </div>
              </div>
                        
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={0}  />

        </React.Fragment>
    )
}
export default ProductsServices;
