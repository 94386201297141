import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";
import ContactForm from "./contact/ContactForm";


class ServiceCustomisedSolutions extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "//embed.typeform.com/next/embed.js";
        script.async = true;
        document.body.appendChild(script);
    }
    render(){
        return(
            <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image vocational_bg" 
                 style={{ 
                    backgroundColor: '#5255FF', 
                    display: 'flex',
                    backgroundPosition: 'top', 
                    alignItems: 'center',
                    position: 'relative', 
                }}>
                
                <div style={{transform: 'scale(0.8)'}} className="container">
                    <div className="row" style={{alignItems: 'center'}}>
                        
                        <div className="col-lg-8 about_hero_content">
                            <h2>CUSTOMIZE SOLUTION</h2>
                            <p>Full Stack Robotics, Hardware, Software, and Machine Learning Solutions for all your needs. From prototyping to mass production, ANTT is the right choice for you</p>
                        </div>
                        
                        <div className="col-lg-4 about_hero_image" style={{alignSelf: 'flex-end'}}>
                            <img src="/assets/images/service/customised-solutions/hero.webp"/>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details bg_color--1">
                <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container customized-solution-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 100}}>
                                        <h2 style={{alignSelf: 'flex-start', marginBottom: 20, fontSize: 40}} className="title">Essential Frameworks for Developing Cutting-Edge Technology</h2>
                                        <p style={{ marginBottom: 50}}>A one-stop shop for commercial organizations or Startup's looking to expand globally in the electro-robotic-IoT field. ANTT Robotics' expert team is always on standby for inventors and corporations who are interested in inventing, producing, and testing novel technology for a good cause.We offer fully customizable and adaptable methodology for joint-collaboration on large challenges to make ideas turn into reality</p>
                                        <h4 style={{alignSelf: 'flex-start', fontSize: 20, fontWeight: 600, marginBottom: 80}}>The following are the essential frameworks for developing a product from the ground up:</h4>
                                        <img style={{width: '80%'}} src="/assets/images/service/customised-solutions/1.webp"/>
                                      
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 120}}>                                  
                                        <div className="col-lg-6" style={{padding: 0}}>
                                            <img className="customized-image" src="/assets/images/service/customised-solutions/2.webp" alt="About Images"/>                                 
                                        </div>
                                        <div className="col-lg-6 customized-content" style={{backgroundColor: '#F0F9FF', borderRadius: 15, display: 'flex'}}>
                                            <div className="about-inner inner" style={{display: 'flex'}}>
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0}}>
                                                        <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%'}} className="title">Integrating Sensors, Actuators, and PLCs for a Seamless Control Environment</h2>
                                                        <p className="description">Custom solutions enable several levels of access to hardware, allowing for a diverse range of research projects and applications. Creating Internet of Things (IoT) software and hardware to give high-level control comparable to that found in today's industrial equipment in order to monitor data, productivity, and surroundings, among other things.</p>                                                       
                                                        <p className="description">ANTT Robotics provides bespoke IoT, AI, and blockchain desktop software, web applications, mobile applications, and APIs for multiple programming languages, as well as logging tools, allowing system developers to operate ANTT components or a client's hardware using the environment that best matches the project.</p>                                                       
                                                        <p className="description">A diverse variety of sensors, actuators, mechanisms, and PLCs may be integrated to create your control environment with minimal overhead and that can be readily studied and displayed</p>                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 120}}>                                  
                                        
                                        <div className="col-lg-6 enhance-teach-content customized-content" style={{backgroundColor: '#F0F9FF', borderRadius: 15, display: 'flex'}}>
                                            <div className="about-inner inner" style={{display: 'flex'}}>
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0}}>
                                                        <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%'}} className="title">Fighting COVID with Telepresence Robots: ANTT Robotics in Action"</h2>
                                                        <p className="description">Our experience is in working with customers to conceptualize, build, and produce customized and semi-custom robots to solve their challenges. Creating individualized robots is difficult, fun, and immensely gratifying. Working to bring a client's concept to life through research, analysis, minimum viable product development, support documentation, and production.</p>                                                       
                                                        <p className="description">Our robotics solutions increase production consistency and process reliability, allowing our clients to create more aggressive production schedules. Our highly qualified and devoted robotic automation engineers design, manufacture, and install robotic automation systems for a wide range of production applications.</p>                                                       
                                                        <p className="description">ANTT developed a telepresence robot to assist doctors with regular COVID checkups remotely for AIMS LAB, United International University, Dhaka, Bangladesh.</p>                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 enhance-teach-image" style={{padding: 0}}>
                                            <img className="customized-image" src="/assets/images/service/customised-solutions/3.webp" alt="About Images"/>                                 
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                        

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 120}}>                                  
                                        <div className="col-lg-6" style={{padding: 0}}>
                                            <img className="customized-image" src="/assets/images/service/customised-solutions/4.webp" alt="About Images"/>                                 
                                        </div>
                                        <div className="col-lg-6 customized-content" style={{backgroundColor: '#F0F9FF', borderRadius: 15, display: 'flex'}}>
                                            <div className="about-inner inner" style={{display: 'flex'}}>
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0}}>
                                                        <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%'}} className="title">UAV-Supported Seed Sowing: A Successful Tool for Revegetation and Stabilization</h2>
                                                        <p className="description">We have successfully developed an x-copter, drone, or UAV to plant "Seed Balls" that have been planted in agricultural areas with the help of a new unmanned aerial vehicle that does not hurt the seeds. It's a simple, portable, and easy-to-fly drone. The experimental and theoretical values for the drone have been determined and calculated. The findings of the drone were studied using a thrust calculation with a 50% throttle.</p>                                                       
                                                        <p className="description">Academics, forest managers, restoration practitioners, policymakers, landowners, environmental conservationists, remote sensing scientists, and biotechnology researchers have all expressed interest in UAV-supported seed sowing as a successful revegetation and stabilization tool that can supplement ongoing traditional A/R programs and practices. The results were validated by repeating the experiment in other fields.</p>                                                                                                              
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    <div className="rn-contact-us ptb--30" id="contact">
                                        <div className="contact-form--1">
                                            
                                            <div className="row row--35" style={{alignItems: 'stretch', marginTop: 150}}>
                                                <div className="col-12 order-2 order-lg-1">
                                                    <div className="section-title text-left mb--50">
                                                        <p style={{color: '#2c7fc2', marginBottom: 2, fontSize: 30}}>Interested?</p>
                                                        <h2 style={{marginBottom: 15, fontSize: 40, fontWeight: 400}} className="title">Book your <span style={{fontWeight: 600}}>Free</span> Session</h2>
                                                        <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                                    </div>
                                                    <div className="form-wrapper">
                                                        {/* <ContactForm /> */}
                                                        <div data-tf-live="01J8T05PHQMQ54P9ANXE209CTP" style={{ width: '100%', height: '500px' }}></div>
                                                        
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6 order-1 order-lg-2">
                                                    <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                                        <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                                    </div>
                                                </div> */}
                                            </div>
                                            
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-800}  />

        </React.Fragment>
        )
    }
}
export default ServiceCustomisedSolutions;