import React , {useState} from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}
function LoginForm({props}) {
    const [ result, showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        // emailjs
        // .sendForm(
        //     'service_p4x3hv8', 
        //     'template_jgfr42f', 
        //     e.target, 
        //     'user_jrfTH2e0Ely35ZCVFdT9S'
        // )
        // .then((result) => {
        //     console.log(result.text);
        //     }, 
        //     (error) => {
        //         console.log(error.text);
        //     }
        // );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form action="" onSubmit={sendEmail} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div className="rn-form-group" style={{marginBottom: 28, width: '100%'}}>
                <input 
                type="email"
                name="email"
                placeholder="Your Email"
                required
                />
            </div>

            <div className="rn-form-group" style={{marginBottom: 28, width: '100%'}}>
                <input 
                type="password"
                name="password"
                placeholder="Password"
                required
                />
            </div>

            <div style={{marginBottom: 18}} className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" style={{background: '#1f1f25', border: '2px solid #1f1f25'}} type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Login now</button>
            </div>

            <p style={{fontSize: 14}}>Need access? <a style={{textDecoration: 'underline'}} href='/contact'>Contact us</a></p> 
        </form>
    )
}
export default LoginForm;
