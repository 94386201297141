import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";


class ServiceDetailsSteam extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='Service Details' />
                {/* End Pagehelmet  */}

                <HeaderFixed />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area bg_image" style={{backgroundImage: 'url(/assets/images/bg/steam-education-banner.png)', paddingBottom: 152, paddingTop: 96}}  data-black-overlay="5">
                    <div style={{transform: 'scale(0.8)'}} className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">STEAM EDUCATION</h2>
                                    <p>Full Stack Edtech Solutions For Enterprise</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div style={{transform: 'scale(0.8)', transformOrigin: 'center center'}} className="container">
                        <div className="row space-reduction">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div  className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-01.png" alt="Service Images"/>
                                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='6iSq4Ad0voI' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Improve Educational Outcomes with ANTT</h4>
                                                    <p>Are you looking for computer science, IoT, Robotics, or STEAM curriculum and products? ANTT is your answer. We've created the world's most comprehensive coding learning platform that both adults and children alike will enjoy. Our robotics programs are designed for learners ages 5 and up to teach STEAM education with a robotics focus. It includes a hands-on STEAM curriculum to teach problem-solving, teamwork, creativity, and leadership. ANTT is working hard to help all students develop the skills needed to thrive in the 21st century.</p>
                                                    <p>Use our mobile app or web portal to learn anytime, anywhere, with our ready-to-use coding software. We aim to help educators improve the quality of education by providing a unified and connected learning environment. We  provide intelligent and adaptive learning processes where students can be constantly supported, monitored, and challenged.</p>
                                                    <h4 className="title">We provide the following LMS Support for Educators:</h4>
                                                    <ul className="liststyle">
                                                        <li>Educational data management</li>
                                                        <li>Testing, assessment and evaluation</li>
                                                        <li>Learning analytics</li>
                                                        <li>Adaptive teaching</li>
                                                        <li>Personalized curriculum</li>
                                                        <li>Data-driven decision-making tools</li>

                                                    </ul>                                                                                                                                                      
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div  className="row sercice-details-content align-items-center" style={{marginBottom: 50}}>
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>The ANTT Robotics Smart Ecosystem in Education Technology is a customizable platform for interactive and immersive learning in Computer Science and Coding, with a focus on IoT, artificial intelligence, and future technologies. It is designed to help students learn better and faster while providing the necessary tools for students to pursue their education and career goals. We provide STEAM teacher training as well as resources for teachers like supplementary course booklets, class lecture notes, teaching guides and student assessment materials and support.</p>           
                                                    <h4 className="title">We provide the following support for partners:</h4>
                                                    <ul className="liststyle">
                                                        <li>Marketing and design support to establish your brand in your country</li>
                                                        <li>Use Case Scenario examples and access to customer testimonials</li>
                                                        <li>Pilot and/or STEM Lab set-up and guidance</li>
                                                        <li>Computer science education that is accessible, affordable, and engaging</li>
                                                        <li>ODM and OEM services</li>
                                                        <li>A straightforward Edtech Business Partnership process</li>
                                                        <li>EOI</li>

                                                    </ul>
                                                    <br/>
                                                    <p>At ANTT we have an open-door policy. Please feel free to contact us at any time and we will always do our best to support your business and help you succeed.</p> 
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.jpg" alt="Service Images"/>
                                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div  className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-03.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Introduction to ANTT Robotics</h4>
                                                    <p>Product Category & Partnership Opportunities</p>
                                                    <p>Technical Discussion & Support Policy (Sample Products $)</p>
                                                    <p>Sample Product Training & Curriculum Survey</p>
                                                    <p>Feedback & Partnership Agreement (Learn about Packaging Policies)</p>
                                                    <h4 className="title">Customer Support and After-Sales Services</h4>
                                                    <ul className="liststyle">
                                                        <li>Teach the Trainer Program</li>
                                                        <li>Hardware and device troubleshooting support</li>
                                                        <li>Software updates</li>
                                                        <li>Teaching resource updates</li>
                                                        <li>Monitoring and Evaluation</li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div  className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>EduBlock is a unique kit that teaches youngsters STEAM topics and interactive online and offline applications. It's a gamified strategy that involves augmented reality, digital books, and live sessions. </p>
                                                    <p>Smartphones may also be used to offer rapid access to educational materials by this one can develop multiple projects like Line follower, Distance Measurement & Soccer Robot and so on. Edublock is the best fit for aged 05 – 10 years of students.</p>
                                                    <h4 className="title">EduBlocks Benefits</h4>
                                                    <ul className="liststyle">
                                                        <li>Robotics code, Control, Implement using Edublock App.</li>
                                                        <li>Can make multiple  projects </li>
                                                        <li>Learn Graphical programming, C programming, Python</li>
                                                        <li>Code, Control, Implement using Edublock App</li>
                                                        <li>Plug & Play Hitech solutions using Modern technology</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-02.jpg" alt="Service Images"/>
                                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div  className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-03.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>"School of IoT" is a head start resource for schoolchild aged 10 and up to learn data science and the internet of things. The kit includes all necessary tools and four different types of sensors to complete the fundamental 12 projects, which include a smart home, grading system, weather monitoring system, and many others.</p>
                                                    {/* <p>With education technology is now one of the growing sectors in business. ANTT Robotics offers the following services to help your Edtech firm succeed in your local area:</p> */}
                                                    <h4 className="title">Shool of IOT Benefits</h4>
                                                    <ul className="liststyle">
                                                        <li>Electronic and Mechanical equipments with 3 months free service</li>
                                                        <li>Interactive IoT Dashboard & learn Graphical programming, C programming, Python</li>
                                                        <li>Gamified learning guide </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div  className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Robotic Car Kit is a premium Addons for “School of IoT”. This package comes with the following premium equipment to make RC car to experiment with coding through IoT or Bluetooth communication using Mobile Application. </p>
                                                    {/* <p>Smartphones may also be used to offer rapid access to educational materials by this one can develop multiple projects like Line follower, Distance Measurement & Soccer Robot and so on. Edublock is the best fit for aged 05 – 10 years of students.</p> */}
                                                    {/* <h4 className="title">EduBlocks Benefits</h4> */}
                                                    <ul className="liststyle">
                                                        <li>DC Motor Driver</li>
                                                        <li>4 DC Motors</li>
                                                        <li>4 Wheels</li>
                                                        <li>A Car Chassis</li>
                                                        <li>18650 NCR Battery</li>
                                                        <li>18650 Battery Holder</li>
                                                        <li>18650 Battery Charger</li>
                                                        <li>Jumper Wires</li>
                                                        <li>An Android Phone</li>
                                                        <li>Instructions </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-04.jpeg" alt="Service Images"/>
                                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div  className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-05.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Robotic arm kit is a premium addon of “School of IoT” which is focused to learning industrial robotics arm design, development and uses. Mechanical arm are usually programmed to execute a specific task or job quickly, efficiently, and extremely accurately.</p>
                                                    <p>ANTT Robotic Arm kit packages are developed to demonstrate especially valued in the industrial production, manufacturing, machining and assembly sectors experiments.</p>
                                                    {/* <h4 className="title">Shool of IOT Benefits</h4>
                                                    <ul className="liststyle">
                                                        <li>Electronic and Mechanical equipments with 3 months free service</li>
                                                        <li>Interactive IoT Dashboard & learn Graphical programming, C programming, Python</li>
                                                        <li>Gamified learning guide </li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div  className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>AntRobo is a robotic car, you may write your own code and build 100 projects using STEAM technique, including robots that play soccer games, follow lines, calculate distance, and more activity that makes student analytical thinker and logical thinker. </p>
                                                    <p>Can learn using smartphone, tablet, and computers. The device and coding learning software are focused on Plug & Play gamified methodology to Code, Control, and Exprements.</p>
                                                    <h4 className="title">AntRobo Benefits</h4>
                                                    <ul className="liststyle">                               
                                                        <li>Code, Control, Implement using Python, C, or graphical coding</li> 
                                                        <li>Plug & Play methodology and circuit marking.</li> 
                                                        <li>Build 100 projects using AntRobo</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/service-06.jpg" alt="Service Images"/>
                                                    {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                                    <button className="video-popup" onClick={this.openModal}><span className="play-icon"></span></button> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/service-07.jpg" alt="Service Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <p>Modular device that comes with IoT features on board. Modular parts help to learn about each component and operations design to solve regular life problems like Smart Garbage Bin, personal weather station, anti-theft smart bag and many more.</p>
                                                    <p>Students can also enjoy soccer robots, RC cars, Spy Robot and many fun projects. Robo Planet - is focused on STEAM activity and fourth industrial revolution skills</p>
                                                    {/* <h4 className="title">Shool of IOT Benefits</h4>
                                                    <ul className="liststyle">
                                                        <li>Electronic and Mechanical equipments with 3 months free service</li>
                                                        <li>Interactive IoT Dashboard & learn Graphical programming, C programming, Python</li>
                                                        <li>Gamified learning guide </li>
                                                    </ul> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer topMargin={-760} />

            </React.Fragment>
        )
    }
}
export default ServiceDetailsSteam;