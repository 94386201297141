import React from 'react';
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn, FaYoutubeSquare } from "react-icons/fa";

const SocialShare = [
    { Social: <FaFacebookF />, link: 'https://www.facebook.com/anttroboticsltd/' },
    { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/company/anttroboticsltd/' },
    { Social: <FaInstagram />, link: 'https://instagram.com/anttrobotics' },
    { Social: <FaYoutubeSquare />, link: 'https://youtube.com/anttroboticsltd' },
]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--10 bg_image bg_image--1" data-black-overlay="6">
            <div className="wrapper plr--50 plr_sm--20">
                <div style={{ transform: 'scale(0.8)', transformOrigin: 'top', paddingTop: 60 }} className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-5 col-md-6 col-sm-6 col-12" style={{ paddingRight: 50 }}>
                            <div className="inner" style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="logo text-center text-sm-left mb_sm--20" style={{ marginBottom: 30 }}>
                                    <a href="/home-one">
                                        <img style={{ width: '30%' }} src="/assets/images/logo/logo-light.png" alt="Logo images" />
                                    </a>
                                </div>
                                <p style={{ color: 'white', fontSize: 12, marginBottom: 90 }}>ANTT Robotics simplifies the transition to Industry 4.0 with cutting-edge technology for corporations, SMBs, and academic institutions. We believe in equal access to technology for all, promoting creativity, teamwork, and innovation for streamlined processes and business growth. Our commitment to sustainability, social responsibility, and community development is grounded in a culture of diversity and inclusion. Join us to lead the future of the robotics industry and grow your business with our AI, IoT, robotics, and knowledge transfer solutions.</p>
                                <h3 style={{ fontWeight: 600, fontSize: 18, color: 'white' }}>Get in Touch</h3>
                                <div className="inner" style={{ marginBottom: 30 }}>
                                    <ul className="social-share rn-lg-size d-flex liststyle">
                                        {SocialShare.map((val, i) => (
                                            <li key={i}><a target="_blank" href={`${val.link}`}>{val.Social}</a></li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                            <div className="inner" style={{ display: 'flex', flexDirection: 'column' }}>
                                <h3 style={{ fontWeight: 600, fontSize: 18, color: 'white' }}>Company</h3>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="/about">About Us</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="#">Partenership</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="/investor-portal">Investor Protal</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="#">Business Profile</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="#">Career</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 30 }} href="/contact">Contact</a>
                                <h3 style={{ fontWeight: 600, fontSize: 18, color: 'white' }}>Support Center</h3>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="#">Support</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="#">ANTT AI Chat bot</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="#">Media Room</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="#">Teachers</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="/terms-and-conditions">Terms and Conditions</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="/refund-policy">Refund</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 30 }} href="/privacy-policy">Privacy Policy</a>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-12 col-sm-12 col-12">
                            <div className="inner" style={{ display: 'flex', flexDirection: 'column' }}>
                                <h3 style={{ fontWeight: 600, fontSize: 18, color: 'white' }}>Solutions</h3>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="#">For Educators</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="#">For Institutes</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="#">Industrial Services</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 30 }} href="#">PCB Design & Printing</a>

                                <h3 style={{ fontWeight: 600, fontSize: 18, color: 'white' }}>Resource</h3>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="#">AI Chatbot</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 10 }} href="#">For Teachers</a>
                                <a style={{ color: '#B3B3B3', marginBottom: 30 }} href="https://iotsnacksbox.io/" target="_blank">IoT Snacks Box</a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <div className="inner" style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex' }}>
                                    <img style={{ height: 22, marginRight: 20 }} src="/assets/images/footer/BD.webp" alt="Logo images" />
                                    <h3 style={{ fontWeight: 600, fontSize: 18, color: 'white' }}>Bangladesh Address </h3>
                                </div>
                                <div style={{ display: 'flex', marginBottom: 20 }}>
                                    <p style={{ color: 'white', fontSize: 14, fontWeight: 500, width: 100, marginRight: 10 }}>Address:</p>
                                    <p style={{ color: '#B3B3B3', fontSize: 14, width: 250 }}>ANTT Robotics R&D Office:L-4, Gha 75/A, Moddho Badda, Gulshan-1, Dhaka -1212, BD</p>
                                </div>
                                <div style={{ display: 'flex', marginBottom: 20 }}>
                                    <p style={{ color: 'white', fontSize: 14, fontWeight: 500, width: 100, marginRight: 10 }}>Email:</p>
                                    <p style={{ color: '#B3B3B3', fontSize: 14, width: 250 }}>anttrobotics@gmail.com uday@anttrobotics.com </p>
                                </div>
                                <div style={{ display: 'flex', marginBottom: 30 }}>
                                    <p style={{ color: 'white', fontSize: 14, fontWeight: 500, width: 100, marginRight: 10 }}>Call:</p>
                                    <p style={{ color: '#B3B3B3', fontSize: 14, width: 250 }}>+880 192 577 0036</p>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <img style={{ height: 22, marginRight: 20 }} src="/assets/images/footer/USA.webp" alt="Logo images" />
                                    <h3 style={{ fontWeight: 600, fontSize: 18, color: 'white' }}>USA  Address </h3>
                                </div>
                                <div style={{ display: 'flex', marginBottom: 20 }}>
                                    <p style={{ color: 'white', fontSize: 14, fontWeight: 500, width: 100, marginRight: 10 }}>Address:</p>
                                    <p style={{ color: '#B3B3B3', fontSize: 14, width: 250 }}>30 N Gould St Suit R Sheridan, WY 82801, USA</p>
                                </div>
                                <div style={{ display: 'flex', marginBottom: 20 }}>
                                    <p style={{ color: 'white', fontSize: 14, fontWeight: 500, width: 100, marginRight: 10 }}>Email:</p>
                                    <p style={{ color: '#B3B3B3', fontSize: 14, width: 200 }}>support@anttrobotics.com jayme.ibd@anttrobotics.com</p>
                                </div>
                                <div style={{ display: 'flex', marginBottom: 30 }}>
                                    <p style={{ color: 'white', fontSize: 14, fontWeight: 500, width: 100, marginRight: 10 }}>Call:</p>
                                    <p style={{ color: '#B3B3B3', fontSize: 14, width: 250 }}>+44 7904 386644</p>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <img style={{ height: 22, marginRight: 20 }} src="/assets/images/footer/korea.png" alt="Logo images" />
                                    <h3 style={{ fontWeight: 600, fontSize: 18, color: 'white' }}>South Korea Address </h3>
                                </div>
                                <div style={{ display: 'flex', marginBottom: 20 }}>
                                    <p style={{ color: 'white', fontSize: 14, fontWeight: 500, width: 100, marginRight: 10 }}>Address:</p>
                                    <p style={{ color: '#B3B3B3', fontSize: 14, width: 250 }}>Seoul City, MapoGu, SeungMunGil, South Korea, 04112</p>
                                </div>
                                <div style={{ display: 'flex', marginBottom: 20 }}>
                                    <p style={{ color: 'white', fontSize: 14, fontWeight: 500, width: 100, marginRight: 10 }}>Email:</p>
                                    <p style={{ color: '#B3B3B3', fontSize: 14, width: 200 }}>support@anttrobotics.com jayme.ibd@anttrobotics.com</p>
                                </div>
                                <div style={{ display: 'flex', marginBottom: 30 }}>
                                    <p style={{ color: 'white', fontSize: 14, fontWeight: 500, width: 100, marginRight: 10 }}>Call:</p>
                                    <p style={{ color: '#B3B3B3', fontSize: 14, width: 250 }}>+82 10-7154-5263</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                            <p style={{ color: 'white', fontSize: 14, fontWeight: 400 }}>Copyright © 2022 ANTT Robotics LTD; LLC All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;