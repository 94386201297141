import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";
import ContactForm from "./contact/ContactForm";


class ServiceRoboticGadgets extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    componentDidMount() {
        const script = document.createElement("script");
        script.src = "//embed.typeform.com/next/embed.js";
        script.async = true;
        document.body.appendChild(script);
    }
    render(){
        return(
            <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image about_bg" 
                 style={{ 
                    backgroundColor: '#5255FF', 
                    display: 'flex',
                    backgroundPosition: 'top', 
                    alignItems: 'center',
                    position: 'relative', 
                }}>
                
                <div style={{transform: 'scale(0.8)',}} className="container">
                    <div className="row" style={{alignItems: 'center'}}>
                        
                        <div className="col-lg-6 about_hero_content">
                            <h2>ROBOTICS GADGETS</h2>
                            <p>Full Stack Robotics Lifestyle Solutions</p>
                        </div>
                        
                        <div className="col-lg-6 about_hero_image" style={{alignSelf: 'flex-end'}}>
                            <img src="/assets/images/service/robotic-gadgets/hero.webp"/>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--80 bg_color--1 service-rb-gadgets">
                <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <div className="service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30}}>
                                        <h2 className="title">ANTT RoboStep into the Future with ANTT's Smart Watch Technology! Tics presents our  "IoT Snacks Box"</h2>
                                        <img src="/assets/images/service/robotic-gadgets/1.webp"/>
                                        <p className="description">Discover the Power of Innovation with ANTT. As technology continues to advance, it's transforming the way we live and access medical care. The rise of robots in industries like healthcare is just the beginning.</p>

                                        <p>ANTT is dedicated to creating a smarter, more responsible and data-driven environment for you. Stay ahead of the curve with our cutting-edge smart watch technology. Not only does it track your health and physical activity, but it also keeps you connected to your home appliances, appointments, and finances. It's your one-stop-shop for managing your digital life.</p>

                                        <p>Experience the ease of use as our smart watch doubles as a micro-controller for all your smart gadgets without needing any coding knowledge. Get started with simple tasks like checking your email or monitor your home with just a few taps. Join the revolution and upgrade your life with ANTT's Smart Watch Technology.</p>
                                      
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 150}}>
                                        <div className="col-lg-8" style={{alignSelf: 'flex-start', padding: 0}}>
                                            <h2 className="title">Unlock Your Creativity with ANTT's Smart Watch Technology!</h2>
                                        </div>
                                        
                                        <img src="/assets/images/service/robotic-gadgets/2.webp"/>
                                        <p className="description">Encourage students to bring their ideas to life with the power of programming. Our smart watch offers a unique platform for students to dive into computer science and discover new passions. With Scratch [graphical programming], students can develop their coding skills by creating animations, games, and interactive projects. The possibilities are endless, and the hands-on learning experience is invaluable. Moreover, the smart watch can also be used as a data scientist tool to analyze and interpret data. It's an excellent opportunity for students to practice critical thinking and problem-solving skills as they tell their stories through data. Unlock your students' full potential with ANTT's Smart Watch Technology. Give them the tools they need to succeed in the digital world and unleash their creativity!</p>
                                      
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 150}}>
                                        <div className="col-lg-9" style={{alignSelf: 'flex-start', padding: 0}}>
                                            <h2 className="title">Unlock a World of Possibilities with ANTT-Watch: The Smart Companion for Seniors</h2>
                                        </div>
                                        
                                        <img src="/assets/images/service/robotic-gadgets/3.webp"/>
                                        <p>ANTT has revolutionized the game for elderly care with its innovative ANTT-Watch. This smart companion offers seniors a user-friendly way to stay on top of their daily routines and stay connected with loved ones. With a touch-based graphical interface, the ANTT-Watch makes it easy for seniors to keep track of their health, medication, and exercise, without needing to know how to code.</p>

                                        <p>Never miss a pill again! The ANTT-Watch is equipped with reminders that help seniors keep on top of their daily medications and routines. It also allows family members to remotely monitor and check in on their loved ones. Whether you're looking to enhance your daily life or keep your loved ones safe, the ANTT-Watch is the perfect solution.</p>

                                        <p>The ANTT-Watch goes beyond traditional wearable devices by offering seniors an array of features that cater to their specific needs. From controlling their home appliances to setting reminders, the</p>

                                        <p>ANTT-Watch makes daily life easier and more convenient. Its cutting-edge technology ensures that seniors can always stay connected and in control, even from the comfort of their own homes.</p>

                                        <p>Experience the future of elderly care with ANTT's innovative ANTT-Watch. Get your hands on the smart companion that offers a touch of technology to your everyday life.</p>
                                      
                                    </div>
                                    {/* End Single Area */}

                        

                                    {/* Start Single Area */}
                                    <div className="service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 150}}>
                                        <div className="col-lg-10" style={{alignSelf: 'flex-start', padding: 0}}>
                                            <h2 className="title">Health Care</h2>
                                        </div>
                                        
                                        <img src="/assets/images/service/robotic-gadgets/4.webp"/>
                                        <p>ANTT's advanced health and pregnancy monitoring device is the perfect solution for expectant mothers and those who are looking to keep a close eye on their health. This compact and user-friendly device is packed with features that help you stay on top of your health, both during pregnancy and beyond.</p>

                                        <p>Track your sleep patterns, stress levels, and heart rate to gain valuable insights into your overall health and wellness. The device reminds you to eat healthy meals, take your medications on time, and monitor your blood pressure levels, so you can take charge of your health with ease</p>

                                        <p>In case of any emergency, you can quickly call your emergency contacts or connect to nearby hospitals with just a touch of a button. With ANTT's health and pregnancy monitoring device, you can feel confident and secure knowing that you have a reliable source of support by your side. Get started today and take control of your health and wellbeing!</p>
                                        <ul style={{color: '#717173', fontWeight: 300, fontSize: 18, alignSelf: 'flex-start', margin: '20px 0'}}>
                                            <li>Multi-functional graphical interface</li>
                                            <li>Remote monitoring and control through smartphone or computer</li>
                                            <li>Flexible customization options</li>
                                            <li>Integrated health monitoring and reminders</li>
                                            <li>Emergency call and location support</li>
                                            <li>Easy-to-use software and user-friendly design</li>
                                        </ul>
                                      
                                    </div>
                                    {/* End Single Area */}

                                    <div className="rn-contact-us ptb--30" id="contact">
                                        <div className="contact-form--1">
                                            
                                            <div className="row row--35" style={{alignItems: 'stretch', marginTop: 120}}>
                                                <div className="col-12 order-2 order-lg-1">
                                                    <div className="text-left mb--50">
                                                        <p style={{color: '#2c7fc2', marginBottom: 2, fontSize: 30}}>Interested?</p>
                                                        <h2 style={{marginBottom: 15, fontSize: 40, fontWeight: 400}} className="title">Book your <span style={{fontWeight: 600}}>Free</span> Session</h2>
                                                        <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                                    </div>
                                                    <div className="form-wrapper">
                                                        {/* <ContactForm /> */}
                                                        <div data-tf-live="01J8T05PHQMQ54P9ANXE209CTP" style={{ width: '100%', height: '500px' }}></div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-6 order-1 order-lg-2">
                                                    <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                                        <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                                    </div>
                                                </div> */}
                                            </div>
                                            
                                        </div>
                                    </div>
                                    {/* End Contact Us */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-1140}  />

        </React.Fragment>
        )
    }
}
export default ServiceRoboticGadgets;