import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";


class ServiceIotAiot extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image vocational_bg" 
                 style={{ 
                    backgroundColor: '#5255FF', 
                    display: 'flex',
                    backgroundPosition: 'top', 
                    alignItems: 'center',
                    position: 'relative', 
                }}>
                
                <div style={{transform: 'scale(0.8)'}} className="container">
                    <div className="row" style={{alignItems: 'center'}}>
                        
                        <div className="col-lg-6 about_hero_content">
                            <h2>IoT & AIIoT SOLUTION</h2>
                            <p>Industrial IoT (IIoT) Full Stack No Coding Solution for Enterprise, Factory, Industry, and MNC</p>
                        </div>
                        
                        <div className="col-lg-6 about_hero_image" style={{alignSelf: 'flex-end'}}>
                            <img src="/assets/images/service/iot-aiot/hero.webp"/>
                        </div>
                        
                        
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details ptb--120 bg_color--1">
                <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container iot-service-body">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30}}>
                                        <h2 style={{alignSelf: 'flex-start', marginBottom: 80, fontSize: 40}} className="title">ANTT Robotics presents our  "IoT Snacks Box"</h2>
                                        <img style={{marginBottom: 70, width: '100%'}} src="/assets/images/service/iot-aiot/1.webp"/>
                                        <p className="description">ANTT Robotics specializes in the Internet of Things (IoT), Artificial Intelligence for IoT (AIIoT), Industrial IoT (IIoT), Machine-to-Machine Communication, and Embedded Data Acquisitions.</p>
                                      
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 150}}>
                                        <div className="col-lg-8" style={{alignSelf: 'flex-start', padding: 0}}>
                                            <h2 style={{marginBottom: 80, fontSize: 40}} className="title">Learn more about Data-Science just like an engineer or a scientist!</h2>
                                        </div>
                                        
                                        <img style={{marginBottom: 70}} src="/assets/images/service/iot-aiot/2.webp"/>
                                        <p className="description">With the fast-approaching future of the Internet of Things (IoT) many schools are looking for ways to incorporate this innovation into their curriculum. The Internet of Things (IoT) is a network of devices such as connected cars, thermostats and wearable technology. The IoT enables these devices to connect with each other and exchange data. For example, a car can communicate with its driver's phone to let him know that the vehicle is running out of gas. STEAM education has been highlighted as a way to prepare future generations for the continuous changes in technology that are taking place around us every day. ANTT endeavors to provide learners with the skills necessary to take advantage of future career opportunities as technology advances. Our IoT Snacks Box is a STEAM integrated hardware and software platform that delivers end-to-end IoT solutions and educational opportunities connected to real-life scenarios and SDGs for 2030 that are easy to understand and simple to create. Just like a snack! Get creative and try it out! This device can act like a remote control to monitor, control, and automate things. Try out some projects and learn about automation processes in agriculture, industry automation, energy control, healthcare, and data logging. With the IoT Snacks Box, you can be an engineer or a scientist, too!</p>
                                      
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 150}}>
                                        <div className="col-lg-7" style={{alignSelf: 'flex-start', padding: 0}}>
                                            <h2 style={{marginBottom: 80, fontSize: 40}} className="title">Develop a SMART-World with ANTT’s IoT Snacks Box</h2>
                                        </div>
                                        
                                        <img style={{marginBottom: 70}} src="/assets/images/service/iot-aiot/3.webp"/>
                                        <p className="description">Forgot to turn off your air conditioner? Did you leave the lights on? Our IoT Snacks Box is an integrated hardware and software platform that delivers end-to-end IoT solutions</p>
                                        <p className="description">Our devices are designed and developed to to be used in any PLCs, digital home appliances, industrial machines, or digital devices that need to be monitored or controlled throughout the Internet of Things (IoT) technology. Each IoT Snacks Box has different components in it, based on its intended educational focus or use.All devices come with a dual-core processor, microSD memory card, OLED display, power management system, and a high-tech PCB design.</p>
                                        <p className="description">Depending on your requirements, devices can be input/output (I/O) or magnetic relays. All the hardware devices will be connected together using a mesh network system. Every node works as a router, up to 1000 nodes per mesh network. Mesh networks can easily, effectively, and wirelessly connect entire cities using existing technology.</p>
                                      
                                    </div>
                                    {/* End Single Area */}

                        

                                    {/* Start Single Area */}
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 150}}>
                                        <div className="col-lg-10" style={{alignSelf: 'flex-start', padding: 0}}>
                                            <h2 style={{marginBottom: 80, fontSize: 40}} className="title">Use the IoT Snacks Box Dashboard and connect to any SMART device faster than the speed of light!</h2>
                                        </div>
                                        
                                        <img style={{marginBottom: 70, width: '70%'}} src="/assets/images/service/iot-aiot/4.webp"/>
                                        <p className="description">Forgot to turn off your air conditioner? Did you leave the lights on? Our IoT Snacks Box is an integrated hardware and software platform that delivers end-to-end IoT solutions</p>
                                        <p className="description">Our devices are designed and developed to to be used in any PLCs, digital home appliances, industrial machines, or digital devices that need to be monitored or controlled throughout the Internet of Things (IoT) technology. Each IoT Snacks Box has different components in it, based on its intended educational focus or use.All devices come with a dual-core processor, microSD memory card, OLED display, power management system, and a high-tech PCB design.</p>
                                        <p className="description">Depending on your requirements, devices can be input/output (I/O) or magnetic relays. All the hardware devices will be connected together using a mesh network system. Every node works as a router, up to 1000 nodes per mesh network. Mesh networks can easily, effectively, and wirelessly connect entire cities using existing technology.</p>
                                      
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 150}}>                                  
                                        <div className="col-lg-6" style={{padding: 0}}>
                                            <div style={{
                                                height: 710,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: '#DBE4FE',
                                                borderRadius: 15,
                                            }}>
                                                <img style={{width: '80%'}} src="/assets/images/service/iot-aiot/5.webp" alt="About Images"/>
                                            </div>                                  
                                        </div>
                                        <div className="col-lg-6 iot-content" style={{paddingLeft: 50, backgroundColor: '#F0F9FF', borderRadius: 15, display: 'flex'}}>
                                            <div className="about-inner inner" style={{display: 'flex'}}>
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0}}>
                                                        <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%'}} className="title">Use the IoT Snacks Box Dashboard and connect to any SMART device faster than the speed of light!</h2>
                                                        <p className="description">Ok. Maybe not as fast as the speed of light! But, you can do this in less than 5 minutes! Don’t worry if you are new to IoT and Data-Science.</p>
                                                        <ul style={{color: '#717173', fontWeight: 300, fontSize: 18, lineHeight: '30px', margin: '20px 0'}}>
                                                            <li>No coding experience needed</li>
                                                            <li>Click on the dashboard Wizard and drag and drop blocks</li>
                                                            <li>Easily add team members to your dashboard and control permissions with a few simple clicks</li>
                                                            <li>Public dashboard link to share data data visually</li>
                                                            <li>AI powered sensor data analytics</li>
                                                            <li>Automated reporting systems and tools</li>
                                                        </ul>
                                                        <p>Whether onboarding one or 1,000 devices, navigating the IoT Snacks Box dashboard is a piece of cake when new devices are detected in the system. Recreate the same processes and automatically configure variables and device properties each time a new piece of hardware is detected.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 120}}>                                                                         
                                        <div className="col-lg-6 enhance-teach-content iot-content" style={{paddingLeft: 50, backgroundColor: '#F0F9FF', borderRadius: 15, display: 'flex'}}>
                                            <div className="about-inner inner" style={{display: 'flex'}}>
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0}}>
                                                        <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%'}} className="title">A Device-Friendly Dashboard</h2>                                                        
                                                        <p>The IoT Snacks Box is designed to allow you to connect your hardware quickly and easily to the IoT Snacks Box Cloud. You can connect to over a thousand trusted, user-proven libraries and documents that guide your integration over HTTP, MQTT, parsing custom or industrial protocols.</p>
                                                        <p>The IoT Snacks Box device is compatible with standard modern IoT software such as Blynk. The software is available on Web Platform, Android, or iOS devices and is also compatible with popular devices and processors like Raspberry Pi, NVidia Jetson, Microchip, Arduino, and Intel.</p>                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 enhance-teach-image" style={{padding: 0}}>
                                            <div style={{
                                                height: 710,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: '#DBE4FE',
                                                borderRadius: 15,
                                            }}>
                                                <img style={{width: '80%'}} src="/assets/images/service/iot-aiot/6.webp" alt="About Images"/>
                                            </div>                                    
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 120}}>                                  
                                        <div className="col-lg-6" style={{padding: 0}}>
                                            <div style={{
                                                height: 710,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: '#DBE4FE',
                                                borderRadius: 15,
                                            }}>
                                                <img style={{width: '80%'}} src="/assets/images/service/iot-aiot/7.webp" alt="About Images"/>
                                            </div>                                  
                                        </div>
                                        <div className="col-lg-6 iot-content" style={{paddingLeft: 50, backgroundColor: '#F0F9FF', borderRadius: 15, display: 'flex'}}>
                                            <div className="about-inner inner" style={{display: 'flex'}}>
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0}}>
                                                        <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%'}} className="title">The number of cars on the road has increased dramatically and this has led to long lines at toll booths.</h2>
                                                        <p className="description">The lack of new construction projects to facilitate traffic continues to be a major cause of this congestion. While RFID-enabled tolls have reduced wait times, better solutions are only achievable with IoT technologies. IoT has made the prospect of paying a toll or a parking fee easier than ever. The use of RFID and near-field communication (NFC) means that in order to travel on a given road or use a given parking spot, you must have an RFID-enabled tag installed. When you reach the exit or entrance of the road, your tag will be detected and will automatically send data.</p>                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                   {/* Start Single Area */}
                                   <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 150}}>
                                        <div className="col-lg-9" style={{alignSelf: 'flex-start', padding: 0}}>
                                            <h2 style={{marginBottom: 80, fontSize: 40, fontWeight: 500}} className="title">Streamlining Toll Management: Revolutionizing the Roadways with ANTT's IoT Snacks Box and RFID Technology</h2>
                                        </div>
                                        
                                        <img style={{marginBottom: 70, width: '80%'}} src="/assets/images/service/iot-aiot/8.webp"/>
                                        
                                        <p>ANTT Robotics is a leading provider of Internet of Things (IoT) solutions and services, offering a range of innovative hardware and software products designed to support the automation of various industries. Our portfolio of products and services is designed to meet the needs of a diverse range of customers, from small businesses to large corporations.</p>

                                        <p>One of our flagship products is an IoT Snacks Box that utilizes NFC technology to support the automation of various industries, such as toll management. The IoT Snacks Box provides a comprehensive solution for customers looking to streamline their operations and improve their overall efficiency. With its powerful hardware and intuitive software, it allows customers to easily monitor and control their assets, regardless of location.</p>

                                        <p>Our IoT Snacks Box is designed to be highly secure and reliable, utilizing state-of-the-art security measures to protect customer data and ensure the smooth functioning of their operations. It also features a user-friendly interface, allowing customers to quickly and easily monitor and control their assets with just a few clicks. Whether you're looking to optimize your operations, increase efficiency, or simply enhance the security of your assets, our IoT Snacks Box has you covered.</p>

                                        <p>At ANTT Robotics, we believe in providing our customers with the tools they need to succeed in today's rapidly evolving technological landscape. With our range of cutting-edge hardware and software products, we're committed to helping businesses of all sizes stay ahead of the curve and stay ahead of the competition. So if you're looking for an IoT solution that delivers results, look no further than ANTT Robotics</p>
                                      
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 120}}>                                                                         
                                        <div className="col-lg-6 enhance-teach-content iot-content" style={{padding: '0 50px', backgroundColor: '#F0F9FF', borderRadius: 15, display: 'flex'}}>
                                            <div className="about-inner inner" style={{display: 'flex'}}>
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0}}>
                                                        <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%'}} className="title">"Revolutionizing Asset Tracking with IoT Snacks Box: Enhancing Security, Optimization, and Control"</h2>                                                        
                                                        <p>With each passing day, our IoT Snacks Box for Asset Tracking becomes more relevant for industries. Knowing where our most valuable assets are is essential for security, optimization, and control. Being able to remotely geolocate assets within a building, area or from one country to another, is now achievable thanks to IoT. Using tracking technology, businesses can reduce risks, save money, and create new revenue streams.</p>                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 enhance-teach-image" style={{padding: 0}}>
                                            <div style={{
                                                height: 710,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: '#DBE4FE',
                                                borderRadius: 15,
                                            }}>
                                                <img style={{width: '80%'}} src="/assets/images/service/iot-aiot/10.webp" alt="About Images"/>
                                            </div>                                    
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 120}}>                                  
                                        <div className="col-lg-6" style={{padding: 0}}>
                                            <div style={{
                                                height: 710,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: '#DBE4FE',
                                                borderRadius: 15,
                                            }}>
                                                <img style={{width: '80%'}} src="/assets/images/service/iot-aiot/9.webp" alt="About Images"/>
                                            </div>                                  
                                        </div>
                                        <div className="col-lg-6 iot-content" style={{padding: '0 50px', backgroundColor: '#F0F9FF', borderRadius: 15, display: 'flex'}}>
                                            <div className="about-inner inner" style={{display: 'flex'}}>
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0}}>
                                                        <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%'}} className="title">"Securing the Future: Navigating the Growing IoT Ecosystem and Protecting Against Cyber Attacks"</h2>
                                                        <p className="description">The IoT ecosystem is growing at an exponential rate and with it, the number of insecure devices. There are now more than 8 billion connected devices in the world and this number is only going to grow. This massive increase in insecure devices has led to a rise in cyber attacks and data breaches. In fact, cyber attacks can now be carried out through IoT devices, which means that they are becoming an easy target for hackers. The use of the IoT to provide security and monitor devices is on the rise. With billions of devices around the world connected to the internet, it stands to reason that this technology is the future for security and protection.</p>                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 120}}>                                                                         
                                        <div className="col-lg-6 enhance-teach-content iot-content" style={{padding: '0 50px', backgroundColor: '#F0F9FF', borderRadius: 15, display: 'flex'}}>
                                            <div className="about-inner inner" style={{display: 'flex'}}>
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0}}>
                                                        <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%'}} className="title">"Empowering IoT Security: Protecting People, Estates, and Businesses with ANTT's Monitoring Platform"</h2>                                                        
                                                        <p>When it comes to IoT safety, insecurity is increasingly revolutionizing the security industry and affecting various people, estates, and businesses. Many businesses have tried to implement tighter security systems, but many are ineffective. Using ANTT’s platform,you can monitor your devices, alerting you of any potential hazards that could cause injury to your staff, damage to your property or fraudulent charges to your bank account!</p>                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 enhance-teach-image" style={{padding: 0}}>
                                            <div style={{
                                                height: 710,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: '#DBE4FE',
                                                borderRadius: 15,
                                            }}>
                                                <img style={{width: '80%'}} src="/assets/images/service/iot-aiot/11.webp" alt="About Images"/>
                                            </div>                                    
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 120}}>                                  
                                        <div className="col-lg-6" style={{padding: 0}}>
                                            <div style={{
                                                height: 710,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: '#DBE4FE',
                                                borderRadius: 15,
                                            }}>
                                                <img style={{width: '80%'}} src="/assets/images/service/iot-aiot/12.webp" alt="About Images"/>
                                            </div>                                  
                                        </div>
                                        <div className="col-lg-6 iot-content" style={{padding: '0 50px', backgroundColor: '#F0F9FF', borderRadius: 15, display: 'flex'}}>
                                            <div className="about-inner inner" style={{display: 'flex'}}>
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0}}>
                                                        <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%'}} className="title">IoT Snack Box for Health-Care </h2>
                                                        <p className="description">Medical equipment is expensive, and this is where IoT devices come in. IoT medical devices can be equipped with sensors to monitor the position of medical equipment in real time, which can help reduce the amount of human error that occurs during shift changes and other times when equipment may be mishandled. This includes wheelchairs, defibrillators, nebulizers, oxygen pumps, and other monitoring devices. Real-time analysis may also be performed on the distribution of medical personnel across various sites.</p>                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginRight: 0, marginTop: 120}}>                                                                         
                                        <div className="col-lg-6 enhance-teach-content iot-content" style={{padding: '0 50px', backgroundColor: '#F0F9FF', borderRadius: 15, display: 'flex'}}>
                                            <div className="about-inner inner" style={{display: 'flex'}}>
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 0}}>
                                                        <h2 style={{fontSize: 40, marginBottom: 20, fontWeight: 500, lineHeight: '134.5%'}} className="title">IoT Snack Box for Agriculture</h2>                                                        
                                                        <p>Across the world, agricultural businesses are struggling to keep up with the growing population. To feed 9.6 billion people, farmers will have to produce more crops than ever before. One solution is to adopt IoT in agriculture. This will allow farmers to harvest crops at the most opportune time and reduce food wastage by lessening spoilage and ensuring a constant supply of fresh food. Extreme weather conditions, increasing temperatures, and the environmental effects of intensive farming techniques may all be addressed with smart farming based on IoT technology. By being able to monitor field conditions at any time and automate procedures accordingly, the IoT will help growers and farmers cut waste and increase output. It will also create a new era of possibilities for digital farmers to control and monitor their businesses.</p>                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 enhance-teach-image" style={{paddingLeft: 0}}>
                                            <div style={{
                                                height: 710,
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: '#DBE4FE',
                                                borderRadius: 15,
                                            }}>
                                                <img style={{width: '100%'}} src="/assets/images/service/iot-aiot/13.webp" alt="About Images"/>
                                            </div>                                    
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-2600}  />

        </React.Fragment>
        )
    }
}
export default ServiceIotAiot;