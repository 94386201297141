import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Everbest Media',
        title: 'STEAM & Robotics Teaching Solutions',
        details: 'https://old.anttrobotics.com/pressrelease/press4 '
    },
    {
        image: 'image-3',
        category: 'IoTKID LLC',
        title: 'loT & Robotics Training',
        details: '/portfolio-details'
    },
    {
        image: 'image-2',
        category: 'Regnum IT Limited',
        title: 'loT Devices for Toll Management System',
        details: '/portfolio-details'
    },
    {
        image: 'image-4',
        category: 'Showa Co., Ltd',
        title: 'loT & Al based Software-Hardware development ',
        details: '/portfolio-details'
    },
    {
        image: 'image-5',
        category: 'AlMS LAB',
        title: 'COVID 19 Medical Care Robot',
        details: '/portfolio-details'
    },
    {
        image: 'image-6',
        category: 'ICT Ministry of Bangladesh',
        title: 'Al &Speech Recognition',
        details: '/portfolio-details'
    },
    {
        image: 'image-7',
        category: 'Champion',
        title: 'Bangladesh Frontier Startup, Seoul, SK',
        details: 'https://old.anttrobotics.com/pressrelease/press2'
    },
    {
        image: 'image-8',
        category: 'Low Cost Ventilators',
        title: 'UIU takes the lead in developing low-cost ventilators ',
        details: '/portfolio-details'
    },
    {
        image: 'image-9',  
        category: '1st Runners Up ',
        title: 'Entrepreneurship World Cup 2019',
        details: '/portfolio-details'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href={value.details}>{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.details}>View Details</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to={value.details}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;