import React from 'react';
import {Link} from 'react-router-dom';

function AcademicHeroTabs({ selectedTab }) {
  return (
    <div className="container academic" id="hero-tabs-container">
      <div className={selectedTab === 'stem' ? 'hero-tab-first hero-tab-selected' : 'hero-tab-first'}>
        <Link to='/academic/steam'/>
        <p>STEAM for School</p>
      </div>
      <div className={selectedTab === 'product' ? 'hero-tab hero-tab-selected' : 'hero-tab'}>
        <Link to='/academic/product-service'/>
        <p>Product & Services</p>
      </div>
      <div className={selectedTab === 'curriculum' ? 'hero-tab hero-tab-selected' : 'hero-tab'}>
        <Link to='/academic/curriculum'/>
        <p>Curriculum</p>
      </div>
      <div className={selectedTab === 'demo' ? 'hero-tab-last hero-tab-selected' : 'hero-tab-last'}>
        <Link to='/academic/demo'/>
        <p>Get Demo Product</p>
      </div>
      {/* <div className='hero-tab-last'>
        <Link to='#'/>
        <p>Standards</p>
      </div> */}
  </div>
  )
}

export default  AcademicHeroTabs;
