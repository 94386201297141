import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { FiCheck } from "react-icons/fi";
import LoginForm from "../login/LoginForm";

class TabsLogin extends Component{
    render(){
        let 
        tab1 = "ANTT", 
        tab2 = "Investor",
        tab3 = "Partner";
        const { tabStyle } = this.props;
        var namesItemOne = [
            'The Philosophy Of business analytics',
            'Fast-Track Your business',
            'Lies And Damn Lies About business',
            'The Ultimate Deal On business',
        ];
       
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="card tabs-area" style={{padding: 40}}>
                    
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <h4>We help you design your life</h4>

                                           <div className="mt--30">
                                               <LoginForm/>
                                           </div>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <h4>Patented Technological Platform With Significant Worldwide Markets</h4>
                                           <div className="mt--30">
                                               <LoginForm/>
                                           </div>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                           <h4>Keep Your Invention Business Live & Guarantee High Gain</h4>
                                           <div className="mt--30">
                                               <LoginForm/>
                                           </div>
                                       </div>
                                    </TabPanel>
                                    
                                </Tabs>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsLogin;