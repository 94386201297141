import React , {useState} from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}
function ContactForm({props}) {
    const [ result, showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
        .sendForm(
            'service_p4x3hv8', 
            'template_jgfr42f', 
            e.target, 
            'user_jrfTH2e0Ely35ZCVFdT9S'
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <form action="" onSubmit={sendEmail}>
            <div className="form-row">
                <div style={{position: 'relative', marginBottom: '1.6rem'}} className="form-group col-md-6">
                    <img width={21} style={{position: 'absolute', top: 13, left: 20}} src='/assets/images/icons/User Icon.webp'/>
                    <input
                        style={{border: '1px solid #ced4da', padding: '0 50px'}}
                        name="fullname"
                        type="text"
                        className="form-control"
                        placeholder="Your Name"
                        size="20"
                    />                  
                </div>
                <div style={{position: 'relative', marginBottom: '1.6rem'}} className="form-group col-md-6">
                    <img width={21} style={{position: 'absolute', top: 13, left: 20}} src='/assets/images/icons/Email Icon .webp'/>
                    <input
                        style={{border: '1px solid #ced4da', padding: '0 50px'}}
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Your Email"
                        size="20"
                    />
                </div>
            </div>
            <div className="form-row">
                <div style={{position: 'relative', marginBottom: '1.6rem'}} className="form-group col-md-6">
                    <img width={21} style={{position: 'absolute', top: 13, left: 20}} src='/assets/images/icons/Call icon.webp'/>
                    <input
                        style={{border: '1px solid #ced4da', padding: '0 50px'}}
                        name="phone"
                        type="text"
                        className="form-control"
                        placeholder="Phone Number"
                        size="20"
                    />
                </div>
                <div style={{position: 'relative', marginBottom: '1.6rem'}} className="form-group col-md-6">
                    <img width={21} style={{position: 'absolute', top: 13, left: 20}} src='/assets/images/icons/Subject Icon .webp'/>
                    <input
                        style={{border: '1px solid #ced4da', padding: '0 50px'}}
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder="Subject"
                        size="20"
                    />
                </div>
            </div>

            <div className='form-row'>
                <div style={{position: 'relative', marginBottom: '1.6rem'}} className="form-group col-md-12">
                    <textarea 
                        style={{border: '1px solid #ced4da'}}
                        name="message"
                        className="form-control"
                        placeholder="Write your message here..."
                        required
                    >
                    </textarea>
                </div>
            </div>


            <div className="rn-form-group">
                <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit Now</button>
            </div> 

            <div className="rn-form-group">
                {result ? <Result /> : null}
            </div> 
        </form>
    )
}
export default ContactForm;
