import React, { useEffect, useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ElementaryHeroTabs from "../../blocks/VocationalHeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";
import ContactForm from "../contact/ContactForm";

const opportunities = [
  {
    title: 'Find Us',
    description: 'Share Your Location and Discover Our STEAM Solutions ',
    image: 'd'
  },
  {
    title: 'Local STEAM Experts',
    description: 'Connect with Us and Enhance Your Education',
    image: 'e'
  },
  {
    title: 'Pricing and Consultation',
    description: 'Share Your Location and Discover Our STEAM Solutions ',
    image: 'f'
  }
];



function SteamVocational() {
    const [selected, setSelected] = useState(0);
    useEffect(() => {
      const script = document.createElement("script");
      script.src = "//embed.typeform.com/next/embed.js";
      script.async = true;
      document.body.appendChild(script);

      // Cleanup script when the component is unmounted
      return () => {
          document.body.removeChild(script);
      };
  }, []);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image vocational_bg" 
                  style={{ 
                        backgroundColor: '#4ABEAD', 
                        display: 'flex',
                        backgroundPosition: 'top', 
                        alignItems: 'center',
                        position: 'relative', 
                  }}
            >
                <ElementaryHeroTabs selectedTab={'demo'}/>
                <div className="container">
                  <div className="row" style={{alignItems: 'center'}}>
                      
                      <div className="col-lg-7 about_hero_content">
                          <h2>Join Our Pilot Program and Achieve Your Goals With Our Demo Product</h2>
                          <p>Contact Us to Learn More About Our Partnership Opportunities</p>
                      </div>
                      
                      <div className="col-lg-5 about_hero_image" style={{alignSelf: 'flex-end'}}>
                          <img src="/assets/images/demo/hero.webp"/>
                      </div>
                      
                      
                  </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1 demo-vocational-body">
                
              <div className="service-details-inner">
                  <div className="inner">
                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 130}}>                                  
                              <div className="col-lg-6 col-sm-12">                                                                   
                               
                                      <h2 style={{marginBottom: 16, fontWeight: 700, lineHeight: '134.5%', fontSize: 36}} className="title">Join Our Pilot Program: Try Our Demo Product for Free!</h2>                                     
                                                                                    
                              </div>
                              <div className="col-12">
                                <p className="description">Welcome to our pilot opportunity program! We're excited to offer our demo product to potential partners as a complimentary service. Our demo product is an excellent way to get a taste of our offerings and see how our solutions can benefit your organization.</p>
                              </div>
                              
                          </div>
                          <div className="row" style={{marginTop: 40}}>
                            <div className="col-lg-6" style={{paddingTop: 15, paddingBottom: 15}}>
                              <img style={{width: '100%'}} src="/assets/images/demo/1.webp"/>
                            </div>
                            <div className="col-lg-6" style={{paddingTop: 15, paddingBottom: 15}}>
                              <img style={{width: '100%'}} src="/assets/images/demo/2.webp"/>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6" style={{paddingTop: 15, paddingBottom: 15}}>
                              <img style={{width: '100%'}} src="/assets/images/demo/3.webp"/>
                            </div>
                            <div className="col-lg-6" style={{paddingTop: 15, paddingBottom: 15}}>
                              <img style={{width: '100%'}} src="/assets/images/demo/4.webp"/>
                            </div>
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 130,}}>
                              <div className="col-lg-6">
                                  <div style={{
                                    height: 573,                                   
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#E3FFFB'
                                  }} className="enroll-pilot-image">
                                    <img style={{width: '80%'}} src="/assets/images/demo/5.webp" alt="About Images"/>
                                  </div>
                              </div>                                  
                              <div className="col-lg-6 enhance-hands-content">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="service-style--3 text-left mb_sm--0">
                                              <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Enroll in Our Pilot Program and Get Free Support for Your Business</h2>
                                              <p className="description">In our pilot program, you will have the chance to test our demo product in a real-world setting. This will enable you to experience firsthand how our products work and how they can assist you in reaching your objectives. Additionally, we provide free support to assist you in improving your business.</p>
                                              <ul style={{fontSize: 18, lineHeight: '32px'}}>
                                                <li>Free technical assistance to train your trainer with the sample product</li>
                                                <li>Free support during your demo session with students</li>
                                                <li>Free support for shaping your business idea and operations</li>
                                                <li>Free support for creating your promotional design</li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              
                          </div>
                          {/* End Single Area */}
                          {/* Start Single Area */}
                          <div className="row" style={{marginTop: 130,}}>
                              {opportunities.map( (val , i) => (
                                  <div className="col-lg-4 col-md-6 col-sm-12 col-12 text-left" key={i} style={{paddingBottom: 30}}>
                                      <div class="card service-card" style={{border: 'none', boxShadow: '2px 3px 20px rgba(0, 0, 0, 0.14)', borderRadius: '0.65rem', height: 530 }}>
                                          <div style={{
                                              borderTopLeftRadius: '0.65rem', 
                                              borderTopRightRadius: '0.65rem', 
                                              backgroundColor: 'white', 
                                              height: 300,
                                              display: 'flex',
                                              justifyContent: 'center',
                                              alignItems: 'center'
                                          }}>
                                              <img style={{width: '70%'}} id="service-image" className="card-img-top" src={`/assets/images/academic/${val.image}.webp`} alt="Card image cap"/>
                                          </div>
                                          
                                          <div className="card-body" style={{display: 'flex', flexDirection: 'column', padding: '0 20px 20px 20px', justifyContent: 'flex-start', alignItems: 'center'}}>
                                             
                                                  <h5 className="card-title" style={{fontWeight: 700, marginBottom: 10}}>{val.title}</h5>
                                                  <p style={{fontSize: 16, marginBottom: 30, textAlign: 'center'}} className="card-text">{val.description}</p>
                                         
                                              
                                              <a href="/contact" className="generic-color-btn">Contact Us</a>
                                          </div>
                                      </div>
                                  </div>
                                  
                              ))}
                          </div>
                          {/* End Single Area */}

                      
                        

                          <div className="rn-contact-us ptb--30" id="contact" style={{marginTop: 120}}>
                            <div className="contact-form--1">
                                
                                <div className="row row--35" style={{alignItems: 'stretch'}}>
                                    <div className="col-12 order-2 order-lg-1">
                                        <div className="section-title text-left mb--50">
                                            <p style={{color: '#2c7fc2', marginBottom: 2, fontSize: 30}}>Interested?</p>
                                            <h2 style={{marginBottom: 15, fontSize: 40, fontWeight: 400}} className="title">Book your <span style={{fontWeight: 600}}>Free</span> Session</h2>
                                            <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                        </div>
                                        <div className="form-wrapper">
                                            {/* <ContactForm /> */}
                                            <div data-tf-live="01J8T05PHQMQ54P9ANXE209CTP" style={{ width: '100%', height: '500px' }}></div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 order-1 order-lg-2">
                                        <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                            <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                        </div>
                                    </div> */}
                                </div>
                                
                            </div>
                          </div>
                       
                      </div>
                      
                      {/* End Single Area */}
                      

              

                  </div>
              </div>
                        
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-560}  />

        </React.Fragment>
    )
}
export default SteamVocational;
