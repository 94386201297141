import React, { useEffect, useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ElementaryHeroTabs from "../../blocks/HigherEducationHeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";
import ContactForm from "../contact/ContactForm";



function SteamVocational() {
    const [selected, setSelected] = useState(0);
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//embed.typeform.com/next/embed.js";
        script.async = true;
        document.body.appendChild(script);

        // Cleanup script when the component is unmounted
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return (
        <React.Fragment>

            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image vocational_bg"
                style={{
                    backgroundColor: '#4ABEAD',
                    display: 'flex',
                    backgroundPosition: 'top',
                    alignItems: 'center',
                    position: 'relative',
                }}
            >
                <ElementaryHeroTabs selectedTab={'stem'} />
                <div className="container">
                    <div className="row" style={{ alignItems: 'center' }}>

                        <div className="col-lg-6 about_hero_content">
                            <h2>Drive Your Higher Institute Success with STEAM & Digital Literacy</h2>
                            <p>Supercharge your career with STEM education: A must-have for higher education institute students</p>
                        </div>

                        <div className="col-lg-6 about_hero_image" style={{ alignSelf: 'flex-end' }}>
                            <img src="/assets/images/highered/stem/HERO.webp" />
                        </div>


                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1 steam-vocational-body">

                <div className="service-details-inner">
                    <div className="inner">
                        {/* Start Single Area */}
                        <div style={{ transform: 'scale(0.8)', transformOrigin: 'top center' }} className="container">
                            {/* Start Single Area */}
                            <div className="row align-items-center" style={{ marginTop: 130, marginLeft: -4, marginRight: 0 }}>
                                <div className="col-lg-6 enhance-teach-content">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="service-style--3 text-left mb_sm--0">
                                                <h2 style={{ marginBottom: 36, fontWeight: 600, lineHeight: '134.5%' }} className="title">Future-Proof Your Career with STEM Education in Higher Education Institute</h2>
                                                <p className="description">Higher education plays a pivotal role in bridging the skills gap in the workforce, equipping students with practical skills and hands-on experience that are highly sought after by employers. Integrating STEM education into your vocational school curriculum provides students with the necessary skills and knowledge to excel in the digital age, thereby enabling them to enter the workforce fully prepared. By adopting 4IR tech-oriented education solutions using STEM, your vocational school curriculum can equip students with the skills that are currently in high demand, enhancing their employability and preparing them for the jobs of the future.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 enhance-teach-image">
                                    <div style={{ position: 'relative' }}>
                                        <img className="w-100" src="/assets/images/vocational/steam/1.webp" alt="About Images" />
                                    </div>
                                </div>
                            </div>
                            {/* End Single Area */}

                            {/* Start Single Area */}
                            <div className="row align-items-center" style={{ marginTop: 130, marginLeft: -4, marginRight: 0 }}>
                                <div className="col-lg-6">
                                    <div style={{ position: 'relative' }}>
                                        <img className="w-100" src="/assets/images/vocational/steam/2.webp" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-6 enhance-hands-content">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="service-style--3 text-left mb_sm--0">
                                                <h2 style={{ marginBottom: 36, fontWeight: 600, lineHeight: '134.5%' }} className="title">Boost Your Career Prospects With STEM-Powered Higher Education</h2>
                                                <p className="description">Incorporating STEM education into your higher education institute curriculum can significantly enhance your employability. As STEM skills are in high demand across industries, learning these skills will give you an edge in the job market. By providing you with the skills and knowledge required to succeed in the workforce, STEM education paves the way for a fulfilling career.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* End Single Area */}
                            {/* Start Single Area */}
                            <div className="row align-items-center" style={{ marginTop: 130, marginLeft: -4, marginRight: 0 }}>
                                <div className="col-lg-6 enhance-teach-content">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="service-style--3 text-left mb_sm--0">
                                                <h2 style={{ marginBottom: 36, fontWeight: 600, lineHeight: '134.5%' }} className="title">Future-Proof Your Career with STEM Education in Higher Education Institute</h2>
                                                <p className="description">The digital age is transforming the way we live and work, and STEM education is the key to preparing yourself for the jobs of the future. By integrating STEM education into your higher education institute curriculum, you can equip yourself with the necessary skills and knowledge to thrive in a rapidly changing world. With STEM education, you can be confident in your ability to succeed in the digital age.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 enhance-teach-image">
                                    <div style={{ position: 'relative' }}>
                                        <img className="w-100" src="/assets/images/vocational/steam/3.webp" alt="About Images" />
                                    </div>
                                </div>
                            </div>
                            {/* End Single Area */}

                            {/* Start Single Area */}
                            <div className="row align-items-center" style={{ marginTop: 130, marginLeft: -4, marginRight: 0 }}>
                                <div className="col-lg-6">
                                    <div style={{ position: 'relative' }}>
                                        <img className="w-100" src="/assets/images/vocational/steam/4.webp" alt="About Images" />
                                    </div>
                                </div>
                                <div className="col-lg-6 enhance-hands-content">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="service-style--3 text-left mb_sm--0">
                                                <h2 style={{ marginBottom: 36, fontWeight: 600, lineHeight: '134.5%' }} className="title">Develop Critical Thinking and Problem-Solving Skills With STEM Education</h2>
                                                <p className="description">STEM education is a game-changer in terms of fostering critical thinking, problem-solving, and innovation. These skills are essential for success in any field, and STEM education provides a solid foundation for their development. By incorporating STEM education into your higher education institute curriculum, you can gain these valuable skills and stand out from the crowd in your future career.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* End Single Area */}

                            {/* Start Single Area */}
                            <div className="row align-items-center" style={{ marginTop: 130, marginLeft: -4, marginRight: 0 }}>
                                <div className="col-lg-6 enhance-teach-content">
                                    <div className="about-inner inner">
                                        <div className="row">
                                            <div className="service-style--3 text-left mb_sm--0">
                                                <h2 style={{ marginBottom: 36, fontWeight: 600, lineHeight: '134.5%' }} className="title">Maximize Your Earning Potential With STEM Education in Higher Education Institute</h2>
                                                <p className="description">The digital age is transforming the way we live and work, and STEM education is the key to preparing yourself for the jobs of the future. By integrating STEM education into your higher education institute curriculum, you can equip yourself with the necessary skills and knowledge to thrive in a rapidly changing world. With STEM education, you can be confident in your ability to succeed in the digital age.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 enhance-teach-image">
                                    <div style={{ position: 'relative' }}>
                                        <img className="w-100" src="/assets/images/vocational/steam/5.webp" alt="About Images" />
                                    </div>
                                </div>
                            </div>
                            {/* End Single Area */}


                            <div className="rn-contact-us ptb--30" id="contact" style={{ marginTop: 120 }}>
                                <div className="contact-form--1">

                                    <div className="row row--35" style={{ alignItems: 'stretch' }}>
                                        <div className="col-12 order-2 order-lg-1">
                                            <div className="section-title text-left mb--50">
                                                <p style={{ color: '#2c7fc2', marginBottom: 2, fontSize: 30 }}>Interested?</p>
                                                <h2 style={{ marginBottom: 15, fontSize: 40, fontWeight: 400 }} className="title">Book your <span style={{ fontWeight: 600 }}>Free</span> Session</h2>
                                                <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                            </div>
                                            <div className="form-wrapper">
                                                {/* <ContactForm /> */}
                                                <div data-tf-live="01J8T05PHQMQ54P9ANXE209CTP" style={{ width: '100%', height: '500px' }}></div>
                                            </div>
                                        </div>
                                        {/* <div className="col-lg-6 order-1 order-lg-2">
                                        <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                            <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                        </div>
                                    </div> */}
                                    </div>

                                </div>
                            </div>

                        </div>

                        {/* End Single Area */}




                    </div>
                </div>

            </div>
            {/* End Page Wrapper */}

            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}

            <Footer topMargin={-560} />

        </React.Fragment>
    )
}
export default SteamVocational;
