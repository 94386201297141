import React, { useEffect, useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ElementaryHeroTabs from "../../blocks/AcademicHeroTabs";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";
import ContactForm from "../contact/ContactForm";



function Steam() {
    const [selected, setSelected] = useState(0);
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//embed.typeform.com/next/embed.js";
        script.async = true;
        document.body.appendChild(script);
  
        // Cleanup script when the component is unmounted
        return () => {
            document.body.removeChild(script);
        };
    }, []);
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image about_bg" 
                  style={{ 
                        backgroundColor: '#4ABEAD', 
                        display: 'flex',
                        backgroundPosition: 'top', 
                        alignItems: 'center',
                        position: 'relative', 
                  }}
            >
                <ElementaryHeroTabs selectedTab={'stem'}/>
                <div className="container">
                  <div className="row" style={{alignItems: 'center'}}>
                      
                      <div className="col-lg-6 about_hero_content">
                          <h2 style={{textAlign: 'start'}}>Future-Proof Your Career With STEM Powered Vocational Education</h2>
                          <p>Prepare your students for success in a technology-driven world</p>
                      </div>
                      
                      <div className="col-lg-6 about_hero_image" style={{alignSelf: 'flex-end'}}>
                          <img src="/assets/images/academic/steam/hero img.webp"/>
                      </div>
                      
                      
                  </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div className="rn-service-details mt-first bg_color--1">
                
              <div className="service-details-inner">
                  <div className="inner">
                      {/* Start Single Area */}
                      <div style={{transform: 'scale(0.8)', transformOrigin: 'top center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}} className="container">
                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{ marginLeft: -4, marginRight: 0}}>                                  
                              <div className="col-lg-6 enhance-teach-content">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="service-style--3 text-left mb_sm--0">
                                              <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Future-Proof Your Institution With STEAM Education</h2>
                                              <p className="description">Are you looking for a way to set your institution apart and give your students the skills they need to succeed in the future? Look no further than STEAM education. STEAM education, which integrates Science, Technology, Engineering, Arts, and Mathematics, is an essential approach to learning that emphasizes the connections between these subjects and how they can be applied to real-world problems. This type of education prepares students for the future, which is becoming increasingly technology-driven.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 enhance-teach-image">
                                  <div style={{position: 'relative'}}>
                                      <img className="w-100" src="/assets/images/academic/steam/1.webp" alt="About Images"/>
                                  </div>
                              </div>
                          </div>
                          {/* End Single Area */}

                          <div  className="col-lg-8 section-title-40">
                            <h2 style={{marginTop: 120, marginBottom: 60}}>Give Your Students a Competitive Edge With STEAM Education</h2>
                          </div>

                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 30, marginLeft: -4, marginRight: 0}}>
                              <div className="col-lg-5" style={{
                                  height: 374,
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: '#E3FCFF'
                                }}>
                        
                                  <img style={{width: '80%'}} src="/assets/images/academic/steam/2.webp" alt="About Images"/>
      
                              </div>                                  
                              <div className="col-lg-7 academic-steam">
                                <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Develop Critical Thinking and Problem-Solving Skills</h2>
                                <p className="description">STEAM education emphasizes the connections between science, technology, engineering, arts, and mathematics, helping students develop skills that are essential for success in any field.</p>
                              </div>
                              
                          </div>
                          {/* End Single Area */}
                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 30, marginLeft: -4, marginRight: 0}}>
                              <div className="col-lg-5" style={{
                                  height: 374,
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: '#E3FCFF'
                                }}>
                        
                                  <img style={{width: '80%'}} src="/assets/images/academic/steam/3.webp" alt="About Images"/>
      
                              </div>                                  
                              <div className="col-lg-7 academic-steam">
                                <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Explore Various Career Possibilities</h2>
                                <p className="description">By offering STEAM education, academic institutions can expose their students to various career possibilities in the STEAM fields, such as healthcare, manufacturing, computer programming, and engineering.</p>
                              </div>
                              
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 30, marginLeft: -4, marginRight: 0}}>
                              <div className="col-lg-5" style={{
                                  height: 374,
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: '#E3FCFF'
                                }}>
                        
                                  <img style={{width: '80%'}} src="/assets/images/academic/steam/4.webp" alt="About Images"/>
      
                              </div>                                  
                              <div className="col-lg-7 academic-steam">
                                <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Teach Future of Work Skills</h2>
                                <p className="description">Teach Problem-Solving, Teamwork, Creativity, and Leadership Skills. ANTT Robotics' STEAM curriculum teaches essential skills such as problem-solving, teamwork, creativity, and leadership that prepare students for the digital world. The platform is available on mobile apps and web portals, allowing students to learn anytime, anywhere with ready-to-use coding software.</p>
                              </div>
                              
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{marginTop: 30, marginLeft: -4, marginRight: 0}}>
                              <div className="col-lg-5" style={{
                                  height: 374,
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  backgroundColor: '#E3FCFF'
                                }}>
                        
                                  <img style={{width: '80%'}} src="/assets/images/academic/steam/5.webp" alt="About Images"/>
      
                              </div>                                  
                              <div className="col-lg-7 academic-steam">
                                <h2 style={{marginBottom: 36, fontWeight: 600, lineHeight: '134.5%'}} className="title">Differentiate Your Institution</h2>
                                <p className="description">Incorporating STEAM education can help academic institutions differentiate themselves from other schools and provide their students with a competitive advantage.</p>
                              </div>
                              
                          </div>
                          {/* End Single Area */}

                          {/* Start Single Area */}
                          <div className="row align-items-center" style={{ marginLeft: -4, marginRight: 0, marginTop: 120}}>                                  
                              <div className="col-lg-6 enhance-teach-content">
                                  <div className="about-inner inner">
                                      <div className="row">
                                          <div className="service-style--3 text-left mb_sm--0">
                                             
                                              <p className="description">Investing in our program is an investment in the future of education and the success of your students. By incorporating STEAM education and digital literacy training in your academic institution, you can give your students the skills they need to succeed in the future and set your institution apart from others. </p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-6 enhance-teach-image" style={{padding: 0}}>
                                  <div style={{position: 'relative'}}>
                                      <img className="w-100" src="/assets/images/academic/steam/6.webp" alt="About Images"/>
                                  </div>
                              </div>
                          </div>
                          {/* End Single Area */}
                        

                          <div className="rn-contact-us ptb--30" id="contact" style={{marginTop: 100}}>
                            <div className="contact-form--1">
                                
                                <div className="row row--35" style={{alignItems: 'stretch'}}>
                                    <div className="col-12 order-2 order-lg-1">
                                        <div className="section-title text-left mb--50">
                                            <p style={{color: '#2c7fc2', marginBottom: 2, fontSize: 30}}>Interested?</p>
                                            <h2 style={{marginBottom: 15, fontSize: 40, fontWeight: 400}} className="title">Book your <span style={{fontWeight: 600}}>Free</span> Session</h2>
                                            <p className="description">Our Deep Pool Of Certified Engineers And Robotics Development Staff Are Ready To Help You to get started with your Robotics Business. Reach us at <a href="mailto: anttrobotics@gmail.com">anttrobotics@gmail.com</a>, <a href="mailto: support@anttrobotics.com">support@anttrobotics.com</a>.</p>
                                        </div>
                                        <div className="form-wrapper">
                                            {/* <ContactForm /> */}
                                            <div data-tf-live="01J8T05PHQMQ54P9ANXE209CTP" style={{ width: '100%', height: '500px' }}></div>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-6 order-1 order-lg-2">
                                        <div className="thumbnail mb_md--30 mb_sm--30" style={{height: '100%'}}>
                                            <img src="/assets/images/about/contact-us.png" alt="trydo" style={{height: '100%'}}/>
                                        </div>
                                    </div> */}
                                </div>
                                
                            </div>
                          </div>
                       
                      </div>
                      
                      {/* End Single Area */}
                      

              

                  </div>
              </div>
                        
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-600}  />

        </React.Fragment>
    )
}
export default Steam;
