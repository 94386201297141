import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import HeaderFixed from "../component/header/HeaderFixed";
import Footer from "../component/footer/Footer";
import TabsLogin from "./tab/TabsLogin";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Login extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Contact' />

                <HeaderFixed />

                 {/* Start Breadcrump Area */}
                 <div className="rn-page-title-area bg_image" style={{backgroundImage: 'url(/assets/images/bg/login-banner.png)', paddingBottom: 152, paddingTop: 96}}>
                    <div style={{transform: 'scale(0.8)'}} className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Login</h2>
                                    {/* <p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--80 bg_color--5" 
                     style={{
                                backgroundImage: 'url(/assets/images/bg/login-background.jpeg)', 
                                paddingBottom: 152, 
                                paddingTop: 120, 
                                backgroundRepeat: 'no-repeat', 
                                backgroundSize: 'cover', 
                                backgroundPosition: 'center center'
                            }} >
                    <div className="container">
                       
                        <div className="row">
                            <div className="container">
                                <div className="row row--35" style={{alignItems: 'center'}}>
                                    <div className="col-lg-6 order-2 order-lg-1">
                                        <TabsLogin tabStyle="tab-style--1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--60 bg_color--1">
                    <div className="contact-form--1">
                        
                    </div>
                </div>
                {/* End Contact Page Area  */}

                {/* Start Contact Map  */}
                {/* <div className="rn-contact-map-area position-relative">
                    <div style={{ height: '520px', width: '100%' }}>
                        <GoogleMapReact
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        >
                        <AnyReactComponent
                            lat={23.7800221}
                            lng={90.4244863}
                            text="ANTT Robotics R&D office"
                        />
                        </GoogleMapReact>
                    </div>
                </div> */}
                {/* End Contact Map  */}
                

                {/* Start Brand Area */}
                {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <BrandTwo />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer topMargin={-170} />
                
            </React.Fragment>
        )
    }
}
export default Login