import React, { useState } from "react";
import PageHelmet from "../../component/common/Helmet";

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";

import HeaderFixed from "../../component/header/HeaderFixed";
import Footer from "../../component/footer/Footer";

import 'react-accessible-accordion/dist/fancy-example.css';
import {Link} from 'react-router-dom';
import Collapsible from "../../blocks/Collapsible";
import HeroTabs from "../../blocks/HeroTabs";
import CurriculumSubItem from "../../blocks/CurriculumSubItem";


function Curriculum() {
    const [selected, setSelected] = useState(0);
    
    return(
        <React.Fragment>
            
            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details' />
            {/* End Pagehelmet  */}

            <HeaderFixed />

            {/* Start Breadcrump Area */}
            <div className="bg_image education-hero" style={{backgroundImage: 'url(/assets/images/bg/preschool-Curriculum-hero-img.webp)'}}>
                <HeroTabs selectedTab={'curriculum'}/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center pt--100">
                                <h2>CURRICULUM</h2>
                                <p style={{fontSize: 20}}>Full Stack EdTech  Solutions For Enterprise</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Page Wrapper */}
            <div style={{marginTop: 50}} className="rn-service-details bg_color--1">
                <div style={{transform: 'scale(0.8)', transformOrigin: 'top center'}} className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service-details-inner">
                                <div className="inner">
                                    {/* Start Single Area */}
                                    
                                    {/* <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <h2 style={{alignSelf: 'flex-start', marginBottom: 80}} className="title">What is STEAM & ICT?</h2>
                                        <img style={{marginBottom: 70}} src="/assets/images/education/What is Steam _ ICT.webp"/>
                                        <p className="description">STEAM education is the integration of Science, Technology, Engineering, Arts, and Mathematics in the classroom. It is an approach to learning that emphasizes the connections between these subjects and how they can be applied to real-world problems. This type of education is important as it prepares students for the future, which is becoming increasingly technology-driven. STEAM education is a unique approach to learning that emphasizes the connections between these subjects and how they can be applied to real-world problems. It helps students to understand the world around them and prepare them for the future, which is becoming increasingly technology-driven.</p>
                                        <p className="description">On the other hand, digital literacy is the ability to use technology and digital tools to access, analyze, and create information. It's an essential skill that students need in today's digital age. As an educator, incorporating both STEAM and digital literacy into your teaching can create engaging and interactive lesson plans that keep students interested and motivated. By using technology in the classroom, educators can also differentiate instruction and meet the needs of diverse learners.</p>
                                    </div>
                                     */}
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center">                                  
                                        <div className="col-lg-7">
                                            <div style={{position: 'relative', marginRight: 50}}>
                                                <img className="w-100" src="/assets/images/education/Gamified Lesson_.webp" alt="About Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="about-inner inner">
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0">
                                                        <h2 style={{fontSize: 40, marginBottom: 40, fontWeight: 700, lineHeight: '134.5%'}} className="title">Gamified Lesson Plan</h2>
                                                        <p style={{marginBottom: 40}} className="description">ANTT's patented, curriculum-aligned system offers preschool teachers a fun and interactive way to teach STEAM subjects. Our gamified lesson plans spark curiosity and creativity in young learners, while meeting their individual needs. Experience the power of ANTT in inspiring a love of learning and unlocking potential in your students.</p>
                                                        {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="row align-items-center" style={{marginLeft: -4, marginRight: 0}}>                                  
                                        <div className="col-lg-5">
                                            <div className="about-inner inner">
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0">
                                                        <h2 style={{fontSize: 40, marginBottom: 40, fontWeight: 700, lineHeight: '134.5%'}} className="title">AI-enhanced Monitoring & Evaluation</h2>
                                                        <p style={{marginBottom: 40}} className="description">Evaluate students' potential with ANTT Robotics' capstone project-based approach. Our AI-enhanced curriculum allows educators to personalize learning and track progress, maximizing students' understanding and growth in preschool education. Unleash the full potential of your students with ANTT Robotics</p>
                                                        {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div style={{position: 'relative', marginRight: 50}}>
                                                <img className="w-100" src="/assets/images/education/Monitoring _ Evaluation.webp" alt="About Images"/>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}
                                    {/* Start Single Area */}
                                    <div className="row align-items-center">                                  
                                        <div className="col-lg-7">
                                            <div style={{position: 'relative', marginRight: 50}}>
                                                <img className="w-100" src="/assets/images/education/Step-by-Step Guidance.webp" alt="About Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <div className="about-inner inner">
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0">
                                                        <h2 style={{fontSize: 40, marginBottom: 40, fontWeight: 700, lineHeight: '134.5%'}} className="title">Step-by-Step Guidance for Educator</h2>
                                                        <p style={{marginBottom: 40}} className="description">Empower your preschool classroom with ANTT's comprehensive STEAM curriculum. Our teacher manual for each lesson provides step-by-step guidance for introducing technology in an engaging and interactive way. With ANTT, your students will learn coding, robotics, problem-solving, and more, all while having fun. Join us for a cutting-edge educational experience that will equip students for success in the digital age.</p>
                                                        {/* <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Single Area */}
                                    <div className="section-title service-style--3 text-left mb--15 mb_sm--0" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 120}}>
                                        <h2 style={{marginBottom: 80}} className="title">Find Your Curriculum</h2>
                                        <Collapsible title='South Korea' image='/assets/images/education/South Korea Flag.webp'>
                                            <CurriculumSubItem title={'STEAM Education for Preschool Educators'}/>
                                            <CurriculumSubItem title={'Digital Literacy for Preschool Educators'}/>
                                            <CurriculumSubItem title={'ICT & Soft Skills basics for Preschool Educators'}/>
                                            <CurriculumSubItem title={'Robotics and Computer Programming for Preschool Educators'}/>
                                        </Collapsible>
                                        <Collapsible title='Bangladesh' image='/assets/images/education/Bangladesh Flag.webp'>
                                            <CurriculumSubItem title={'NCTB Curriculum friendly Lesson'}/>
                                            <CurriculumSubItem title={'STEAM Education for Preschool Educators'}/>
                                            <CurriculumSubItem title={'Digital Literacy for Preschool Educators'}/>
                                            <CurriculumSubItem title={'ICT & Soft Skills basics for Preschool Educators'}/>
                                            <CurriculumSubItem title={'Robotics and Computer Programming for Preschool Educators'}/>
                                        </Collapsible>
                                        <Collapsible title='Egypt' image='/assets/images/education/Egypt Flag.webp'>
                                            <CurriculumSubItem title={'STEAM Education for Preschool Educators'}/>
                                            <CurriculumSubItem title={'Digital Literacy for Preschool Educators'}/>
                                            <CurriculumSubItem title={'ICT & Soft Skills basics for Preschool Educators'}/>
                                            <CurriculumSubItem title={'Robotics and Computer Programming for Preschool Educators'}/>
                                        </Collapsible>
                                        <Collapsible title='Malaysia' image='/assets/images/education/Malaysia Flag.webp'>
                                            <CurriculumSubItem title={'STEAM Education for Preschool Educators'}/>
                                            <CurriculumSubItem title={'Digital Literacy for Preschool Educators'}/>
                                            <CurriculumSubItem title={'ICT & Soft Skills basics for Preschool Educators'}/>
                                            <CurriculumSubItem title={'Robotics and Computer Programming for Preschool Educators'}/>
                                        </Collapsible>
                                        <Collapsible title='Global and International Curriculum' image='/assets/images/education/Global.webp'>
                                            <CurriculumSubItem title={'STEAM Education for Preschool Educators'}/>
                                            <CurriculumSubItem title={'Digital Literacy for Preschool Educators'}/>
                                            <CurriculumSubItem title={'ICT & Soft Skills basics for Preschool Educators'}/>
                                            <CurriculumSubItem title={'Robotics and Computer Programming for Preschool Educators'}/>
                                        </Collapsible>
                                    </div>
                                    {/* End Single Area */}

                                    {/* Start Finding Us Area  */}
                                    {/* <div className="row align-items-center" style={{marginTop: 150}}>                                  
                                        <div className="col-lg-5">
                                            <div className="thumbnail" style={{position: 'relative'}}>
                                                <img className="w-100" src="/assets/images/education/Improve Educational_.webp" alt="About Images"/>
                                            </div>
                                        </div>
                                        <div className="col-lg-7">
                                            <div className="about-inner inner">
                                                <div className="row">
                                                    <div className="section-title service-style--3 text-left mb_sm--0">
                                                        <h2 style={{fontSize: 45, marginBottom: 20, fontWeight: 700, lineHeight: '134.5%'}} className="title">Improve Educational Outcomes with ANTT </h2>
                                                        <p style={{marginBottom: 40}} className="description">ANTT offers a comprehensive computer science, IoT, Robotics, or STEAM curriculum and products for schools. Our platform offers the world's most advanced coding learning experience for both adults and children with a focus on STEAM education. Our curriculum teaches problem-solving, teamwork, creativity, and leadership skills. Our platform is available on mobile app and web portal, allowing students to learn anytime, anywhere with our ready-to-use coding software. Our platform also offers a range of resources and tools for teachers, including interactive and engaging </p>
                                                        <a className="rn-button-style--2 btn-border-primary-color btn-primary-color" href="#">Read More</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* End Finding Us Area  */}

                            

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Page Wrapper */}
            
            {/* Start Back To Top */}
            {/* <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div> */}
            {/* End Back To Top */}
            
            <Footer topMargin={-500}  />

        </React.Fragment>
    )
}
export default Curriculum;