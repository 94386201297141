const BlogContent = [
    {
        images: '01',
        title: 'EduBlock',
        category: 'Robotics Tool for Learning CS'
    },
    {
        images: '02',
        title: 'School of IoT',
        category: 'IoT & Data Science Starter Kit'
    },

    {
        images: '03',
        title: 'Robotic Arm Kit',
        category: 'Arm addons for School of IoT'
    },
    {
        images: '04',
        title: ' Robotic Car Kit',
        category: 'Car addons for School of IoT'
    },
    {
        images: '05',
        title: 'AntRobo',
        category: 'IoT & Robotics Starter Kit'
    },
    {
        images: '06',
        title: 'RoboPlanet',
        category: 'Robotics Planet Kit for Machine Learning'
    },
    {
        images: '07',
        title: 'IoT Snacks Box',
        category: 'Industiral IoT Solutions',
    },
    {
        images: '08',
        title: 'Service Robot',
        category: 'AI based Autonomous Service Robot',
    }
]

export default BlogContent;